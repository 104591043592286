import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useParams} from "react-router-dom";
import * as React from "react";
import {appSession} from "../../Broadtask";
import ExtensionDetailsPane from "../../components/extension/ExtensionDetailsPane";
import ExtensionSummaryPane from "../../components/extension/ExtensionSummaryPane";
import ExtensionTaskablesPane from "../../components/extension/ExtensionTaskablesPane";
import {sectionTitle} from "../../../AppStyles";

const ExtensionViewer = () =>
{
    const {extensionUID} = useParams();

    return <Stack spacing={2} padding={1}>
        <Stack direction="row"
               sx={sectionTitle}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/extensions"} underline={"hover"}>Extensions</Link>
                <Typography>{extensionUID}</Typography>
            </Breadcrumbs>
        </Stack>

        {
            appSession.activeSession() === false ?
            <Navigate to="/login"/> :
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2}
                   padding={1}>

                <ExtensionDetailsPane extensionUID={extensionUID} />

                <ExtensionSummaryPane extensionUID={extensionUID} />

                <ExtensionTaskablesPane extensionUID={extensionUID} />

            </Stack>
        }

    </Stack>
};

export default ExtensionViewer;
