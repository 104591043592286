import React, {useEffect, useState} from "react";
import {Divider, Stack, Typography} from "@mui/material";
import {dataManager} from "../../Broadtask";
import {sectionStack, subSectionPanel} from "../../../AppStyles";

const TaskableDeploymentProfilePane = (props) => {

    const [deploymentProfile, setDeploymentProfile] = useState(null);

    useEffect(() => {
        dataManager.getTaskableManager().getInstance(props.taskableUID).getDeploymentProfile(false, (profile) => {
            setDeploymentProfile(profile);
        });
    }, [props.taskableUID]);

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%"
               alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/taskable_deployment_profile.png" width={32} height={32}/>
                    <Typography variant="h6">Deployment Profile</Typography>
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
            <Stack direction="column" spacing={1} width="100%" minHeight={100}
                   sx={subSectionPanel}>
                {
                    deploymentProfile === null ?
                    <Typography>Loading...</Typography>:
                    <Stack alignItems="flex-start" padding={1}>
                        <Typography>
                            Terrestrial Network: {deploymentProfile.terrestrialConnection === true ?
                            "ISP: " + deploymentProfile.terrestrialProvider + " " + deploymentProfile.terrestrialUpload + "/" + deploymentProfile.terrestrialDownload + " Mbps":
                            "No"}
                        </Typography>
                        <Typography>
                            Satellite Network: {deploymentProfile.satelliteConnection === true ?
                            "ISP: " + deploymentProfile.satelliteProvider + " " + deploymentProfile.satelliteDownload + "/" + deploymentProfile.satelliteUpload + " Mbps":
                            "No"}
                        </Typography>
                        <Typography>
                            Cellular Network: {deploymentProfile.cellularConnection === true ?
                            "ISP: " + deploymentProfile.cellularProvider + " " + deploymentProfile.cellularDownload + "/" + deploymentProfile.cellularUpload + " Mbps":
                            "No"}
                        </Typography>
                        <Typography>
                            Network Switch Command Support: {deploymentProfile.netSwitch === true ? "Yes": "No"}
                        </Typography>
                        <Typography>
                            Proxy Connection: {deploymentProfile.proxyConnection === true ? "Yes": "No"}
                        </Typography>
                        <Typography>
                            TOR Connection: {deploymentProfile.TORConnection === true ? "Yes": "No"}
                        </Typography>
                        <Typography>
                            VPN Connection: {deploymentProfile.VPNConnection === true ? deploymentProfile.VPNProvider: "No"}
                        </Typography>
                        <Typography>
                            Attribution Location: {deploymentProfile.attributionCity}, {deploymentProfile.attributionState} {deploymentProfile.attributionCountry}
                        </Typography>
                        <Typography>
                            IP Rotation: {deploymentProfile.IPRotation === true ?
                            "Frequency: " + deploymentProfile.IPRotationFrequency: "No"}
                        </Typography>
                        <Typography>
                            Default Location: {deploymentProfile.defaultLocationLatitude}, {deploymentProfile.defaultLocationLongitude}
                        </Typography>
                        <Typography>
                            Movable: {deploymentProfile.movable === true ? "Yes": "No"}
                        </Typography>
                        <Typography>
                            GPS Spoof: {deploymentProfile.GPSSpoof === true ? "Yes": "No"}
                        </Typography>
                        <Typography>
                            GPS Beacon: {deploymentProfile.GPSBeacon === true ? "Yes": "No"}
                        </Typography>
                        <Typography>
                            POL Location Profiles: {deploymentProfile.POLLocationProfiles === true ? "Yes": "No"}
                        </Typography>
                    </Stack>
                }
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
        </Stack>
);
}

export default TaskableDeploymentProfilePane;
