import {Breadcrumbs, Button, Divider, Link, MenuItem, Select, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {useRef} from "react";
import {networkAPI} from "../../../api/BroadtaskAPI";
import {appMessaging, appSession} from "../../../Broadtask";
import GridViewIcon from "@mui/icons-material/GridView";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import {Check} from "@mui/icons-material";
import {buttonStyle, mainHeader, sectionStack} from "../../../../AppStyles";

const UserAuthAdd = () =>
{
    const {networkUID} = useParams();

    const navigate = useNavigate();

    const userSelect = useRef();

    function onCancel()
    {
        navigate("/networks/" + networkUID + "/users/");
    }

    async function onCreate()
    {
        const userUID = userSelect.current.uid;
        const language = userSelect.current.value;

        const response = await networkAPI.createUserPermission(networkUID, userUID, language);
        if (response.status && response.status === 200)
        {
            //TODO: Read the data field to get user session details
            console.log(response.data);

            appMessaging.showSuccess('User Authorization Created', 5000);

            navigate("/networks/" + networkUID + "/users/");
        }
        else
        {
            appMessaging.showError('Unable to create user authorization\n' + response, 10000);
        }
    }

    return <Stack spacing={1}>
        <Stack direction="row"
               sx={mainHeader}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/networks"} underline={"hover"}>Networks</Link>
                <Link component={RouterLink} to={"/networks/" + networkUID} underline={"hover"}>{networkUID}</Link>
                <Link component={RouterLink} to={"/networks/" + networkUID + "/users"} underline={"hover"}>User Authorizations</Link>
                <Typography>Add Authorized User</Typography>
            </Breadcrumbs>
        </Stack>

        {
            appSession.activeSession() === false ?
            <Navigate to="/login"/> :
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2} padding={1}>

                {/* Users */}
                <Stack direction="column" spacing={1} padding={1} width="100%" alignItems="flex-start" justifyContent="flex-start"
                       sx={sectionStack}>
                    <Stack direction="row" width="100%"
                           justifyContent="space-between"
                           padding={1} spacing={1}>
                        <Typography variant="h6">Authorizations</Typography>
                        <Stack direction="row" width="100%" spacing={2} justifyContent="flex-end">
                            <Button onClick={onCancel} sx={buttonStyle} startIcon={<CancelIcon />}>CANCEL</Button>
                            <Button onClick={onCreate} sx={buttonStyle} startIcon={<Check />}>CREATE</Button>
                        </Stack>
                    </Stack>
                    <Divider orientation="horizontal" variant="left" flexItem />
                    <Stack direction="column" spacing={1} width="100%" minHeight={100}
                           sx={{backgroundColor: '#141c21'}}>
                        <Select inputRef={userSelect} defaultValue="user1" labelId="userSelectLabel">
                            <MenuItem value="user1">User 1</MenuItem>
                            <MenuItem value="user2">User 2</MenuItem>
                            <MenuItem value="user3">User 3</MenuItem>
                        </Select>

                    </Stack>
                    <Divider orientation="horizontal" variant="left" flexItem />
                </Stack>

            </Stack>
        }

    </Stack>
};

export default UserAuthAdd;
