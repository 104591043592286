import React, {useEffect} from "react";
import {Divider, Stack, Typography} from "@mui/material";
import {dataManager} from "../../../Broadtask";
import {sectionPanel, sectionStack, subSectionPanel} from "../../../../AppStyles";

const StreamDetailsPane = (props) => {

    useEffect(() => {
        dataManager.getNetworkManager().getInstance(props.networkUID).getStreamInstance(props.streamUID).getSettings(false, (details) => {
            console.log(details);
        });
    }, []);

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%"
               alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1} sx={sectionPanel}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/stream.png" width={32} height={32}/>
                    <Typography variant="h6">Data Stream Details</Typography>
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
            <Stack direction="column" spacing={1} width="100%" minHeight={100}
                   sx={subSectionPanel}>

            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
        </Stack>
    );
}

export default StreamDetailsPane;
