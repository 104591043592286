import {Stack, Typography} from "@mui/material";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import {Lock, StopCircle} from "@mui/icons-material";

const NetworkCard = (props) =>
{
    const navigate = useNavigate();
    function onView()
    {
        navigate("../networks/" + props.entry.networkUID);
    }

    return (
        <Stack direction="column" padding={1} spacing={1} alignItems="center" backgroundColor={'#141C21'} onClick={onView} borderRadius={3} sx={{width: 200, height: 200, cursor: "pointer"}}>
            <Stack direction="row" width="100%" alignItems="center" spacing={1}>
                <img src="/icons/network.png" width={32} height={32}/>
                <Typography flexWrap="wrap">{props.entry.networkUID} </Typography>
            </Stack>
            <Stack direction="row">
                <Typography flexWrap="wrap" fontSize="small" sx={{ color: '#808183'}}>{props.entry.display}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
                <img src="/icons/network.png" width={96} height={96}/>
            </Stack>
            <Stack direction="row" alignItems="center">
                {/*<StopCircle />*/}
                {/*<Lock />*/}
            </Stack>
        </Stack>
    )
}
export default NetworkCard;
