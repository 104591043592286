import {Breadcrumbs, Button, Divider, Link, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useNavigate} from "react-router-dom";
import * as React from "react";
import {useState} from "react";
import {networkAPI} from "../../api/BroadtaskAPI";
import {appSession, dataManager} from "../../Broadtask";
import CancelIcon from "@mui/icons-material/Cancel";
import {Check} from "@mui/icons-material";
import {buttonStyle, sectionStack, subSectionPanel} from "../../../AppStyles";

const DIOSCreate = () =>
{
    const navigate = useNavigate();

    const [key, setKey] = useState("");
    const [name, setName] = useState("");
    const [diosDriver, setDriver] = useState("");
    const [fileIcon, setFileIcon] = useState(null);

    function onCancel()
    {
        navigate("/dios");
    }

    async function onCreate()
    {
        const settings = {
            diosKey: key,
            diosName: name,
            diosDriver: diosDriver
        };

        dataManager.getDIOSManager().createDIOS(settings, (success, response) => {
            if (success === true)
            {
                const diosUID = response;

                if (fileIcon)
                {
                    const iconUpdated = networkAPI.updateIcon(diosUID, fileIcon);
                    console.log(iconUpdated);
                }

                navigate("/dios/" + diosUID);
            }
        });
    }

    async function onChangeIcon(fileImage)
    {
        setFileIcon(fileImage);
    }

    return <Stack spacing={1}>
        <Stack direction="row"
               sx={{ backgroundColor: '#182229'}}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/dios"} underline={"hover"}>Data Sources</Link>
                <Typography>Create</Typography>
            </Breadcrumbs>
        </Stack>

        {
            appSession.activeSession() === false ?
            <Navigate to="/login" />:
                <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                       spacing={2} padding={1}>
                {/* Create Editor */}
                    <Stack direction="column" spacing={1} padding={1} width="100%" alignItems="flex-start" justifyContent="flex-start"
                           sx={sectionStack}>
                        <Stack direction="row" width="100%"
                               justifyContent="space-between"
                               padding={1} spacing={1}>
                        <Typography variant="h6">Create a Data Source</Typography>
                        <Stack direction="row" spacing={1}>
                            <Button onClick={onCancel} sx={buttonStyle} startIcon={<CancelIcon />}>CANCEL</Button>
                            <Button onClick={onCreate} sx={buttonStyle} startIcon={<Check />}>CREATE</Button>
                        </Stack>
                    </Stack>
                        <Divider orientation="horizontal" variant="left" flexItem />
                        <Stack direction="row" spacing={1} padding={1} width="100%" minHeight={100}
                               sx={subSectionPanel}>

                        <Stack direction="column" justifyContent="center">
                            <Stack direction="column" alignItems="center" justifyContent="center" width={150} height={150} sx={{ backgroundColor: '#000000', borderRadius:2, borderColor: '#FFFFFF', borderStyle:'solid' }}>
                                <img src="/icons/dios.png" height={128} width={128} />
                            </Stack>
                        </Stack>
                        <Stack direction="column" spacing={1} width="100%">
                            <Select inputRef={diosDriver} label="Driver" onChange={(event) => { setDriver(event.target.value); }}>
                                <MenuItem value="AWS_S3">AWS S3</MenuItem>
                                <MenuItem value="AWS_DYNAMODB">AWS DynamoDB</MenuItem>
                                <MenuItem value="MYSQL">MySQL Database</MenuItem>
                                <MenuItem value="HTTP">HTTP</MenuItem>
                            </Select>
                            <TextField label="Key" fullWidth={true} size="small" value={key} type="text" onChange={(event) => { setKey(event.target.value); }} />
                            <TextField label="Name" fullWidth={true} size="small" value={name} type="text" onChange={(event) => { setName(event.target.value); }} />
                        </Stack>

                    </Stack>
                </Stack>

            </Stack>
        }

    </Stack>
};

export default DIOSCreate;
