import {userAPI} from "../api/BroadtaskAPI";

export default class UserManager
{
    constructor()
    {

    }

    getSettings(force, callback)
    {
        if (this.settings !== undefined && this.settings !== null && force === false)
        {
            callback(this.settings);
            return;
        }

        const apiCall = userAPI.getUserSettings("SOME_USER_ID");
        apiCall.then(response => {
            this.settings = response.data;
            callback(this.settings);
        })
        .catch((reason) => {
            console.log("UserManager::getSettings::ERROR::" + reason.response.data);
            callback(null);
        })
    }

}
