import {
    channelAPI,
    networkAPI,
    networkAuthAPI,
    networkTaskableAPI,
    requestAPI,
    streamAPI,
    utilizationAPI
} from "../api/BroadtaskAPI";
import NetworkTaskable from "./NetworkTaskable";
import ChannelInstance from "./ChannelInstance";
import StreamInstance from "./StreamInstance";
import RequestInstance from "./RequestInstance";
import {appMessaging} from "../Broadtask";

export default class NetworkInstance
{
    constructor(networkUID)
    {
        this.networkUID = networkUID;

        this.requestInstances = new Map();

        this.channelInstances = new Map();
        this.streamInstances = new Map();
        this.taskableInstances = new Map();

        this.utilization = new Map();
    }

    getSettings(force, callback)
    {
        if (this.settings === true && force === false)
        {
            callback(this.settings);
            return;
        }

        const apiCall = networkAPI.getNetworkSettings(this.networkUID);
        apiCall.then(response => {

            this.settings = response.data;

            callback(this.settings);
        })
        .catch((reason) => {
            console.log("NetworkInstance::" + this.networkUID + "::getSettings::ERROR::" + reason.response.data);
            callback(null);
        })
    }

    getAuthorizations(force, callback)
    {
        if (this.authorizations === true && force === false)
        {
            callback(this.authorizations);
            return;
        }

        const apiCall = networkAuthAPI.getAuthorizations(this.networkUID);
        apiCall.then(response => {

            this.authorizations = response.data;

            callback(this.authorizations);
        })
            .catch((reason) => {
                console.log("NetworkInstance::" + this.networkUID + "::getAuthorizations::ERROR::" + reason.response.data);
                callback([]);
            })
    }

    getChannels(force, callback)
    {
        if (this.channels === true && force === false)
        {
            callback(this.channels);
            return;
        }

        const apiCall = channelAPI.getChannels(this.networkUID);
        apiCall.then(response => {

            this.channels = response.data;
            callback(this.channels);
        })
        .catch((reason) => {
            console.log("NetworkInstance::getChannels::ERROR::" + reason.response.data);
            callback([]);
        })
    }

    createChannel(settings, callback)
    {
        const apiCall = channelAPI.createChannel(this.networkUID, settings);
        apiCall.then((response) => {

            //Null out the channels to force reload
            this.channels = null;

            const channelUID = response.data.channelUID;
            appMessaging.showSuccess(settings.display + ' channel created', 5000);

            callback(true, channelUID);
        })
        .catch((error) => {
            appMessaging.showError('Error creating channel: ' + error, 10000);

            callback(false, error);
        });
    }

    getChannelInstance(channelUID)
    {
        if (this.channelInstances.has(channelUID) === true)
        {
            return this.channelInstances.get(channelUID);
        }

        const configuration = new ChannelInstance(this.networkUID, channelUID);
        this.channelInstances.set(channelUID, configuration);

        return configuration;
    }

    getProcesses(force, callback)
    {
        callback([]);

        // const apiCall = networkAPI.getRequests(this.networkUID, filterStatus, filterTime);
        // apiCall.then(response => {
        //     callback(response.data);
        // })
        //     .catch((reason) => {
        //         console.log("NetworkInstance::getRequests::ERROR::" + reason.response.data);
        //         callback(null);
        //     })
    }

    getRequests(filterStatus, filterTime, callback)
    {
        const apiCall = requestAPI.getRequests(this.networkUID, filterStatus, filterTime);
        apiCall.then(response => {
            callback(response.data);
        })
        .catch((reason) => {
            console.log("NetworkInstance::getRequests::ERROR::" + reason.response.data);
            callback(null);
        })
    }

    getRequestInstance(requestUID)
    {
        if (this.requestInstances.has(requestUID) === true)
        {
            return this.requestInstances.get(requestUID);
        }

        const instance = new RequestInstance(this.networkUID, requestUID);
        this.requestInstances.set(requestUID, instance);

        return instance;
    }

    getStreams(force, callback)
    {
        if (this.streams === true && force === false)
        {
            callback(this.streams);
            return;
        }

        const apiCall = streamAPI.getStreams(this.networkUID);
        apiCall.then(response => {

            this.streams = response.data;

            callback(this.streams);
        })
        .catch((reason) => {
            console.log("NetworkInstance::" + this.networkUID + "::getStreams::ERROR::" + reason.response.data);
            callback([]);
        })
    }

    createStream(settings, callback)
    {
        const apiCall = streamAPI.createStream(this.networkUID, settings);
        apiCall.then((response) => {

            //Null out the streams to force reload
            this.streams = null;

            const streamUID = response.data.streamUID;
            appMessaging.showSuccess(settings.display + ' data stream created', 5000);

            callback(true, streamUID);
        })
            .catch((error) => {
                appMessaging.showError('Error creating data stream: ' + error, 10000);

                callback(false, error);
            });
    }

    getStreamInstance(streamUID, force, callback)
    {
        if (this.streamInstances.has(streamUID) === true && force === false)
        {
            return this.streamInstances.get(streamUID);
        }

        const configuration = new StreamInstance(this.networkUID, streamUID);
        this.streamInstances.set(streamUID, configuration);

        return configuration;
    }

    getTaskables(force, callback)
    {
        if (this.taskables === true && force === false)
        {
            callback(this.taskables);
            return;
        }

        const apiCall = networkTaskableAPI.getTaskables(this.networkUID);
        apiCall.then(response => {

            this.taskables = response.data;

            callback(this.taskables);
        })
        .catch((reason) => {
            console.log("NetworkInstance::" + this.networkUID + "::getTaskables::ERROR::" + reason.response.data);
            callback([]);
        })
    }

    getTaskable(taskableUID)
    {
        if (this.taskableInstances.has(taskableUID) === true)
        {
            return this.taskableInstances.get(taskableUID);
        }

        const configuration = new NetworkTaskable(this.networkUID, taskableUID);
        this.taskableInstances.set(taskableUID, configuration);

        return configuration;
    }

    getUtilization(periodKey, force, callback)
    {
        if (this.utilization.has(periodKey) === true && force === false)
        {
            callback(this.utilization.get(periodKey));
            return;
        }

        const apiCall = utilizationAPI.getNetworkUtilization(this.networkUID, periodKey);
        apiCall.then(response => {

            this.utilization.set(periodKey, response.data);

            callback(this.utilization.get(periodKey));
        })
            .catch((reason) => {
                console.log("NetworkInstance::" + this.networkUID + "::getUtilization::ERROR::" + reason.response.data);
                callback(null);
            })
    }

}
