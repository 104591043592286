import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useParams} from "react-router-dom";
import {appSession} from "../../../Broadtask";
import * as React from "react";

const RequestViewer = () =>
{
    const {networkUID, requestUID} = useParams();

    return <Stack spacing={1}>
        <Stack direction="row"
               sx={{ backgroundColor: '#1e1e1e'}}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/networks"} underline={"hover"}>Networks</Link>
                <Link component={RouterLink} to={"/networks/" + networkUID} underline={"hover"}>{networkUID}</Link>
                <Link component={RouterLink} to={"/networks/" + networkUID + "/requests"} underline={"hover"}>Requests</Link>
                <Typography>{requestUID}</Typography>
            </Breadcrumbs>
        </Stack>

        {
            appSession.activeSession() === false ?
            <Navigate to="/login"/> :
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2} padding={0}>

            </Stack>
        }

    </Stack>
};

export default RequestViewer;
