import React, {useEffect, useState} from "react";
import {Button, Stack, TextField, Typography} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {dataManager} from "../../Broadtask";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";

const UserProfilePane = (props) => {

    const [editMode, setEditMode] = useState(false);

    const [display, setDisplay] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [cellPhone, setCellPhone] = useState("");

    useEffect(() => {
        dataManager.getUserManager().getSettings(false,(profile) => {
            setDisplay(profile.display);
            setEmailAddress(profile.emailAddress);
            setPhoneNumber(profile.phoneNumber);
            setCellPhone(profile.cellPhone);
        });
    }, []);

    function onEdit()
    {
        setEditMode(true);
    }

    function onCancel()
    {
        setEditMode(false);
    }

    function onSave()
    {

    }


    return (
        <Stack direction="column" spacing={1} padding={1} width="100%"
               alignItems="flex-start" justifyContent="flex-start"
               sx={{ backgroundColor: '#0a0a0a', borderRadius: 2}}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/user.png" width={32} height={32}/>
                    <Typography variant="h6">User Profile</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    {
                        editMode === false ?
                            <Button onClick={onEdit} startIcon={<EditIcon />}>Edit</Button> :
                            <Stack direction="row">
                                <Button onClick={onCancel} startIcon={<CancelIcon />}>Cancel</Button>
                                <Button onClick={onSave} startIcon={<SaveIcon />}>Save</Button>
                            </Stack>
                    }
                </Stack>
            </Stack>
            <Stack direction="column" spacing={2} width="100%" alignItems="flex-start">
                <Stack direction="row" width="100%" spacing={1}>
                    <Typography minWidth={200}>Display:</Typography>
                    {
                        editMode === false ?
                            <Typography>{display}</Typography> :
                            <TextField fullWidth={true} size="small" value={display} type="text" onChange={(event) => { setDisplay(event.target.value); }} />
                    }
                </Stack>
                <Stack direction="row" width="100%" spacing={1}>
                    <Typography minWidth={200}>Email Address:</Typography>
                    {
                        editMode === false ?
                            <Typography>{emailAddress}</Typography> :
                            <TextField fullWidth={true} size="small" value={display} type="text" onChange={(event) => { setEmailAddress(event.target.value); }} />
                    }
                </Stack>
                <Stack direction="row" width="100%" spacing={1}>
                    <Typography minWidth={200}>Phone Number:</Typography>
                    {
                        editMode === false ?
                            <Typography>{phoneNumber}</Typography> :
                            <TextField fullWidth={true} size="small" value={display} type="text" onChange={(event) => { setPhoneNumber(event.target.value); }} />
                    }
                </Stack>
                <Stack direction="row" width="100%" spacing={1}>
                    <Typography minWidth={200}>Cell Phone:</Typography>
                    {
                        editMode === false ?
                            <Typography>{cellPhone}</Typography> :
                            <TextField fullWidth={true} size="small" value={display} type="text" onChange={(event) => { setCellPhone(event.target.value); }} />
                    }
                </Stack>
            </Stack>
        </Stack>
);
}

export default UserProfilePane;
