import {Breadcrumbs, Divider, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {appSession} from "../../Broadtask";
import {sectionPanel, sectionStack, sectionTitle, subSectionPanel} from "../../../AppStyles";

const ProcessStageCreate = () =>
{
    const navigate = useNavigate();
    const {processUID} = useParams();

    return <Stack spacing={1}>
        <Stack direction="row"
               sx={sectionTitle}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/processes"} underline={"hover"}>Processes</Link>
                <Link component={RouterLink} to={"/processes/" + processUID} underline={"hover"}>{processUID}</Link>
                <Link component={RouterLink} to={"/processes/" + processUID} underline={"hover"}>Stages</Link>
                <Typography>Create Stage</Typography>
            </Breadcrumbs>
        </Stack>
        {
            appSession.activeSession() === false ?
            <Navigate to="/login"/> :
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2}
                   padding={1}>

                {/*HBox*/}
                <Stack direction="row" spacing={1} justifyContent="stretch" alignItems="flex-start">

                    {/* Settings */}
                    <Stack direction="column" spacing={1} padding={1} width="40%"
                           alignItems="flex-start" justifyContent="flex-start"
                           sx={sectionStack}>
                        <Stack direction="row" width="100%"
                               justifyContent="space-between"
                               padding={1} spacing={1}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <img src="/icons/stage.png" width={32} height={32}/>
                                <Typography variant="h6">Stage Settings</Typography>
                            </Stack>
                        </Stack>
                        <Divider orientation="horizontal" variant="left" flexItem />
                        <Stack direction="row" spacing={1} padding={1} width="100%"
                               sx={subSectionPanel}>

                            <Stack direction="column" spacing={1} padding={1} width="100%" alignItems="flex-start" justifyContent="flex-start">
                                <Typography>POSITION</Typography>
                                <Typography>KEY</Typography>
                                <Typography>Name</Typography>
                            </Stack>
                        </Stack>
                        <Divider orientation="horizontal" variant="left" flexItem />
                    </Stack>

                </Stack>

            </Stack>
        }
    </Stack>
};

export default ProcessStageCreate;
