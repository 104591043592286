import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useParams} from "react-router-dom";
import * as React from "react";
import {appSession} from "../../Broadtask";
import DIOSSettingsPane from "../../components/dios/DIOSSettingsPane";
import DIOSParametersPane from "../../components/dios/DIOSParametersPane";
import DIOSFilesPane from "../../components/dios/DIOSFilesPane";
import {sectionTitle} from "../../../AppStyles";

const DIOSViewer = () =>
{
    const {diosUID} = useParams();

    return <Stack spacing={2} padding={1}>
        <Stack direction="row"
               sx={sectionTitle}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/dios"} underline={"hover"}>Data Sources</Link>
                <Typography>{diosUID}</Typography>
            </Breadcrumbs>
        </Stack>

        {
            appSession.activeSession() === false ?
            <Navigate to="/login"/> :
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2}
                   padding={1}>

                <DIOSSettingsPane diosUID={diosUID} />

                <DIOSParametersPane diosUID={diosUID} />

                <DIOSFilesPane diosUID={diosUID} />

            </Stack>
        }

    </Stack>
};

export default DIOSViewer;
