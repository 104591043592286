import {Stack, Typography} from "@mui/material";
import * as React from "react";
import {useNavigate} from "react-router-dom";

const TaskableCard = (props) =>
{
    const navigate = useNavigate();

    let iconPath = "/icons/taskable.png";

    switch (props.entry.taskableType)
    {
        case "COMPUTER":
            iconPath = "/icons/taskable_computer.png";
            break;
        case "MOBILE_ANDROID":
            iconPath = "/icons/taskable_android.png";
            break;
        case "MOBILE_IOS":
            iconPath = "/icons/taskable_ios.png";
            break;
        case "PI":
            iconPath = "/icons/taskable_pi.png";
            break;
        case "GRAPHENE_OS":
            iconPath = "/icons/taskable_grapheneos.png";
            break;
    }

    function onSelect()
    {
        navigate("../taskables/" + props.entry.taskableUID);
    }

    return (
        <Stack direction="column" padding={1} spacing={1} alignItems="center" backgroundColor={'#141C21'} onClick={onSelect} borderRadius={3} sx={{width: 200, height: 200, cursor: "pointer"}}>
            <Stack direction="row" width="100%" alignItems="center" spacing={1}>
                <img src="/icons/taskable.png" width={32} height={32}/>
                <Typography flexWrap="wrap">{props.entry.taskableUID} </Typography>
            </Stack>
            <Stack direction="row">
                <Typography flexWrap="wrap" fontSize="small" sx={{ color: '#808183'}}>{props.entry.display}</Typography>
            </Stack>
            <Stack direction="column" alignItems="center">
                <img src={iconPath} width={64} height={64}/>
                <Typography variant="caption" padding={0}>{props.entry.taskableType}</Typography>
            </Stack>
        </Stack>


    )
}
export default TaskableCard;
