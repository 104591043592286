import {Breadcrumbs, Button, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useNavigate, useParams} from "react-router-dom";
import {appMessaging, appSession} from "../../../Broadtask";
import * as React from "react";
import {mainHeader} from "../../../../AppStyles";

const RequestCreate = () =>
{
    const navigate = useNavigate();
    const {networkUID} = useParams();

    function onCreate()
    {
        appMessaging.showSuccess("Request created", 2000);

        navigate("../networks/" + networkUID + "/requests/12345")
    }

    return <Stack spacing={1}>
        <Stack direction="row"
               sx={mainHeader}
               alignItems="center" justifyContent="space-between" padding={1}>

        <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/networks"} underline={"hover"}>Networks</Link>
                <Link component={RouterLink} to={"/networks/" + networkUID} underline={"hover"}>{networkUID}</Link>
                <Link component={RouterLink} to={"/networks/" + networkUID + "/requests"} underline={"hover"}>Requests</Link>
                <Typography>Create a Request</Typography>
            </Breadcrumbs>
        </Stack>

        {
            appSession.activeSession() === false ?
            <Navigate to="/login"/> :
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2} padding={0}>

                {/* Submit Request */}
                <Stack direction="column" spacing={1} padding={1} width="100%"
                       alignItems="flex-start" justifyContent="flex-start"
                       sx={{ backgroundColor: '#0a0a0a', borderRadius: 2}}>
                    <Stack direction="row" width="100%"
                           justifyContent="space-between"
                           padding={1} spacing={1}>
                        <Typography variant="h6">Create Request</Typography>
                    </Stack>
                    <Stack direction="column" spacing={1} width="100%" minHeight={100}
                           sx={{ backgroundColor: '#1e1e1e'}}>

                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" paddingTop={1}>
                        <Button onClick={onCreate}>Create</Button>
                    </Stack>
                </Stack>

            </Stack>
        }

    </Stack>
};

export default RequestCreate;
