import React, {useEffect, useState} from "react";
import {Button, Divider, Stack, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {dataManager} from "../../Broadtask";
import GridViewIcon from "@mui/icons-material/GridView";
import TaskableCard from "./TaskableCard";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {buttonStyle, sectionStack} from "../../../AppStyles";

const TaskableManagerPane = (props) => {

    const navigate = useNavigate();
    const [taskables, setTaskables] = useState(null);

    useEffect(() => {
        reload(false);
    }, []);

    function onRefresh()
    {
        reload(true);
    }

    function reload(force)
    {
        dataManager.getTaskableManager().getTaskables(force, (taskables) => {
            setTaskables(taskables);
        });
    }

    function onView()
    {
        navigate("../taskables/G4Y225SF92");
    }

    function onCreate()
    {
        navigate("../taskables/create");
    }

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%" alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/taskable.png" width={32} height={32}/>
                    <Typography variant="h6">Taskables</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Button onClick={onView}
                            sx={buttonStyle}
                            startIcon={<GridViewIcon />}>VIEW</Button>
                    <Button onClick={onCreate}
                            sx={buttonStyle}
                            startIcon={<AddCircleOutlineIcon />}>CREATE</Button>
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
            <Stack direction="row" alignItems="center" spacing={1} padding={1} width="100%" minHeight={100}
                   useFlexGap sx={{ flexWrap: "wrap", backgroundColor: '#2A333A'}}>
            {
                taskables === null ?
                    <Typography variant="h6">Loading...</Typography>:
                    taskables.map(nextTaskable => {
                        return (<TaskableCard key={"taskable_" + nextTaskable.taskableUID} entry={nextTaskable} />)
                    })
            }
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
        </Stack>
);
}

export default TaskableManagerPane;
