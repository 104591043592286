import React, {useEffect, useState} from "react";
import {Divider, Stack, Typography} from "@mui/material";
import {dataManager} from "../../Broadtask";
import TaskableApplicationCard from "./TaskableApplicationCard";
import {sectionStack, subSectionPanel} from "../../../AppStyles";

const TaskableApplicationsPane = (props) => {

    const [applications, setApplications] = useState(null);

    useEffect(() => {
        dataManager.getTaskableManager().getInstance(props.taskableUID).getApplications(false, (profileApps) => {
            setApplications(profileApps);
        });
    }, [props.taskableUID]);

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%"
               alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/application_registration.png" width={32} height={32}/>
                    <Typography variant="h6">Applications</Typography>
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
            <Stack direction="row" spacing={1} width="100%" minHeight={100}
                   sx={subSectionPanel}>
            {
                applications === null ?
                <Typography>Loading...</Typography>:
                applications.map(nextApp => {
                   return <TaskableApplicationCard key={"applications_" + nextApp.key}  entry={nextApp} />
                })
            }
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
        </Stack>
);
}

export default TaskableApplicationsPane;
