import {Breadcrumbs, Button, Divider, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useNavigate, useParams} from "react-router-dom";
import {appMessaging, appSession} from "../../../Broadtask";
import * as React from "react";
import AddBoxIcon from '@mui/icons-material/AddBox';
import {mainHeader, sectionStack} from "../../../../AppStyles";

const ChannelCreate = () =>
{
    const navigate = useNavigate();
    const {networkUID} = useParams();

    function onCreate()
    {
        appMessaging.showSuccess("Channel created", 2000);

        navigate("../networks/" + networkUID + "/channels/ch3342");
    }

    return <Stack spacing={1}>
        <Stack direction="row"
               sx={mainHeader}
               alignItems="center" justifyContent="space-between" padding={1}>

        <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/networks"} underline={"hover"}>Networks</Link>
                <Link component={RouterLink} to={"/networks/" + networkUID} underline={"hover"}>{networkUID}</Link>
                <Link component={RouterLink} to={"/networks/" + networkUID + "/channels"} underline={"hover"}>Channels</Link>
                <Typography>Create a Channel</Typography>
            </Breadcrumbs>
        </Stack>

        {
            appSession.activeSession() === false ?
            <Navigate to="/login"/> :
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2} padding={1}>

                {/* Create Channel */}
                <Stack direction="column" spacing={1} padding={1} width="100%" alignItems="flex-start" justifyContent="flex-start"
                       sx={sectionStack}>
                    <Stack direction="row" width="100%"
                           justifyContent="space-between"
                           padding={1} spacing={1}>
                        <Typography variant="h6">Channel Settings</Typography>
                    </Stack>
                    <Divider orientation="horizontal" variant="left" flexItem />
                    <Stack direction="column" spacing={1} width="100%" minHeight={100}
                           sx={{backgroundColor: '#141c21'}}>

                    </Stack>
                    <Divider orientation="horizontal" variant="left" flexItem />
                    <Stack direction="row" alignItems="center" justifyContent="space-between" paddingTop={1}>
                        <Button startIcon={<AddBoxIcon />} onClick={onCreate}>Create</Button>
                    </Stack>
                </Stack>

            </Stack>
        }
    </Stack>
};

export default ChannelCreate;
