import React, {useEffect} from "react";
import {Button, Divider, Stack, Typography} from "@mui/material";
import {dataManager} from "../../Broadtask";
import {useNavigate} from "react-router-dom";
import {RefreshOutlined, ViewList} from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import GridViewIcon from "@mui/icons-material/GridView";
import {buttonStyle, sectionStack, subSectionPanel} from "../../../AppStyles";

const NetworkUsersPane = (props) => {

    const navigate = useNavigate();

    useEffect(() => {
        reload(false);
    }, [props.networkUID]);

    function reload(force)
    {
        dataManager.getNetworkManager().getInstance(props.networkUID).getAuthorizations(force, (userAuths) => {

        });
    }

    function onRefresh()
    {
        reload(true);
    }

    function onView()
    {
        navigate("/networks/" + props.networkUID + "/users");
    }

    function onAdd()
    {
        navigate("/networks/" + props.networkUID + "/users/add");
    }


    return (
        <Stack direction="column" spacing={1} padding={1} width="100%"
               alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/network_user_authorization.png" width={32} height={32}/>
                    <Typography variant="h6">User Authorizations</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Button onClick={onRefresh} title="Refresh"
                            sx={buttonStyle}
                            startIcon={<RefreshOutlined />}>REFRESH</Button>
                    <Button onClick={onAdd}
                            sx={buttonStyle}
                            startIcon={<AddCircleOutlineIcon />}>ADD</Button>
                    <Button onClick={onView}
                            sx={buttonStyle}
                            startIcon={<GridViewIcon />}>VIEW ALL</Button>
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
            <Stack direction="column" spacing={1} padding={1} width="100%" minHeight={100}
                   sx={subSectionPanel}>

                <Typography variant="caption">List of Authorized Users</Typography>
                <Typography variant="caption">Card: Icon, Username, Name</Typography>

            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
        </Stack>
    );
}

export default NetworkUsersPane;
