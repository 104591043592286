import {requestAPI} from "../api/BroadtaskAPI";

export default class RequestInstance
{
    constructor(networkUID, requestUID)
    {
        this.networkUID = networkUID;
        this.requestUID = requestUID;

        this.utilization = new Map();
    }

    getStatus(force, callback)
    {
        if (this.status === true && force === false)
        {
            callback(this.status);
            return;
        }

        const apiCall = requestAPI.getRequestStatus(this.networkUID, this.requestUID);
        apiCall.then(response => {

            this.status = response.data;

            callback(this.status);
        })
        .catch((reason) => {
            console.log("RequestInstance::" + this.requestUID + "::getStatus::ERROR::" + reason.response.data);
            callback(null);
        })
    }
}
