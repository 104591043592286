import React, {useEffect} from "react";
import {Button, Divider, Stack, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import GridViewIcon from "@mui/icons-material/GridView";
import {buttonStyle, sectionStack, subSectionPanel} from "../../../../../AppStyles";
import {RefreshOutlined} from "@mui/icons-material";

const TaskableExtensionsPane = (props) => {

    const navigate = useNavigate();

    useEffect(() => {

    }, []);

    function onInstall()
    {
        navigate("../networks/" + props.networkUID + "/taskables/" + props.taskableUID + "/extensions/install");
    }

    function onView()
    {
        navigate("../networks/" + props.networkUID + "/taskables/" + props.taskableUID + "/extensions");
    }

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%"
               alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/extension.png" width={32} height={32}/>
                    <Typography variant="h6">Extensions</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Button onClick={onInstall} title="Install"
                            sx={buttonStyle}
                            startIcon={<CloudUploadIcon />}>Install</Button>
                    <Button onClick={onView} title="View All"
                            sx={buttonStyle}
                            startIcon={<GridViewIcon />}>View All</Button>
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
            <Stack direction="column" spacing={1} padding={1} width="100%" minHeight={100}
                   sx={subSectionPanel}>

            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
        </Stack>
    );
}

export default TaskableExtensionsPane;
