import React, {useEffect, useState} from "react";
import {Divider, Stack, Typography} from "@mui/material";
import {dataManager} from "../../Broadtask";
import {sectionStack, subSectionPanel} from "../../../AppStyles";

const TaskableDeviceProfilePane = (props) => {

    const [deviceProfile, setDeviceProfile] = useState(null);

    useEffect(() => {
        dataManager.getTaskableManager().getInstance(props.taskableUID).getDeviceProfile(false, (profile) => {
            setDeviceProfile(profile);
        });
    }, [props.taskableUID]);

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%"
               alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/taskable_device_profile.png" width={32} height={32}/>
                    <Typography variant="h6">Device Profile</Typography>
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
            <Stack direction="column" spacing={1} width="100%" minHeight={100}
                   sx={subSectionPanel}>
                {
                    deviceProfile === null ?
                    <Typography>Loading...</Typography>:
                    <Stack alignItems="flex-start" padding={1}>
                        <Typography>Device Type: {deviceProfile.deviceType}</Typography>
                        <Typography>{deviceProfile.manufacturer}: {deviceProfile.hardwareUUID}</Typography>
                        <Typography>OS: {deviceProfile.OSManufacturer} {deviceProfile.OSFamily} {deviceProfile.OSVersion} {deviceProfile.OSBits}-bit</Typography>
                        <Typography>CPU: {deviceProfile.CPUName} {(deviceProfile.CPUVendorFrequency/1000000000).toFixed(2)} Ghz, {deviceProfile.CPUCores} Cores</Typography>
                        <Typography>RAM: Physical {(deviceProfile.memoryTotal/1024000000).toFixed(2)} GB, Virtual {(deviceProfile.memoryVirtual/1024000000).toFixed(2)} GB</Typography>
                        <Typography>DISK: {(deviceProfile.storageTotals/1024000000).toFixed(0)} GB, Drives: {deviceProfile.storageDisks}</Typography>
                        <Typography>NIC: {deviceProfile.networkMaxSpeed/100000} Mbps</Typography>
                        <Typography>GPU: {deviceProfile.GPUInfo}</Typography>
                        <Typography>
                            Bluetooth: {deviceProfile.bluetoothCapable === true ? "Yes": "No"}
                            , Wi-Fi: {deviceProfile.wifiCapable === true ? "Yes": "No"}
                            , GPS: {deviceProfile.gpsCapable === true ? "Yes": "No"}
                        </Typography>
                    </Stack>
                }
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
        </Stack>
);
}

export default TaskableDeviceProfilePane;
