import React, {useEffect, useState} from "react";
import {Button, Divider, Stack, TextField, Typography} from "@mui/material";
import {appMessaging, dataManager} from "../../Broadtask";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import {buttonStyle, sectionPanel, sectionStack, subSectionPanel} from "../../../AppStyles";

const AccountSettingsPane = (props) => {

    const [editMode, setEditMode] = useState(false);

    const [accountType, setAccountType] = useState("");
    const [accountKey, setAccountKey] = useState("");
    const [display, setDisplay] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyURL, setCompanyURL] = useState("");

    useEffect(() => {
        dataManager.getAccountManager().getDetails(false,(profile) => {
            setAccountType(profile.accountType);
            setAccountKey(profile.accountKey);
            setDisplay(profile.display);
            setCompanyName(profile.companyName);
            setCompanyURL(profile.companyURL);
        });
    }, []);

    function onEdit()
    {
        setEditMode(true);
    }

    function onCancel()
    {
        setEditMode(false);
    }

    function onSave()
    {
        const profile = {
            "accountKey": accountKey,
            "display": display,
            "companyName": companyName,
            "companyURL": companyURL
        };

        dataManager.getAccountManager().saveDetails(profile, (status) => {
            if (status === 200)
            {
                appMessaging.showSuccess("Account profile updated", 5000);
            }
            else
            {
                appMessaging.showError("Error occurred updating account profile", 5000);
            }
        });
    }


    return (
        <Stack direction="column" spacing={1} padding={1} width="100%"
               alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/account.png" width={32} height={32}/>
                    <Typography variant="h6">Account Type: {accountType}</Typography>
                </Stack>
                <Stack direction="row" spacing={1} sx={sectionPanel}>
                    {
                        editMode === false ?
                            <Button onClick={onEdit} sx={buttonStyle} startIcon={<EditIcon />}>Edit</Button> :
                            <Stack direction="row">
                                <Button onClick={onCancel} sx={buttonStyle} startIcon={<CancelIcon />}>Cancel</Button>
                                <Button onClick={onSave} sx={buttonStyle} startIcon={<SaveIcon />}>Save</Button>
                            </Stack>
                    }
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
            <Stack direction="column" spacing={1} padding={1} width="100%" sx={subSectionPanel}>
                <Stack direction="row" spacing={1} width="100%">
                    <Stack direction="row" spacing={1} width="50%" alignItems="center">
                        <Typography minWidth={200}>Account Key:</Typography>
                        {
                            editMode === false ?
                                <Typography>{accountKey}</Typography> :
                                <TextField fullWidth={true} size="small" value={accountKey} type="text" disabled onChange={(event) => { setAccountKey(event.target.value); }} />
                        }
                    </Stack>
                    <Stack direction="row" spacing={1} width="50%" alignItems="center">
                        <Typography minWidth={200}>Account Name:</Typography>
                        {
                            editMode === false ?
                                <Typography>{display}</Typography> :
                                <TextField fullWidth={true} size="small" value={display} type="text" onChange={(event) => { setDisplay(event.target.value); }} />
                        }
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={1} width="100%">
                    <Stack direction="row" spacing={1} width="50%" alignItems="center">
                        <Typography minWidth={200}>Company Name:</Typography>
                        {
                            editMode === false ?
                                <Typography>{companyName}</Typography> :
                                <TextField fullWidth={true} size="small" value={companyName} type="text" onChange={(event) => { setCompanyName(event.target.value); }} />
                        }
                    </Stack>
                    <Stack direction="row" spacing={1} width="50%" alignItems="center">
                        <Typography minWidth={200}>Company URL:</Typography>
                        {
                            editMode === false ?
                                <Typography>{companyURL}</Typography> :
                                <TextField fullWidth={true} size="small" value={companyURL} type="text" onChange={(event) => { setCompanyURL(event.target.value); }} />
                        }
                    </Stack>
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
        </Stack>
);
}

export default AccountSettingsPane;
