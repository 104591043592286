import React, {useEffect, useState} from "react";
import {Button, Divider, Stack, Typography} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {useNavigate} from "react-router-dom";
import {dataManager} from "../../../Broadtask";
import ChannelCard from "./ChannelCard";
import {buttonStyle, sectionStack} from "../../../../AppStyles";

const ChannelManagerPane = (props) => {

    const navigate = useNavigate();
    const [channels, setChannels] = useState(null);

    useEffect(() => {
        reload(false);
    }, []);

    function reload(force)
    {
        dataManager.getNetworkManager().getInstance(props.networkUID).getChannels(force, (channels) => {
            setChannels(channels);
        });
    }

    function onCreate()
    {
        navigate("/networks/" + props.networkUID + "/channels/create");
    }

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%" alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/channel.png" width={32} height={32}/>
                    <Typography variant="h6">Channels</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Button onClick={onCreate}
                            sx={buttonStyle}
                            startIcon={<AddCircleOutlineIcon />}>CREATE</Button>
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
            <Stack direction="row" spacing={1} padding={1} width="100%" minHeight={100}
                   useFlexGap sx={{ flexWrap: "wrap", backgroundColor: '#2A333A'}}>
                {
                    channels === null ?
                        <Typography variant="h6">Loading...</Typography>:
                        channels.map(nextChannel => {
                            return (<ChannelCard networkUID={props.networkUID} key={"channel_" + nextChannel.channelUID} entry={nextChannel} />)
                        })
                }
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
        </Stack>
);
}

export default ChannelManagerPane;
