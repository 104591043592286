import {taskableAPI} from "../api/BroadtaskAPI";
import TaskableInstance from "./TaskableInstance";

export default class TaskableManager
{
    constructor()
    {
        this.instances = new Map();
    }

    getTaskables(force, callback)
    {
        if (this.master !== undefined && this.master !== null && force === false)
        {
            callback(this.master);
            return;
        }

        const apiCall = taskableAPI.getTaskables();
        apiCall.then(response => {
            this.master = response.data;
            callback(this.master);
        })
        .catch((reason) => {
            console.log("TaskableManager::getTaskables::ERROR::" + reason.response.data);
            callback([]);
        })
    }

    getInstance(taskableUID)
    {
        if (this.instances.has(taskableUID) === true)
        {
            return this.instances.get(taskableUID);
        }

        const tiInstance = new TaskableInstance(taskableUID);
        this.instances.set(taskableUID, tiInstance);

        return tiInstance;
    }

}
