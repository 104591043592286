import './App.css';
import {createTheme, CssBaseline, responsiveFontSizes, Stack, ThemeProvider} from "@mui/material";
import {BrowserRouter, Route, Routes} from "react-router-dom";

import AppLogin from "./broadtask/pages/AppLogin";
import AppHome from "./broadtask/pages/AppHome";
import AppHeader from "./broadtask/components/AppHeader";
import AppFooter from "./broadtask/components/AppFooter";
import AppUser from "./broadtask/pages/AppUser";

import AccountSettings from "./broadtask/pages/account/AccountSettings";
import AccountUserEditor from "./broadtask/pages/account/AccountUserEditor";
import AccountContactEditor from "./broadtask/pages/account/AccountContactEditor";
import AccountUserCreate from "./broadtask/pages/account/AccountUserCreate";
import AccountContactCreate from "./broadtask/pages/account/AccountContactCreate";

import ChannelManager from "./broadtask/pages/network/channel/ChannelManager";
import ChannelCreate from "./broadtask/pages/network/channel/ChannelCreate";
import ChannelViewer from "./broadtask/pages/network/channel/ChannelViewer";
import ChannelListenerCreate from "./broadtask/pages/network/channel/ChannelListenerCreate";
import ChannelListenerViewer from "./broadtask/pages/network/channel/ChannelListenerViewer";
import ChannelStreamViewer from "./broadtask/pages/network/channel/ChannelStreamViewer";
import ChannelStreamCreate from "./broadtask/pages/network/channel/ChannelStreamCreate";

import DeploymentMap from "./broadtask/pages/deployment/DeploymentMap";

import DIOSCreate from "./broadtask/pages/dios/DIOSCreate";
import DIOSManager from "./broadtask/pages/dios/DIOSManager";
import DIOSViewer from "./broadtask/pages/dios/DIOSViewer";

import ExtensionManager from "./broadtask/pages/extension/ExtensionManager";
import ExtensionInstaller from "./broadtask/pages/extension/ExtensionInstaller";
import ExtensionViewer from "./broadtask/pages/extension/ExtensionViewer";

import NetworkCreate from "./broadtask/pages/network/NetworkCreate";
import NetworkManager from "./broadtask/pages/network/NetworkManager";
import NetworkViewer from "./broadtask/pages/network/NetworkViewer";
import NetworkUtilization from "./broadtask/pages/network/utilization/NetworkUtilization";
import NetworkDeployment from "./broadtask/pages/network/deployment/NetworkDeployment";

import ProcessCreate from "./broadtask/pages/process/ProcessCreate";
import ProcessEditor from "./broadtask/pages/process/ProcessEditor";
import ProcessManager from "./broadtask/pages/process/ProcessManager";
import ProcessStageEditor from "./broadtask/pages/process/ProcessStageEditor";
import ProcessStageCreate from "./broadtask/pages/process/ProcessStageCreate";
import ProcessStageTaskAdd from "./broadtask/pages/process/ProcessStageTaskAdd";

import RequestCreate from "./broadtask/pages/network/request/RequestCreate";
import RequestViewer from "./broadtask/pages/network/request/RequestViewer";
import RequestManager from "./broadtask/pages/network/request/RequestManager";

import StreamManager from "./broadtask/pages/network/stream/StreamManager";
import StreamCreate from "./broadtask/pages/network/stream/StreamCreate";
import StreamViewer from "./broadtask/pages/network/stream/StreamViewer";
import StreamListenerCreate from "./broadtask/pages/network/stream/StreamListenerCreate";
import StreamListenerViewer from "./broadtask/pages/network/stream/StreamListenerViewer";

import TaskableManager from "./broadtask/pages/taskable/TaskableManager";
import NetworkTaskableAdd from "./broadtask/pages/network/taskable/NetworkTaskableAdd";
import TaskableViewer from "./broadtask/pages/taskable/TaskableViewer";
import TaskableCreate from "./broadtask/pages/taskable/TaskableCreate";
import TaskableHistory from "./broadtask/pages/taskable/TaskableHistory";

import UserAuthManager from "./broadtask/pages/network/user/UserAuthManager";
import UserAuthPermissions from "./broadtask/pages/network/user/UserAuthPermissions";
import UserAuthAdd from "./broadtask/pages/network/user/UserAuthAdd";

import UtilizationSummary from "./broadtask/pages/utilization/UtilizationSummary";
import NetworkTaskableManager from "./broadtask/pages/network/taskable/NetworkTaskableManager";
import NetworkTaskableViewer from "./broadtask/pages/network/taskable/NetworkTaskableViewer";
import TaskableCommandManager from "./broadtask/pages/network/taskable/commands/TaskableCommandManager";
import TaskableCommandViewer from "./broadtask/pages/network/taskable/commands/TaskableCommandViewer";
import TaskableExtensionManager from "./broadtask/pages/network/taskable/extensions/TaskableExtensionManager";
import TaskableExtensionViewer from "./broadtask/pages/network/taskable/extensions/TaskableExtensionViewer";
import TaskableServiceManager from "./broadtask/pages/network/taskable/services/TaskableServiceManager";
import TaskableServiceViewer from "./broadtask/pages/network/taskable/services/TaskableServiceViewer";
import TaskableProcessorManager from "./broadtask/pages/network/taskable/processors/TaskableProcessorManager";
import TaskableProcessorViewer from "./broadtask/pages/network/taskable/processors/TaskableProcessorViewer";
import TaskableExtensionInstall from "./broadtask/pages/network/taskable/extensions/TaskableExtensionInstall";
import NetworkProcessManager from "./broadtask/pages/network/process/NetworkProcessManager";
import NetworkProcessAdd from "./broadtask/pages/network/process/NetworkProcessAdd";
import NetworkProcessViewer from "./broadtask/pages/network/process/NetworkProcessViewer";
import ProcessStageTaskEditor from "./broadtask/pages/process/ProcessStageTaskEditor";

let darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: '#182229',
  },
  typography: {
    fontSize: 14,
  },
});
darkTheme = responsiveFontSizes(darkTheme);

function App() {

  return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <div className="App">
          <div className="App-content">
              <BrowserRouter>
                  <AppHeader />
                  <Stack minHeight={window.innerHeight - 170}>
                      <Routes>
                          <Route path="/" element={<AppHome />} />
                          <Route path="/login" element={<AppLogin />} />

                          <Route path="/account" element={<AccountSettings />} />
                          <Route path="/account/users/create" element={<AccountUserCreate />} />
                          <Route path="/account/users/:userUID" element={<AccountUserEditor />} />
                          <Route path="/account/contacts/create" element={<AccountContactCreate />} />
                          <Route path="/account/contacts/:contactUID" element={<AccountContactEditor />} />

                          <Route path="/deployments" element={<DeploymentMap />} />

                          <Route path="/dios" element={<DIOSManager />} />
                          <Route path="/dios/create" element={<DIOSCreate />} />
                          <Route path="/dios/:diosUID" element={<DIOSViewer />} />

                          <Route path="/extensions" element={<ExtensionManager />} />
                          <Route path="/extensions/install" element={<ExtensionInstaller />} />
                          <Route path="/extensions/:extensionUID" element={<ExtensionViewer />} />

                          <Route path="/processes" element={<ProcessManager />} />
                          <Route path="/processes/create" element={<ProcessCreate />} />
                          <Route path="/processes/:processUID" element={<ProcessEditor />} />
                          <Route path="/processes/:processUID/stages/create" element={<ProcessStageCreate />} />
                          <Route path="/processes/:processUID/stages/:stageUID" element={<ProcessStageEditor />} />
                          <Route path="/processes/:processUID/stages/:stageUID/tasks/add" element={<ProcessStageTaskAdd />} />
                          <Route path="/processes/:processUID/stages/:stageUID/tasks/:taskUID" element={<ProcessStageTaskEditor />} />

                          <Route path="/taskables" element={<TaskableManager />} />
                          <Route path="/taskables/create" element={<TaskableCreate />} />
                          <Route path="/taskables/:taskableUID" element={<TaskableViewer />} />
                          <Route path="/taskables/:taskableUID/history" element={<TaskableHistory />} />

                          <Route path="/user" element={<AppUser />} />

                          <Route path="/utilization" element={<UtilizationSummary />} />

                          <Route path="/networks" element={<NetworkManager />} />
                          <Route path="/networks/create" element={<NetworkCreate />} />
                          <Route path="/networks/:networkUID" element={<NetworkViewer />} />
                          <Route path="/networks/:networkUID/deployments" element={<NetworkDeployment />} />
                          <Route path="/networks/:networkUID/utilization" element={<NetworkUtilization />} />
                          <Route path="/networks/:networkUID/users" element={<UserAuthManager />} />
                          <Route path="/networks/:networkUID/users/add" element={<UserAuthAdd />} />
                          <Route path="/networks/:networkUID/users/:userUID" element={<UserAuthPermissions />} />

                          <Route path="/networks/:networkUID/channels" element={<ChannelManager />} />
                          <Route path="/networks/:networkUID/channels/create" element={<ChannelCreate />} />
                          <Route path="/networks/:networkUID/channels/:channelUID" element={<ChannelViewer />} />
                          <Route path="/networks/:networkUID/channels/:channelUID/listeners/create" element={<ChannelListenerCreate />} />
                          <Route path="/networks/:networkUID/channels/:channelUID/listeners/:listenerUID" element={<ChannelListenerViewer />} />
                          <Route path="/networks/:networkUID/channels/:channelUID/streams/create" element={<ChannelStreamCreate />} />
                          <Route path="/networks/:networkUID/channels/:channelUID/streams/:streamUID" element={<ChannelStreamViewer />} />

                          <Route path="/networks/:networkUID/processes" element={<NetworkProcessManager />} />
                          <Route path="/networks/:networkUID/processes/add" element={<NetworkProcessAdd />} />
                          <Route path="/networks/:networkUID/processes/:processUID" element={<NetworkProcessViewer />} />

                          <Route path="/networks/:networkUID/requests" element={<RequestManager />} />
                          <Route path="/networks/:networkUID/requests/create" element={<RequestCreate />} />
                          <Route path="/networks/:networkUID/requests/:requestUID" element={<RequestViewer />} />

                          <Route path="/networks/:networkUID/streams" element={<StreamManager />} />
                          <Route path="/networks/:networkUID/streams/create" element={<StreamCreate />} />
                          <Route path="/networks/:networkUID/streams/:streamUID" element={<StreamViewer />} />
                          <Route path="/networks/:networkUID/streams/:streamUID/listeners/create" element={<StreamListenerCreate />} />
                          <Route path="/networks/:networkUID/streams/:streamUID/listeners/:listenerUID" element={<StreamListenerViewer />} />

                          <Route path="/networks/:networkUID/taskables" element={<NetworkTaskableManager />} />
                          <Route path="/networks/:networkUID/taskables/add" element={<NetworkTaskableAdd />} />
                          <Route path="/networks/:networkUID/taskables/:taskableUID" element={<NetworkTaskableViewer />} />
                          <Route path="/networks/:networkUID/taskables/:taskableUID/commands" element={<TaskableCommandManager />} />
                          <Route path="/networks/:networkUID/taskables/:taskableUID/commands/:commandUID" element={<TaskableCommandViewer />} />
                          <Route path="/networks/:networkUID/taskables/:taskableUID/extensions" element={<TaskableExtensionManager />} />
                          <Route path="/networks/:networkUID/taskables/:taskableUID/extensions/install" element={<TaskableExtensionInstall />} />
                          <Route path="/networks/:networkUID/taskables/:taskableUID/extensions/:extensionUID" element={<TaskableExtensionViewer />} />
                          <Route path="/networks/:networkUID/taskables/:taskableUID/services" element={<TaskableServiceManager />} />
                          <Route path="/networks/:networkUID/taskables/:taskableUID/services/:serviceUID" element={<TaskableServiceViewer />} />
                          <Route path="/networks/:networkUID/taskables/:taskableUID/processors" element={<TaskableProcessorManager />} />
                          <Route path="/networks/:networkUID/taskables/:taskableUID/processors/:processorUID" element={<TaskableProcessorViewer />} />

                      </Routes>
                  </Stack>
                  <AppFooter />
              </BrowserRouter>
          </div>
        </div>
      </ThemeProvider>
  );
}

export default App;
