import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {appSession} from "../../Broadtask";
import StageSettingsPane from "../../components/process/StageSettingsPane";
import StageTasksPane from "../../components/process/StageTasksPane";
import {sectionTitle} from "../../../AppStyles";

const ProcessStageEditor = () =>
{
    const navigate = useNavigate();
    const {processUID, stageUID} = useParams();

    return <Stack spacing={1} padding={1}>
        <Stack direction="row"
               sx={sectionTitle}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/processes"} underline={"hover"}>Processes</Link>
                <Link component={RouterLink} to={"/processes/" + processUID} underline={"hover"}>{processUID}</Link>
                <Link component={RouterLink} to={"/processes/" + processUID} underline={"hover"}>Stages</Link>
                <Typography>{stageUID}</Typography>
            </Breadcrumbs>
        </Stack>
        {
            appSession.activeSession() === false ?
            <Navigate to="/login"/> :
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2}
                   padding={0}>

                <StageSettingsPane processUID={processUID} stageUID={stageUID} />

                <StageTasksPane processUID={processUID} stageUID={stageUID} />

            </Stack>
        }
    </Stack>
};

export default ProcessStageEditor;
