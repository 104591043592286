import React, {useEffect, useState} from "react";
import {Button, Stack, TextField, Typography} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

const UserPasswordPane = (props) => {

    const [password, setPassword] = useState("");
    const [changedPassword, setChangedPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    useEffect(() => {

    }, []);

    function onSave()
    {

    }


    return (
        <Stack direction="column" spacing={1} padding={1} width="100%"
               alignItems="flex-start" justifyContent="flex-start"
               sx={{ backgroundColor: '#0a0a0a', borderRadius: 2}}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/user.png" width={32} height={32}/>
                    <Typography variant="h6">Password</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Button onClick={onSave} startIcon={<SaveIcon />}>Save</Button>
                </Stack>
            </Stack>
            <Stack direction="column" spacing={1} width="100%">
                <TextField type="password" label="Current Password" fullWidth={true} size="small" value={password} onChange={(event) => { setPassword(event.target.value); }} />
                <TextField type="password" label="New Password" fullWidth={true} size="small" value={changedPassword} type="text" onChange={(event) => { setChangedPassword(event.target.value); }} />
                <TextField type="password" label="Confirm Password" fullWidth={true} size="small" value={confirmPassword} type="text" onChange={(event) => { setConfirmPassword(event.target.value); }} />
            </Stack>
        </Stack>
);
}

export default UserPasswordPane;
