import {Breadcrumbs, Button, Divider, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {appSession} from "../../../Broadtask";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import GridViewIcon from "@mui/icons-material/GridView";
import {buttonStyle, mainHeader, sectionStack} from "../../../../AppStyles";

const UserAuthManager = () =>
{
    const {networkUID} = useParams();

    const navigate = useNavigate();

    function onAdd()
    {
        navigate("/networks/" + networkUID + "/users/add");
    }

    function onView()
    {
        navigate("/networks/" + networkUID + "/users/smithJohn");
    }

    return <Stack spacing={1}>
        <Stack direction="row"
               sx={mainHeader}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/networks"} underline={"hover"}>Networks</Link>
                <Link component={RouterLink} to={"/networks/" + networkUID} underline={"hover"}>{networkUID}</Link>
                <Typography>User Authorizations</Typography>
            </Breadcrumbs>
        </Stack>

        {
            appSession.activeSession() === false ?
            <Navigate to="/login"/> :
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2} padding={1}>

                {/* Users */}
                <Stack direction="column" spacing={1} padding={1} width="100%" alignItems="flex-start" justifyContent="flex-start"
                       sx={sectionStack}>
                    <Stack direction="row" width="100%"
                           justifyContent="space-between"
                           padding={1} spacing={1}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <img src="/icons/network_user_authorization.png" width={32} height={32}/>
                            <Typography variant="h6">User Authorizations</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Button onClick={onView} sx={buttonStyle} startIcon={<GridViewIcon />}>VIEW</Button>
                            <Button onClick={onAdd} sx={buttonStyle} startIcon={<AddCircleOutlineIcon />}>ADD</Button>
                        </Stack>
                    </Stack>
                    <Divider orientation="horizontal" variant="left" flexItem />
                    <Stack direction="column" spacing={1} width="100%" minHeight={100}
                           sx={{backgroundColor: '#141c21'}}>

                    </Stack>
                    <Divider orientation="horizontal" variant="left" flexItem />
                </Stack>

            </Stack>
        }

    </Stack>
};

export default UserAuthManager;
