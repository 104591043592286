import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate} from "react-router-dom";
import * as React from "react";
import NetworkManagerPane from "../../components/network/NetworkManagerPane";
import {appSession} from "../../Broadtask";
import {mainHeader} from "../../../AppStyles";

const NetworkManager = () =>
{

    return <Stack spacing={1}>
        <Stack direction="row"
               sx={mainHeader}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Typography>Networks</Typography>
            </Breadcrumbs>
        </Stack>
        {
            appSession.activeSession() === false ?
            <Navigate to="/login" />:
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2}
                   padding={1}>

                <NetworkManagerPane />

            </Stack>
        }
    </Stack>
};

export default NetworkManager;
