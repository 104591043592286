import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useParams} from "react-router-dom";
import * as React from "react";
import {appSession} from "../../../Broadtask";
import ChannelDetailsPane from "../../../components/network/channel/ChannelDetailsPane";
import ChannelPersistencePane from "../../../components/network/channel/ChannelPersistencePane";
import ChannelListenersPane from "../../../components/network/channel/ChannelListenersPane";
import ChannelUtilizationPane from "../../../components/network/channel/ChannelUtilizationPane";
import ChannelStreamsPane from "../../../components/network/channel/ChannelStreamsPane";
import {sectionTitle} from "../../../../AppStyles";

const ChannelViewer = () =>
{
    const {networkUID, channelUID} = useParams();

    return <Stack spacing={2} padding={1}>
        <Stack direction="row"
               sx={sectionTitle}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/networks"} underline={"hover"}>Networks</Link>
                <Link component={RouterLink} to={"/networks/" + networkUID} underline={"hover"}>{networkUID}</Link>
                <Link component={RouterLink} to={"/networks/" + networkUID + "/channels"} underline={"hover"}>Channels</Link>
                <Typography>{channelUID}</Typography>
            </Breadcrumbs>
        </Stack>

        {
            appSession.activeSession() === false ?
            <Navigate to="/login"/> :
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2} padding={1}>

                {/*Top Row*/}
                <Stack direction="row" spacing={1} justifyContent="stretch" alignItems="flex-start">

                    <ChannelDetailsPane networkUID={networkUID} channelUID={channelUID} />

                    <ChannelPersistencePane networkUID={networkUID} channelUID={channelUID} />

                </Stack>

                <ChannelStreamsPane networkUID={networkUID} channelUID={channelUID} />

                <ChannelListenersPane networkUID={networkUID} channelUID={channelUID} />

                <ChannelUtilizationPane networkUID={networkUID} channelUID={channelUID} />

            </Stack>
        }

    </Stack>
};

export default ChannelViewer;
