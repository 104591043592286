import React, {useEffect, useState} from "react";
import {Button, Divider, Stack, Typography} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import {buttonStyle, sectionStack, subSectionPanel} from "../../../AppStyles";

const DIOSSettingsPane = (props) => {

    const [editMode, setEditMode] = useState(false);

    useEffect(() => {

    }, []);

    function onEdit()
    {
        setEditMode(true);
    }

    function onCancel()
    {
        setEditMode(false);
    }

    function onSave()
    {

    }

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%" alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/dios.png" width={32} height={32}/>
                    <Typography variant="h6">Data Source Settings</Typography>
                </Stack>
                <Divider orientation="horizontal" variant="left" flexItem />
                <Stack direction="row" spacing={1}>
                    {
                        editMode === false ?
                            <Button onClick={onEdit}
                                    sx={buttonStyle}
                                    startIcon={<EditIcon />}>EDIT</Button>:
                            <Stack direction="row" spacing={1}>
                                <Button onClick={onCancel}
                                        sx={buttonStyle}
                                        startIcon={<CancelIcon />}>CANCEL</Button>
                                <Button onClick={onSave}
                                        sx={buttonStyle}
                                        startIcon={<SaveIcon />}>SAVE</Button>
                            </Stack>
                    }
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
            <Stack direction="column" spacing={1} width="100%" minHeight={100}
                   sx={subSectionPanel}>

            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
        </Stack>
);
}

export default DIOSSettingsPane;
