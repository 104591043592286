import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useParams} from "react-router-dom";
import * as React from "react";
import {appSession} from "../../../Broadtask";
import NetworkTaskableAssignmentPane from "../../../components/network/taskable/NetworkTaskableAssignmentPane";
import TaskableExtensionsPane from "../../../components/network/taskable/extensions/TaskableExtensionsPane";
import TaskableCommandsPane from "../../../components/network/taskable/commands/TaskableCommandsPane";
import TaskableServicesPane from "../../../components/network/taskable/services/TaskableServicesPane";
import TaskableProcessorsPane from "../../../components/network/taskable/processors/TaskableProcessorsPane";
import {sectionTitle} from "../../../../AppStyles";

const NetworkTaskableViewer = () =>
{
    const {networkUID, taskableUID} = useParams();

    return <Stack spacing={2} padding={1}>
        <Stack direction="row"
               sx={sectionTitle}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/networks"} underline={"hover"}>Networks</Link>
                <Link component={RouterLink} to={"/networks/" + networkUID} underline={"hover"}>{networkUID}</Link>
                <Link component={RouterLink} to={"/networks/" + networkUID + "/taskables"} underline={"hover"}>Taskables</Link>
                <Typography>{taskableUID}</Typography>
            </Breadcrumbs>
        </Stack>

        {
            appSession.activeSession() === false ?
            <Navigate to="/login"/> :
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2} padding={1}>

                {/*Top Row*/}
                <Stack direction="row" spacing={1} justifyContent="stretch" alignItems="flex-start">

                    <NetworkTaskableAssignmentPane networkUID={networkUID} taskableUID={taskableUID} />

                </Stack>

                <TaskableExtensionsPane networkUID={networkUID} taskableUID={taskableUID} />

                <TaskableCommandsPane networkUID={networkUID} taskableUID={taskableUID} />

                <TaskableServicesPane networkUID={networkUID} taskableUID={taskableUID} />

                <TaskableProcessorsPane networkUID={networkUID} taskableUID={taskableUID} />

            </Stack>
        }

    </Stack>
};

export default NetworkTaskableViewer;
