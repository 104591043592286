import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useParams} from "react-router-dom";
import * as React from "react";
import NetworkUtilizationPane from "../../components/network/NetworkUtilizationPane";
import NetworkDeploymentPane from "../../components/network/NetworkDeploymentPane";
import NetworkDetailsPane from "../../components/network/NetworkDetailsPane";
import NetworkTaskablesPane from "../../components/network/taskable/NetworkTaskablesPane";
import NetworkUsersPane from "../../components/network/NetworkUsersPane";
import {appSession} from "../../Broadtask";
import RequestSelectPane from "../../components/network/request/RequestSelectPane";
import StreamSelectPane from "../../components/network/stream/StreamSelectPane";
import ChannelSelectPane from "../../components/network/channel/ChannelSelectPane";
import NetworkProcessSelectPane from "../../components/network/process/NetworkProcessSelectPane";
import {mainHeader} from "../../../AppStyles";

const NetworkViewer = () =>
{
    const {networkUID} = useParams();

    return <Stack spacing={2}>
        <Stack direction="row"
               sx={mainHeader}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/networks"} underline={"hover"}>Networks</Link>
                <Typography>{networkUID}</Typography>
            </Breadcrumbs>
        </Stack>
        {
            appSession.activeSession() === false ?
            <Navigate to="/login" />:
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2} padding={1}>

                <Stack direction="row" spacing={1} justifyContent="stretch" alignItems="flex-start">

                    <NetworkDetailsPane networkUID={networkUID} />

                    <NetworkUtilizationPane networkUID={networkUID} />

                </Stack>

                <RequestSelectPane networkUID={networkUID} />

                <NetworkTaskablesPane networkUID={networkUID} />

                <NetworkProcessSelectPane networkUID={networkUID} />

                <StreamSelectPane networkUID={networkUID} />

                <ChannelSelectPane networkUID={networkUID} />

                <NetworkDeploymentPane networkUID={networkUID} />

                <NetworkUsersPane networkUID={networkUID} />

            </Stack>
        }

    </Stack>
};

export default NetworkViewer;
