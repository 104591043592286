import APICaller from "./APICaller";

export default class SessionAPI extends APICaller
{
    constructor()
    {
        super();
    }

    start(username, password) {
        const servicePath = "session";

        const payload = {
            "username": username,
            "password": password
        };

        return this.post(servicePath, payload);
    }

    end()
    {
        const servicePath = "session/finish";

        const payload = {

        };

        return this.post(servicePath, payload);
    }
}
