import React, {useEffect} from "react";
import {Button, Divider, Stack, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {dataManager} from "../../Broadtask";
import GridViewIcon from "@mui/icons-material/GridView";
import {RefreshOutlined} from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {buttonStyle, sectionStack, subSectionPanel} from "../../../AppStyles";

const NetworkUtilizationPane = (props) => {

    const navigate = useNavigate();

    useEffect(() => {
        reload(false);
    }, [props.networkUID]);

    function onRefresh()
    {
        reload(true);
    }

    function onView()
    {
        navigate("/networks/" + props.networkUID + "/utilization");
    }

    function reload(force)
    {
        dataManager.getNetworkManager().getInstance(props.networkUID).getUtilization("last24", force, (utilization) => {

        });
    }

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%" alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/utilization.png" width={32} height={32}/>
                    <Typography variant="h6">Utilization</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Button onClick={onRefresh} title="Refresh"
                            sx={buttonStyle}
                            startIcon={<RefreshOutlined />}>Refresh</Button>
                    <Button onClick={onView}
                            sx={buttonStyle}
                            startIcon={<GridViewIcon />}>VIEW</Button>
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
            <Stack direction="column" spacing={1} padding={1} width="100%" minHeight={100}
                   sx={subSectionPanel}>

                <Typography variant="caption">Requests by Hour for Last 24</Typography>
                <Typography variant="caption">Tasks by Hour for Last 24</Typography>
                <Typography variant="caption">Commands by Hour for Last 24</Typography>
                <Typography variant="caption">Channel Messages by Hour for Last 24</Typography>

            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
        </Stack>
);
}

export default NetworkUtilizationPane;
