import {extensionsAPI} from "../api/BroadtaskAPI";

export default class ExtensionInstance
{
    constructor(extensionUID)
    {
        this.extensionUID = extensionUID;

        this.utilization = new Map();
    }

    getSettings(force, callback)
    {
        if (this.settings === true && force === false)
        {
            callback(this.settings);
            return;
        }

        const apiCall = extensionsAPI.getSettings(this.extensionUID);
        apiCall.then(response => {

            this.settings = response.data;

            callback(this.settings);
        })
        .catch((reason) => {
            console.log("ExtensionInstance::getSettings::ERROR::" + reason.response.data);
            callback(null);
        })
    }
}
