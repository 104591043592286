import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useNavigate} from "react-router-dom";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {userAPI} from "../api/BroadtaskAPI";
import {appMessaging, appSession} from "../Broadtask";
import UserProfilePane from "../components/user/UserProfilePane";
import UserNamePane from "../components/user/UserNamePane";
import UserPasswordPane from "../components/user/UserPasswordPane";

const AppUser = () =>
{
    const navigate = useNavigate();

    const [loaded, setLoaded] = useState(false);
    const [settings, setSettings] = useState(null);

    const inputUsername = useRef();
    const inputDisplay = useRef();
    const inputEmail = useRef();
    const inputPhone = useRef();
    const inputCurrentPassword = useRef();
    const inputChangePassword = useRef();
    const inputConfirmPassword = useRef();

    //TODO: Find a way to get the current users uid
    const uid = "UID03033";

    useEffect(() => {

        userAPI.getUserSettings(uid)
            .then((results) => {
                console.log("Loading User Settings....");

                if (results.status && results.status === 200)
                {
                    const loadedSettings = results.data.settings;

                    const hackSettings = {
                        "uid":"UID03033",
                        "username":"pips",
                        "display":"Pipsqueak",
                        "email":"pips@thesqueak.com",
                        "phone":"111-304-2340",
                        "currentPassword":"Pas5word!",
                        "changePassword":"",
                        "confirmPassword":""

                    }
                    setSettings(hackSettings);

                    setLoaded(true);

                    // inputUsername.current.value = settings.username;
                    // inputDisplay.current.value = settings.display;
                    // inputEmail.current.value = settings.email;
                    // inputPhone.current.value = settings.phone;
                    // inputCurrentPassword.current.value = settings.currentPassword;
                    // inputChangePassword.current.value = settings.changePassword;
                    // inputConfirmPassword.current.value = settings.confirmPassword;
                }
            });

    }, []);

    function onClose()
    {
        navigate("/");
    }

    async function onUpdate()
    {
        const username = inputUsername.current.value;
        const display = inputDisplay.current.value;
        const email = inputEmail.current.value;
        const phone = inputPhone.current.value;
        const currentPassword = inputCurrentPassword.current.value;
        const changePassword = inputChangePassword.current.value;
        const confirmPassword = inputConfirmPassword.current.value;

        const details = {
            "uid": settings.uid,
            "username": username,
            "display": display,
            "email": email,
            "phone": phone,
            "currentPassword": currentPassword,
            "changePassword": changePassword,
            "confirmPassword": confirmPassword
        };

        const response = await userAPI.saveUserSettings(settings.uid, details);

        if (response.status && response.status === 200)
        {
            console.log(response.data);

            appMessaging.showSuccess('User settings have been updated.', 5000);

            navigate("/");
        }
        else
        {
            appMessaging.showError('User Settings Update Failed\nPlease check your inputs and retry.', 10000);
        }
    }

    return <Stack spacing={1}>
        <Stack direction="row"
               sx={{ backgroundColor: '#1e1e1e'}}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Typography>User Settings</Typography>
            </Breadcrumbs>
        </Stack>
        {
            appSession.activeSession() === false ?
            <Navigate to="/login" />:
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2} padding={0}>

                <UserProfilePane />

                {/*Top Row*/}
                <Stack direction="row" spacing={1} justifyContent="stretch" alignItems="flex-start">

                    <UserNamePane />

                    <UserPasswordPane />

                </Stack>
            </Stack>
        }
    </Stack>
};

export default AppUser;
