import APICaller from "./APICaller";

export default class UtilizationAPI extends APICaller
{
    constructor()
    {
        super();
    }

    getAccountUtilization(periodKey)
    {
        const servicePath = "account/utilization/" + periodKey;

        return this.get(servicePath);
    }

    getChannelUtilization(networkUID, channelUID, periodKey)
    {
        const servicePath = "networks/" + networkUID + "/channels/" + channelUID + "/utilization/" + periodKey;

        return this.get(servicePath);
    }

    getNetworkUtilization(networkUID, periodKey)
    {
        const servicePath = "networks/" + networkUID + "/utilization/" + periodKey;

        return this.get(servicePath);
    }

    getStreamUtilization(networkUID, streamUID, periodKey)
    {
        const servicePath = "networks/" + networkUID + "/streams/" + streamUID + "/utilization/" + periodKey;

        return this.get(servicePath);
    }

    getTaskableUtilization(taskableUID, periodKey)
    {
        const servicePath = "taskables/" + taskableUID + "/utilization/" + periodKey;

        return this.get(servicePath);
    }
}
