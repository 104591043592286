import {accountAPI, utilizationAPI} from "../api/BroadtaskAPI";

export default class AccountManager
{
    constructor()
    {
        this.accountUtilization = new Map();
    }

    getDetails(force, callback)
    {
        if (this.accountDetails !== undefined && this.accountDetails !== null && force === false)
        {
            callback(this.accountDetails);
            return;
        }

        const apiCall = accountAPI.getDetails();
        apiCall.then(response => {

            if (response.data !== undefined)
            {
                this.accountDetails = response.data;
                callback(this.accountDetails);
            }
            else
            {
                callback(null);
            }
        })
        .catch((reason) => {
            console.log("AccountManager::getDetails::ERROR::" + reason.response.data);
            callback(null);
        })
    }

    saveDetails(details, callback)
    {
        const apiCall = accountAPI.saveDetails(details);
        apiCall.then(response => {
            //TODO: Check response for updated details?
            this.accountDetails = details;
            callback(response.status);
        })
        .catch((reason) => {
            console.log("AccountManager::saveDetails::ERROR::" + reason.response.data);
            callback(false);
        })
    }

    getBilling(force, callback)
    {
        if (this.accountBilling !== undefined && this.accountBilling !== null && force === false)
        {
            callback(this.accountBilling);
            return;
        }

        const apiCall = accountAPI.getBilling();
        apiCall.then(response => {

            this.accountBilling = response.data;

            callback(this.accountBilling);
        })
        .catch((reason) => {
            console.log("AccountManager::getBilling::ERROR::" + reason.response.data);
            callback(null);
        })
    }

    getBillingInvoices(force, callback)
    {
        if (this.accountInvoices !== undefined && this.accountInvoices !== null && force === false)
        {
            callback(this.accountInvoices);
            return;
        }

        const apiCall = accountAPI.getBillingInvoices();
        apiCall.then(response => {

            this.accountInvoices = response.data;

            callback(this.accountInvoices);
        })
        .catch((reason) => {
            console.log("AccountManager::getBillingInvoices::ERROR::" + reason.response.data);
            callback([]);
        })
    }

    saveBilling(billing, callback)
    {
        const apiCall = accountAPI.saveBilling(billing);
        apiCall.then(response => {
            //TODO: Check response for updated billing?
            this.accountBilling = billing;
            callback(response.status);
        })
        .catch((reason) => {
            console.log("AccountManager::saveBilling::ERROR::" + reason.response.data);
            callback(false);
        })
    }

    getSubscription(force, callback)
    {
        if (this.accountSubscription !== undefined && this.accountSubscription !== null && force === false)
        {
            callback(this.accountSubscription);
            return;
        }

        const apiCall = accountAPI.getSubscription();
        apiCall.then(response => {

            this.accountSubscription = response.data;

            callback(this.accountSubscription);
        })
        .catch((reason) => {
            console.log("AccountManager::getSubscription::ERROR::" + reason.response.data);
            callback(null);
        })
    }

    saveSubscription(subscription, callback)
    {
        const apiCall = accountAPI.saveSubscription(subscription);
        apiCall.then(response => {
            //TODO: Check response for updated subscription?
            this.accountSubscription = subscription;
            callback(response.status);
        })
        .catch((reason) => {
            console.log("AccountManager::saveSubscription::ERROR::" + reason.response.data);
            callback(false);
        })
    }

    getContacts(force, callback)
    {
        if (this.accountContacts !== undefined && this.accountContacts !== null && force === false)
        {
            callback(this.accountContacts);
            return;
        }

        const apiCall = accountAPI.getContacts();
        apiCall.then(response => {

            this.accountContacts = response.data;

            callback(this.accountContacts);
        })
        .catch((reason) => {
            console.log("AccountManager::getContacts::ERROR::" + reason.response.data);
            callback([]);
        })
    }

    getUsers(force, callback)
    {
        if (this.accountUsers !== undefined && this.accountUsers !== null && force === false)
        {
            callback(this.accountUsers);
            return;
        }

        const apiCall = accountAPI.getUserAuthorizations();
        apiCall.then(response => {

            this.accountUsers = response.data;

            callback(this.accountUsers);
        })
        .catch((reason) => {
            console.log("AccountManager::getUsers::ERROR::" + reason.response.data);
            callback([]);
        })
    }

    getUtilization(periodKey, force, callback)
    {
        if (this.accountUtilization.has(periodKey) === true && force === false)
        {
            callback(this.accountUtilization.get(periodKey));
            return;
        }

        const apiCall = utilizationAPI.getAccountUtilization(periodKey);
        apiCall.then(response => {

            this.accountUtilization.set(periodKey, response.data);

            callback(this.accountUtilization.get(periodKey));
        })
        .catch((reason) => {
            console.log("AccountManager::getUtilization::ERROR::" + reason.response.data);
            callback(null);
        })
    }
}
