import React, {useEffect, useState} from "react";
import {Button, Divider, Stack, Typography} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {useNavigate} from "react-router-dom";
import {dataManager} from "../../../Broadtask";
import NetworkTaskableCard from "./NetworkTaskableCard";
import GridViewIcon from "@mui/icons-material/GridView";
import {buttonStyle, cardWrapper, sectionStack} from "../../../../AppStyles";

const NetworkTaskableManagerPane = (props) => {

    const navigate = useNavigate();
    const [taskables, setTaskables] = useState(null);

    useEffect(() => {
        reload(false);
    }, []);

    function reload(force)
    {
        dataManager.getNetworkManager().getInstance(props.networkUID).getTaskables(force, (taskables) => {
            setTaskables(taskables);
        });
    }

    function onAdd()
    {
        navigate("/networks/" + props.networkUID + "/taskables/add");
    }

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%" alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/taskable.png" width={32} height={32}/>
                    <Typography variant="h6">Taskables</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Button onClick={onAdd} sx={buttonStyle} startIcon={<AddCircleOutlineIcon />}>ADD</Button>
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
            <Stack direction="row" spacing={1} padding={1} width="100%" minHeight={100}
                   useFlexGap sx={cardWrapper}>
                {
                    taskables === null ?
                        <Typography variant="h6">Loading...</Typography>:
                        taskables.map(nextTaskable => {
                            return (<NetworkTaskableCard key={"taskable_" + nextTaskable.taskableUID} networkUID={props.networkUID} entry={nextTaskable} />)
                        })
                }
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
        </Stack>
);
}

export default NetworkTaskableManagerPane;
