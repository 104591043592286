import {IconButton} from "@mui/material";
import React, {useState} from "react";

const HideShowButton = (props) => {

    const [showPanel, setShowPanel] = useState(true);

    const iconRotation = showPanel ? "rotate(0deg)": "rotate(-180deg)";

    function onTogglePanel()
    {
        setShowPanel(!showPanel);
        props.onChange(showPanel);
    }

    return (
        <IconButton onClick={onTogglePanel} title="Expand/Collapse"
                    sx={{ backgroundColor: '#141c21',color:'#f9a555', width: 36, height: 36, transition: "all 0.2s linear", transform: iconRotation }} >
            <img src="/icons/arrow.png" width={16} height={16} />
        </IconButton>
    )
}

export default HideShowButton;
