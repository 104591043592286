import AccountManager from "./AccountManager";
import DIOSManager from "./DIOSManager";
import ExtensionManager from "./ExtensionManager";
import NetworkManager from "./NetworkManager";
import ProcessManager from "./ProcessManager";
import TaskableManager from "./TaskableManager";
import UserManager from "./UserManager";

export default class DataManager
{
    constructor()
    {

    }

    getAccountManager()
    {
        if (this.accountManager === undefined || this.accountManager === null)
        {
            this.accountManager = new AccountManager();
        }
        return this.accountManager;
    }

    getDIOSManager()
    {
        if (this.diosManager === undefined || this.diosManager === null)
        {
            this.diosManager = new DIOSManager();
        }
        return this.diosManager;
    }

    getExtensionManager()
    {
        if (this.extensionManager === undefined || this.extensionManager === null)
        {
            this.extensionManager = new ExtensionManager();
        }
        return this.extensionManager;
    }

    getNetworkManager()
    {
        if (this.networkManager === undefined || this.networkManager === null)
        {
            this.networkManager = new NetworkManager();
        }
        return this.networkManager;
    }

    getProcessManager()
    {
        if (this.processManager === undefined || this.processManager === null)
        {
            this.processManager = new ProcessManager();
        }
        return this.processManager;
    }

    getTaskableManager()
    {
        if (this.taskableManager === undefined || this.taskableManager === null)
        {
            this.taskableManager = new TaskableManager();
        }
        return this.taskableManager;
    }

    getUserManager()
    {
        if (this.userManager === undefined || this.userManager === null)
        {
            this.userManager = new UserManager();
        }
        return this.userManager;
    }
}
