import {Breadcrumbs, Button, Link, Stack, ToggleButton, Typography} from "@mui/material";
import {Link as RouterLink, Navigate} from "react-router-dom";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import mapboxgl from 'mapbox-gl';
import {appSession} from "../../Broadtask";
import {subSectionPanel, toggleButtonStyle} from "../../../AppStyles";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const DeploymentMap = () =>
{
    const mapContainer = useRef(null);
    const map = useRef(null);
    const taskablesPanel = useRef(null);
    const taskablesViewer = useRef(null);
    const taskableDetailsPanel = useRef(null);

    const [viewerShowing, setViewerShowing] = useState(false);
    const [viewerHeight, setViewerHeight] = useState(50);

    const [lat, setLat] = useState(35.0);
    const [lng, setLng] = useState(-100.0);
    const [zoom, setZoom] = useState(4);

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
//            style: 'mapbox://styles/mapbox/streets-v12',
//             style: 'mapbox://styles/mapbox/dark-v11',
            style: 'mapbox://styles/mapbox/satellite-streets-v12',
            showPlaceLabels: false,
            showPointOfInterestLabels: false,
            showTransitLabels: false,
            center: [lng, lat],
            zoom: zoom
        });

        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });

        map.current.on('load', () => {
            onMapLoad();
        });

    }, []);

    function onMapLoad()
    {
        map.current.loadImage('/map/taskable_marker.png', (error, image) => {
            if (error) throw error;

            map.current.addImage('taskable', image);

            map.current.addSource('deathstar', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': [
                        {
                            'type': 'Feature',
                            'geometry': {
                                'type': 'Point',
                                'coordinates': [-122.720306, 38.444660]
                            }
                        }
                    ]
                }
            });

            map.current.addLayer({
                'id': 'deathstar',
                'type': 'symbol',
                'source': 'deathstar', // reference the data source
                'layout': {
                    'icon-image': 'taskable', // reference the image
                    'icon-size': 0.25
                }
            });
        })
    }

    function onToggleTaskableViewer()
    {
        if (viewerShowing === true)
        {
            setViewerShowing(false);
            setViewerHeight(50);
        }
        else
        {
            setViewerShowing(true);
            setViewerHeight("70vh");
        }
    }

    return <Stack spacing={1}>
        <Stack direction="row"
               sx={{ backgroundColor: '#182229'}}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Typography>Deployments</Typography>
            </Breadcrumbs>
        </Stack>
        {
            appSession.activeSession() === false ?
            <Navigate to="/login" />:
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2}
                   padding={1}>

                {/* Deployments */}
                <Stack direction="column" spacing={1} padding={1} width="100%" height="100%"
                       alignItems="flex-start" justifyContent="flex-start"
                       sx={toggleButtonStyle}>

                    <Stack direction="column" spacing={1} padding={1} width="100%" minHeight={100}
                           sx={subSectionPanel}>
                        <div ref={mapContainer} className="map-container">

                            {/*Taskables Panel*/}
                            <div ref={taskablesPanel} className="map-taskables-panel">
                                <Stack ref={taskablesViewer} direction="column" width={310} height={viewerHeight}>
                                    <Stack direction="row" width="100%"
                                           justifyContent="space-between"
                                           padding={1} spacing={1}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <img src="/icons/taskable.png" width={24} height={24}/>
                                            <Typography variant="subtitle2">Taskables</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1} padding={0}>
                                            <Button onClick={onToggleTaskableViewer}>Show</Button>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </div>

                            {/*Taskable Details Panel*/}
                            <div ref={taskableDetailsPanel} className="map-taskable-details-panel" hidden>
                                <Stack direction="column" width={310} height="70vh">
                                    <Stack direction="row" width="100%"
                                           justifyContent="space-between"
                                           padding={1} spacing={1}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <img src="/icons/taskable.png" width={32} height={32}/>
                                            <Typography variant="h6">Taskable Details</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1} padding={0}>
                                            <Button>Go</Button>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </div>

                        </div>
                        <Stack direction="row" spacing={1} justifyContent="space-between">
                            <div className="sidebar">Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}</div>
                            <ToggleButton value="SOME">XX</ToggleButton>
                        </Stack>
                    </Stack>
                </Stack>

            </Stack>
        }
    </Stack>
};

export default DeploymentMap;
