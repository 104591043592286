import APICaller from "./APICaller";

export default class RequestAPI extends APICaller
{
    constructor()
    {
        super();
    }

    getRequests(networkUID, filterStatus, filterTime)
    {
        const servicePath = "networks/" + networkUID + "/requests?status=" + filterStatus + "&time=" + filterTime;

        return this.get(servicePath);
    }

    createRequest(networkUID, settings)
    {
        const servicePath = "networks/" + networkUID + "/requests";

        return this.post(servicePath, settings);
    }

    cancelRequest(networkUID, requestUID)
    {
        const servicePath = "networks/" + networkUID + "/requests/" + requestUID + "/cancel";

        const payload = {

        };

        return this.post(servicePath, payload);
    }

    deleteRequest(networkUID, requestUID)
    {
        const servicePath = "networks/" + networkUID + "/requests/" + requestUID + "/delete";

        const payload = {

        };

        return this.post(servicePath, payload);
    }

    getInputs(networkUID, requestUID)
    {
        const servicePath = "networks/" + networkUID + "/requests/" + requestUID + "/inputs";

        return this.get(servicePath);
    }

    createInput(networkUID, requestUID, settings)
    {
        const servicePath = "networks/" + networkUID + "/requests/" + requestUID + "/inputs";

        return this.post(servicePath, settings);
    }

    deleteInput(networkUID, requestUID, fileKey)
    {
        const servicePath = "networks/" + networkUID + "/requests/" + requestUID + "/inputs/delete";

        const payload = {
            fileKey: fileKey
        };

        return this.post(servicePath, payload);
    }

    getResults(networkUID, requestUID)
    {
        const servicePath = "networks/" + networkUID + "/requests/" + requestUID + "/results";

        return this.get(servicePath);
    }

    getResultsFile(networkUID, requestUID, fileKey)
    {
        const servicePath = "networks/" + networkUID + "/requests/" + requestUID + "/results/" + fileKey;

        return this.get(servicePath);
    }

    getStatus(networkUID, requestUID)
    {
        const servicePath = "networks/" + networkUID + "/requests/" + requestUID;

        return this.get(servicePath);
    }

    submitRequest(networkUID, requestUID)
    {
        const servicePath = "networks/" + networkUID + "/requests/" + requestUID + "/submit";

        const payload = {

        };

        return this.post(servicePath, payload);
    }
}
