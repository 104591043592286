import {Stack, Typography} from "@mui/material";
import * as React from "react";

const TaskableRuntimeCard = (props) =>
{
    return (
        <Stack direction="row" padding={1} spacing={1} alignItems="center">
            <img src="/icons/application_registration.png" width={64} height={64}/>
            <Stack direction="column" spacing={0} padding={1} alignItems="flex-start">
                <Typography variant="subtitle2">{props.entry.key}</Typography>
                <Typography variant="caption">{props.entry.version}</Typography>
            </Stack>
        </Stack>
    )
}
export default TaskableRuntimeCard;
