import {Breadcrumbs, Divider, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate} from "react-router-dom";
import * as React from "react";
import {appSession} from "../../Broadtask";
import NetworkCard from "../../components/network/NetworkCard";
import {sectionStack} from "../../../AppStyles";

const UtilizationSummary = () =>
{

    return <Stack spacing={1}>
        <Stack direction="row"
               sx={{ backgroundColor: '#182229'}}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Typography>Utilization</Typography>
            </Breadcrumbs>
        </Stack>

        {
            appSession.activeSession() === false ?
            <Navigate to="/login"/> :
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2}
                   padding={1}>

                {/* Utilizations */}
                <Stack direction="column" spacing={1} padding={1} width="100%" height="100%"
                       alignItems="flex-start" justifyContent="flex-start"
                       sx={sectionStack}>
                    <Stack direction="row" width="100%"
                           justifyContent="space-between"
                           padding={1} spacing={1}>
                        <Typography variant="h6">Utilization</Typography>
                        <Stack direction="row" spacing={1}>

                        </Stack>
                    </Stack>
                    <Divider orientation="horizontal" variant="left" flexItem />
                    <Stack direction="row" alignItems="center" spacing={1} padding={1} width="100%" minHeight={100}
                           useFlexGap sx={{ flexWrap: "wrap", backgroundColor: '#2A333A'}}>
                        {
                        }
                    </Stack>
                    <Divider orientation="horizontal" variant="left" flexItem />
                </Stack>
            </Stack>
        }
    </Stack>
};

export default UtilizationSummary;
