import {Button, Stack, TextField, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {appMessaging, appSession} from "../Broadtask";

const AppLogin = () =>
{
    const navigate = useNavigate();

    const [pendingCall, setPendingCall] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {

        appSession.loadLast((loggedIn) => {
            if (loggedIn === true)
            {
                navigate("/");
                return;
            }
        });

    });

    function onEnter(event)
    {
        if (event.keyCode === 13)
        {
            onLogin();
        }
    }

    function onRegister()
    {
        navigate("/register");
    }

    function onLogin()
    {
        setPendingCall(true);

        appSession.login(username, password, (loginStatus) =>
        {
            setPendingCall(false);
            if (loginStatus === true)
            {
                navigate("/");
            }
            else
            {
                appMessaging.showError("Unable to login", 5000);
            }
        });
    }

    return <Stack direction="column"
                  padding={5} spacing={2}
                  justifyContent="space-between" alignItems="center">
        <Stack direction="column" spacing={1} width={500}>
            <Typography variant='subtitle1'>Login</Typography>
            <TextField
                type="text"
                label="Username"
                value={username}
                onChange={(event) => {
                    setUsername(event.target.value);
                }} />
            <TextField
                type="password"
                label="Password"
                value={password}
                onKeyDown={onEnter}
                onChange={(event) => {
                    setPassword(event.target.value);
                }} />
        </Stack>
        <Stack direction="row" width={500} justifyContent="space-between">
            <Stack>
                <Button disabled={pendingCall} onClick={onRegister}>Register</Button>
            </Stack>
            <Stack>
                <Button disabled={pendingCall} onClick={onLogin}>Login</Button>
            </Stack>
        </Stack>
    </Stack>
};

export default AppLogin;
