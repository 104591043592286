import {extensionsAPI} from "../api/BroadtaskAPI";
import ExtensionInstance from "./ExtensionInstance";

export default class ExtensionManager
{
    constructor()
    {
        this.instances = new Map();
    }

    getExtensions(force, callback)
    {
        if (this.master !== undefined && this.master !== null && force === false)
        {
            callback(this.master);
            return;
        }

        const apiCall = extensionsAPI.getExtensions();
        apiCall.then(response => {
            this.master = response.data;
            callback(this.master);
        })
        .catch((reason) => {
            console.log("ExtensionManager::getExtensions::ERROR::" + reason.response.data);
            callback([]);
        })
    }

    getInstance(extensionUID)
    {
        if (this.instances.has(extensionUID) === true)
        {
            return this.instances.get(extensionUID);
        }

        const extInstance = new ExtensionInstance(extensionUID);
        this.instances.set(extensionUID, extInstance);

        return extInstance;
    }
}
