import React, {useEffect, useState} from "react";
import {Button, Card, Divider, IconButton, Stack, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {dataManager} from "../../Broadtask";
import TaskableCard from "./TaskableCard";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {HexagonTwoTone} from "@mui/icons-material";
import HideShowButton from "../HideShowButton";
import {buttonStyle, sectionStack} from "../../../AppStyles";

const TaskableSelectPane = (props) => {

    const navigate = useNavigate();
    const [taskables, setTaskables] = useState(null);

    const [showPanel, setShowPanel] = useState(true);

    useEffect(() => {
        reload(false);
    }, []);

    function onRefresh()
    {
        reload(true);
    }

    function reload(force)
    {
        dataManager.getTaskableManager().getTaskables(force, (taskables) => {
            setTaskables(taskables);
        });
    }

    function onView()
    {
        navigate("../taskables");
    }

    function onCreate()
    {
        navigate("../taskables/create");
    }

    function onExpand()
    {

    }

    function onTogglePanel()
    {
        setShowPanel(!showPanel);
    }
    function onScrollTaskables()
    {

    }

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%" alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%" justifyContent="space-between" alignItems="left">
                <Stack direction="row" alignItems="left">
                    <img src="/icons/taskable.png" width={58} height={58}/>
                    <Stack direction="column" width="100%" alignItems="flex-start" justifyContent="flex-start">
                        <Typography color="#84898D" variant="subtitle1">TASKABLES</Typography>
                        <Typography color="#495D67" variant="subtitle2" alignItems="left">TASKABLE DEVICES FOR ACCOUNT</Typography>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={1} padding={1} alignItems="center">
                    <Card variant="outlined"
                          sx={{
                              display: 'flex',
                              color: '#f9a555',
                              backgroundColor: '#141c21',
                              '& svg': {
                                  m: 1,
                              },
                              '& hr': {
                                  mx: 0.5,
                              },
                          }}
                    >
                        <Button onClick={onCreate}
                                sx={buttonStyle}
                                startIcon={<AddCircleOutlineIcon />}>CREATE</Button>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Button onClick={onView}
                                sx={buttonStyle}
                                startIcon={<HexagonTwoTone />}>MANAGE TASKABLES</Button>
                    </Card>
                    <HideShowButton onChange={onTogglePanel} />
                </Stack>
            </Stack>
            {
                showPanel === false ?
                    <></> :
                <Stack direction="column" width="100%">
                    <Divider orientation="horizontal" variant="CENTER" width="100%" flexItem>
                        <Typography fontSize="small" sx={{ color: '#808183'}}>DEVICES</Typography>
                    </Divider>
                    <Stack direction="row" width="100%" alignItems="center">
                        <IconButton onClick={onScrollTaskables} title="Scroll Left"
                                    sx={{ backgroundColor: '#2A333A',color:'#f9a555', width: 36, height: 36,transform: 'rotate(270deg)', textAlign: 'top'}} >
                            <img src="/icons/arrow.png" width={16} height={16} />
                        </IconButton>
                        <Stack direction="row" alignItems="center" spacing={1} padding={1} width="100%" minHeight={100}
                               useFlexGap sx={{ flexWrap: "wrap", backgroundColor: '#2A333A'}}>
                            {
                                taskables === null ?
                                    <Typography variant="h6">Loading...</Typography>:
                                    taskables.map(nextTaskable => {
                                        return (<TaskableCard key={"taskable_" + nextTaskable.taskableUID} entry={nextTaskable} />)
                                    })
                            }
                        </Stack>
                        <IconButton onClick={onScrollTaskables} title="Scroll Right"
                                    sx={{ backgroundColor: '#2A333A',color:'#f9a555', width: 36, height: 36,transform: 'rotate(90deg)'}} >
                            <img src="/icons/arrow.png" width={16} height={16} />
                        </IconButton>
                    </Stack>
                    <Divider orientation="horizontal" variant="left" flexItem />
                </Stack>
            }
        </Stack>
    );
}

export default TaskableSelectPane;
