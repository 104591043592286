import React, {useEffect} from "react";
import {Button, Divider, Stack, Typography} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {useNavigate} from "react-router-dom";
import {buttonStyle, sectionPanel, subSectionPanel} from "../../../AppStyles";

const StageTasksPane = (props) => {

    const navigate = useNavigate();

    useEffect(() => {

    }, []);

    function onAdd()
    {
        navigate("../processes/" + props.processUID + "/stages/" + props.stageUID + "/tasks/add");
    }

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%"
               alignItems="flex-start" justifyContent="flex-start"
               sx={sectionPanel}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/task.png" width={32} height={32}/>
                    <Typography variant="h6">Tasks</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Button onClick={onAdd} sx={buttonStyle} startIcon={<AddCircleOutlineIcon />}>Add</Button>
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
            <Stack direction="column" spacing={1} width="100%" minHeight={100}
                   sx={subSectionPanel}>

            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
        </Stack>
    );
}

export default StageTasksPane;
