import {streamAPI} from "../api/BroadtaskAPI";

export default class StreamInstance
{
    constructor(networkUID, streamUID)
    {
        this.networkUID = networkUID;
        this.streamUID = streamUID;
    }

    getSettings(force, callback)
    {
        if (this.settings === true && force === false)
        {
            callback(this.settings);
            return;
        }

        const apiCall = streamAPI.getStreamSettings(this.networkUID, this.streamUID);
        apiCall.then(response => {

            this.settings = response.data;

            callback(this.settings);
        })
            .catch((reason) => {
                console.log("StreamInstance::getSettings::ERROR::" + reason.response.data);
                callback(null);
            })
    }
}
