import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useNavigate} from "react-router-dom";
import * as React from "react";
import {useEffect} from "react";
import AccountSettingsPane from "../../components/account/AccountSettingsPane";
import AccountUsersPane from "../../components/account/AccountUsersPane";
import AccountContactsPane from "../../components/account/AccountContactsPane";
import AccountSubscriptionPane from "../../components/account/AccountSubscriptionPane";
import AccountBillingPane from "../../components/account/AccountBillingPane";
import AccountInvoicesPane from "../../components/account/AccountInvoicesPane";
import {appSession} from "../../Broadtask";
import {sectionPanel, sectionStack, sectionTitle} from "../../../AppStyles";

const AccountSettings = () =>
{
    const navigate = useNavigate();

    useEffect(() => {

    }, []);

    return <Stack spacing={1} padding={1}>
        <Stack direction="row"
               sx={sectionTitle}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Typography>Account</Typography>
            </Breadcrumbs>
        </Stack>

        {
            appSession.activeSession() === false ?
            <Navigate to="/login" />:
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2} padding={1}>

                <AccountSettingsPane />

                {/* Subscription - Billing Row */}
                <Stack direction="row" spacing={1} justifyContent="stretch" alignItems="flex-start">

                    <AccountSubscriptionPane />

                    <AccountBillingPane />

                </Stack>

                <AccountUsersPane />

                <AccountContactsPane />

                <AccountInvoicesPane />

            </Stack>
        }

    </Stack>
};

export default AccountSettings;
