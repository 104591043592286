import React, {useEffect, useState} from "react";
import {Button, Divider, Stack, TextField, Typography} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {appMessaging, dataManager} from "../../Broadtask";
import {useNavigate} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from '@mui/icons-material/Cancel';
import {buttonStyle, sectionStack, subSectionPanel} from "../../../AppStyles";

const AccountBillingPane = (props) => {

    const navigate = useNavigate();

    const [editMode, setEditMode] = useState(false);
    const [chargeType, setChargeType] = useState("");
    const [chargeDOM, setChargeDOM] = useState(1);
    const [CCNumber, setCCNumber] = useState("");
    const [CCExp, setCCExp] = useState("");
    const [CCCode, setCCCode] = useState("");
    const [odcLimit, setODCLimit] = useState(0);

    useEffect(() => {
        dataManager.getAccountManager().getBilling(false, (billing) => {
            setChargeType(billing.chargeType);
            setChargeDOM(billing.chargeDOM);
            setCCNumber(billing.ccNumber);
            setCCExp(billing.ccExp);
            setCCCode(billing.ccCode);
            setODCLimit(billing.odcLimit);
        });
    }, []);

    function onEdit()
    {
        setEditMode(true);
    }

    function onCancel()
    {
        setEditMode(false);
    }

    function onSave()
    {
        const billingInfo = {
            "type": "credit",
            "number": "4400184344325543"
        };

        dataManager.getAccountManager().saveBilling(billingInfo, (status) => {

            if (status === 200)
            {
                appMessaging.showSuccess("Account billing updated", 5000);
                setEditMode(false);
            }
            else
            {
                appMessaging.showError("Error occurred updating account billing", 5000);
            }
        });

    }

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%"
               alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/billing_information.png" width={32} height={32}/>
                    <Typography variant="h6">Billing Information</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                {
                    editMode === false ?
                        <Button onClick={onEdit} sx={buttonStyle} startIcon={<EditIcon />}>Edit</Button> :
                        <Stack direction="row">
                            <Button onClick={onCancel} sx={buttonStyle} startIcon={<CancelIcon />}>Cancel</Button>
                            <Button onClick={onSave} sx={buttonStyle} startIcon={<SaveIcon />}>Save</Button>
                        </Stack>
                }
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
            <Stack direction="column" spacing={2} padding={1} width="100%" minHeight={100}
                   sx={subSectionPanel}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography minWidth={200}>Credit Card Number:</Typography>
                    {
                        editMode === false ?
                            <Typography>{CCNumber}</Typography> :
                            <TextField fullWidth={true} size="small" value={CCNumber}  onChange={(event) => setCCNumber(event.target.value)} />
                    }
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography minWidth={100}>Exp Date:</Typography>
                    {
                        editMode === false ?
                            <Typography>{CCExp}</Typography> :
                            <TextField fullWidth={true} size="small" value={CCExp} onChange={(event) => setCCExp(event.target.value)} />
                    }
                    <Typography minWidth={150}>Security Code:</Typography>
                    {
                        editMode === false ?
                            <Typography>{CCCode}</Typography> :
                            <TextField fullWidth={true} size="small" value={CCCode} onChange={(event) => setCCCode(event.target.value)} />
                    }
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography minWidth={200}>Charging Day of Month:</Typography>
                    {
                        editMode === false ?
                            <Typography>{chargeDOM}</Typography> :
                            <TextField fullWidth={true} size="small" value={chargeDOM}  onChange={(event) => setChargeDOM(event.target.value)} />
                    }
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography minWidth={220}>On Demand Charges (ODC):</Typography>
                    {
                        editMode === false ?
                            <Typography>${odcLimit.toLocaleString(undefined, { maximumFractionDigits: 0})}</Typography> :
                            <TextField fullWidth={true} size="small" value={odcLimit}  onChange={(event) => setODCLimit(event.target.value)} />
                    }
                    <Typography minWidth={170}>per month (maximum)</Typography>
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
        </Stack>
);
}

export default AccountBillingPane;
