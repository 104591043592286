import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    Divider,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Stack,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from '@mui/icons-material/Search';
import {useNavigate} from "react-router-dom";
import {dataManager} from "../../Broadtask";
import NetworkCard from "./NetworkCard";
import {MoreVert, ViewListOutlined} from "@mui/icons-material";
import {
    buttonBarCard,
    buttonStyle,
    sectionStack,
    sectionSubtitle,
    sectionTitle,
    toggleButtonStyle
} from "../../../AppStyles";
import {StyledInputBase, StyledSearch, StyledSearchIconWrapper, StyledToggleButton} from "../../../AppStyledComponents";
import HideShowButton from "../HideShowButton";

const NetworkSelectPane = (props) => {

    const navigate = useNavigate();

    const [networks, setNetworks] = useState(null);
    const [runningStatus, setRunningStatus] = useState("all");
    const [lockedStatus, setLockedStatus] = useState("all");

    const [showPanel, setShowPanel] = useState(true);


    useEffect(() => {
        reload(false);
    }, []);

    function onRefresh()
    {
        reload(true);
    }

    function reload(force)
    {
        dataManager.getNetworkManager().getNetworks(force, (networks) => {
            setNetworks(networks);
        });
    }

    function onView()
    {
        navigate("/networks");
    }

    function onCreate()
    {
        navigate("/networks/create");
    }

    function onTogglePanel()
    {
        setShowPanel(!showPanel);
    }

    function onScrollNetworks()
    {

    }

    function handleRunningStatus(selectedRunningStatus)
    {
        setRunningStatus(selectedRunningStatus);
    }

    function handleLockedStatus(selectedLockedStatus)
    {
        setLockedStatus(selectedLockedStatus);
    }

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%" alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%" justifyContent="space-between" alignItems="left">
                <Stack direction="row" alignItems="left">
                    <img src="/icons/network.png" width={58} height={58}/>
                    <Stack direction="column" width="100%" alignItems="flex-start" justifyContent="flex-start">
                        <Typography sx={sectionTitle} variant="subtitle1">NETWORKS</Typography>
                        <Typography sx={sectionSubtitle} variant="subtitle2" alignItems="left">CONFIGURED NETWORKS FOR THIS ACCOUNT</Typography>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={1} padding={1} alignItems="center">
                    <Card variant="outlined" sx={buttonBarCard}>
                        <Button onClick={onCreate}
                                sx={buttonStyle}
                                startIcon={<AddCircleOutlineIcon />}>CREATE</Button>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Button onClick={onView}
                                sx={buttonStyle}
                                startIcon={<ViewListOutlined />}>MANAGE NETWORKS</Button>
                    </Card>
                    <HideShowButton onChange={onTogglePanel} />
                </Stack>
            </Stack>
            {
                showPanel === false ?
                <></> :
                <Stack direction="column" width="100%">
                    <Divider orientation="horizontal" variant="CENTER" width="100%" flexItem>
                        <Typography fontSize="small" sx={{ color: '#808183'}}>NETWORKS</Typography>
                    </Divider>
                    <Stack direction="row" width="100%" alignItems="center">
                        <IconButton onClick={onScrollNetworks} title="Scroll Left"
                                    sx={{ backgroundColor: '#2A333A',color:'#f9a555', width: 36, height: 36,transform: 'rotate(270deg)'}} >
                            <img src="/icons/arrow.png" width={16} height={16} />
                        </IconButton>
                        <Stack direction="row" alignItems="center" spacing={1} padding={1} width="100%" minHeight={100}
                               useFlexGap sx={{ flexWrap: "wrap", backgroundColor: '#2A333A'}}>
                            {
                                networks === null ?
                                    <Typography variant="h6">Loading...</Typography>:
                                    networks.map(nextNetwork => {
                                        return (<NetworkCard key={"network_" + nextNetwork.networkUID} entry={nextNetwork} />)
                                    })
                            }
                        </Stack>
                        <IconButton onClick={onScrollNetworks} title="Scroll Right"
                                    sx={{ backgroundColor: '#2A333A',color:'#f9a555', width: 36, height: 36,transform: 'rotate(90deg)'}} >
                            <img src="/icons/arrow.png" width={16} height={16} />
                        </IconButton>
                    </Stack>
                    <Divider orientation="horizontal" variant="left" width="100%" flexItem>
                        <div>
                            <FormControl sx={{ m: 1, minWidth: 80}} size="small">
                                <Select sx={{
                                    color: "#495d67",
                                    '& .MuiSvgIcon-root': {
                                        color: "#f9a555",
                                    },
                                    backgroundColor: "#2a333a"}}
                                        value={5}>
                                    <MenuItem value={5}>Display 5</MenuItem>
                                    <MenuItem value={10}>Display 10</MenuItem>
                                    <MenuItem value={15}>Display 15</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Divider>
                    <Stack direction="column" alignItems="left" spacing={1} padding={1} width="100%" minHeight={100}
                           sx={{ backgroundColor: '#495d67', borderRadius: 2}}>
                        <Stack direction="row" alignItems="left" spacing={0} padding={0} width="100%" minHeight={10}>
                            <Typography variant="subtitle1" width="100%" spacing={1} padding={1} sx={{ color: '#f9a555'}}>FILTERS</Typography>
                            <IconButton size="large" spacing={0} padding={0} aria-label="Filter Settings" edge="end" color="inherit">
                                <MoreVert sx={{ color:'#f9a555'}}/>
                            </IconButton>
                        </Stack>
                        <Divider orientation="horizontal" variant="left" width="100%" flexItem />
                        <Stack direction="row" alignItems="flex-start" spacing={5} padding={1} width="100%">
                            <Stack direction="column" line-height="1.0" alignItems="flex-start" justifyContent="flex-start" >
                                <Stack direction="row" alignItems="left" padding={0} width="100%" >
                                    <Typography fontSize="small" sx={{ color: '#f9a555'}}>STATUS TYPE</Typography>
                                </Stack>
                                <ToggleButtonGroup value={runningStatus} exclusive aria-label="text alignment"
                                                   onChange={event => handleRunningStatus(event.target.value)}
                                                   sx={toggleButtonStyle}>
                                    <StyledToggleButton value="all" aria-label="left aligned" >ALL</StyledToggleButton>
                                    <StyledToggleButton value="running" aria-label="center">RUNNING</StyledToggleButton>
                                    <StyledToggleButton value="stopped" aria-label="right aligned">STOPPED</StyledToggleButton>
                                </ToggleButtonGroup>
                            </Stack>
                            <Stack direction="column" alignItems="flex-start" justifyContent="flex-start">
                                <Stack direction="row" alignItems="left" spacing={1} padding={0} width="100%" >
                                    <Typography fontSize="small" sx={{ color: '#f9a555'}}>LOCKED STATUS</Typography>
                                </Stack>
                                <ToggleButtonGroup value={lockedStatus} exclusive aria-label="text alignment"
                                                   onChange={event => handleLockedStatus(event.target.value)}
                                                   sx={toggleButtonStyle}>
                                    <StyledToggleButton value="all" aria-label="left aligned">ALL</StyledToggleButton>
                                    <StyledToggleButton value="locked" aria-label="center">LOCKED</StyledToggleButton>
                                    <StyledToggleButton value="unlocked" aria-label="right aligned">UNLOCKED</StyledToggleButton>
                                </ToggleButtonGroup>
                            </Stack>
                            <Stack direction="column" alignItems="left" justifyContent="flex-start">
                                <Stack direction="row" spacing={1} padding={0} width="100%" >
                                    <Typography fontSize="small" sx={{ color: '#f9a555'}}>FILTER TEXT</Typography>
                                </Stack>
                                <StyledSearch>
                                    <StyledSearchIconWrapper>
                                        <SearchIcon sx={{ color: '#2b333a'}} />
                                    </StyledSearchIconWrapper>
                                    <StyledInputBase placeholder="Filter…" inputProps={{ 'aria-label': 'search' }}/>
                                </StyledSearch>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Divider orientation="horizontal" variant="left" flexItem />
                </Stack>
            }
        </Stack>
);
}

export default NetworkSelectPane;
