import {Breadcrumbs, Button, Divider, Link, Stack, TextField, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useNavigate} from "react-router-dom";
import * as React from "react";
import {useState} from "react";
import {processAPI} from "../../api/BroadtaskAPI";
import {appSession, dataManager} from "../../Broadtask";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CancelIcon from "@mui/icons-material/Cancel";
import {Check} from "@mui/icons-material";
import {buttonStyle, sectionPanel, sectionStack, sectionTitle} from "../../../AppStyles";

const ProcessCreate = () =>
{
    const navigate = useNavigate();

    const [processKey, setKey] = useState("");
    const [processName, setName] = useState("");
    const [fileIcon, setFileIcon] = useState(null);

    function onCancel()
    {
        navigate("/processes/");
    }

    async function onCreate()
    {
        const settings = {
            processKey: processKey,
            processName: processName
        };

        dataManager.getProcessManager().createProcess(settings, (success, response) => {
            if (success === true)
            {
                const processUID = response;

                if (fileIcon)
                {
                    const iconUpdated = processAPI.updateIcon(processUID, fileIcon);
                    console.log(iconUpdated);
                }

                navigate("/processes/" + processUID);
            }
        });

    }

    async function onChangeIcon(fileImage)
    {
        setFileIcon(fileImage);
    }
    return <Stack spacing={1}>
        <Stack direction="row"
               sx={sectionTitle}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/processes"} underline={"hover"}>Processes</Link>
                <Typography>Create Process</Typography>
            </Breadcrumbs>
        </Stack>

        {
            appSession.activeSession() === false ?
            <Navigate to="/login"/> :
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2}
                   padding={1}>

                {/* Create Editor */}
                <Stack direction="column" spacing={1} padding={1} width="100%" alignItems="flex-start" justifyContent="flex-start"
                       sx={sectionStack}>
                    <Stack direction="row" width="100%"
                           justifyContent="space-between"
                           padding={1} spacing={1}>
                        <Typography variant="h6">Create a Process</Typography>
                        <Stack direction="row" spacing={1}>
                            <Button onClick={onCancel} sx={buttonStyle} startIcon={<CancelIcon />}>CANCEL</Button>
                            <Button onClick={onCreate} sx={buttonStyle} startIcon={<Check />}>CREATE</Button>
                        </Stack>
                    </Stack>

                    <Divider orientation="horizontal" variant="left" flexItem />
                    <Stack direction="row" spacing={1} padding={1} width="100%" minHeight={100}
                           sx={sectionPanel}>

                        <Stack direction="column" justifyContent="center">
                            <Stack direction="column" alignItems="center" justifyContent="center" width={150} height={150} sx={{ backgroundColor: '#000000', borderRadius:2, borderColor: '#FFFFFF', borderStyle:'solid' }}>
                                <img src="/icons/process.png" height={128} width={128} />
                            </Stack>
                        </Stack>
                        <Stack direction="column" spacing={1} width="100%">
                            <TextField label="Key" fullWidth={true} size="small" value={processKey} type="text" onChange={(event) => { setKey(event.target.value); }} />
                            <TextField label="Name" fullWidth={true} size="small" value={processName} type="text" onChange={(event) => { setName(event.target.value); }} />
                        </Stack>

                    </Stack>
                    <Divider orientation="horizontal" variant="left" flexItem />
                </Stack>

            </Stack>
        }


    </Stack>
};

export default ProcessCreate;
