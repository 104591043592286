import APICaller from "./APICaller";

export default class DIOSAPI extends APICaller
{
    constructor()
    {
        super();
    }

    getDIOS()
    {
        const servicePath = "dios";

        return this.get(servicePath);
    }

    createDIOS(settings)
    {
        const servicePath = "dios";

        return this.post(servicePath, settings);
    }

    deleteDIOS(diosUID)
    {
        const servicePath = "dios/" + diosUID + "/delete";

        const payload = {
            diosUID: diosUID
        }

        return this.post(servicePath, payload);
    }

    getDIOSSettings(diosUID)
    {
        const servicePath = "dios/" + diosUID;

        return this.get(servicePath);
    }

    saveDIOSSettings(diosUID, settings)
    {
        const servicePath = "dios/" + diosUID;

        return this.post(servicePath, settings);
    }

    getDIOSParameters(diosUID)
    {
        const servicePath = "dios/" + diosUID + "/parameters";

        return this.get(servicePath);
    }

    saveDIOSParameters(diosUID, parameters)
    {
        const servicePath = "dios/" + diosUID + "/parameters";

        return this.post(servicePath, parameters);
    }

    getDIOSFiles(diosUID)
    {
        const servicePath = "dios/" + diosUID + "/files";

        return this.get(servicePath);
    }

    createDIOSFile(diosUID, fileSettings)
    {
        const servicePath = "dios/" + diosUID + "/files";

        return this.post(servicePath, fileSettings);
    }

    deleteDIOSFile(diosUID, fileKey)
    {
        const servicePath = "dios/" + diosUID + "/files/" + fileKey + "/delete";

        const payload = {

        };

        return this.post(servicePath, payload);
    }

    readDIOSFile(diosUID, fileKey)
    {
        const servicePath = "dios/" + diosUID + "/files/" + fileKey;

        return this.get(servicePath);
    }
}
