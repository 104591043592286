import React, {useEffect, useState} from "react";
import {Button, Divider, Stack, Typography} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import {buttonStyle, sectionStack, subSectionPanel} from "../../../AppStyles";

const StageSettingsPane = (props) => {

    const [editMode, setEditMode] = useState(false);

    useEffect(() => {

    }, []);

    function onEdit()
    {
        setEditMode(true);
    }

    function onCancel()
    {
        setEditMode(false);
    }

    function onSave()
    {

    }

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%"
               alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/stage.png" width={32} height={32}/>
                    <Typography variant="h6">Stage Settings</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    {
                        editMode === false ?
                            <Button onClick={onEdit} sx={buttonStyle} startIcon={<EditIcon />}>Edit</Button> :
                            <Stack direction="row">
                                <Button onClick={onCancel} sx={buttonStyle} startIcon={<CancelIcon />}>Cancel</Button>
                                <Button onClick={onSave} sx={buttonStyle} startIcon={<SaveIcon />}>Save</Button>
                            </Stack>
                    }
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
            <Stack direction="column" spacing={1} width="100%" minHeight={100}
                   sx={subSectionPanel}>

            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
        </Stack>
);
}

export default StageSettingsPane;
