import {Stack, Typography} from "@mui/material";
import * as React from "react";
import {useNavigate} from "react-router-dom";

const NetworkProcessCard = (props) =>
{
    const navigate = useNavigate();

    function onView()
    {
        navigate("../networks/" + props.networkUID + "/processes/" + props.entry.processUID);
    }

    return (
        <Stack direction="column" padding={1} spacing={1} alignItems="center" backgroundColor={'#141C21'} onClick={onView} borderRadius={3} sx={{width: 200, height: 200, cursor: "pointer"}}>
            <img src="/icons/process.png" width={64} height={64}/>
            <Stack direction="column" padding={1} alignItems="flex-start">
                <Typography>{props.entry.display}</Typography>
                <Typography variant="caption">{props.entry.processUID}</Typography>
            </Stack>
        </Stack>
    )
}
export default NetworkProcessCard;
