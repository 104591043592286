import {diosAPI} from "../api/BroadtaskAPI";
import DIOSInstance from "./DIOSInstance";
import {appMessaging} from "../Broadtask";

export default class DIOSManager
{
    constructor()
    {
        this.instances = new Map();
    }

    getDIOS(force, callback)
    {
        if (this.master !== undefined && this.master !== null && force === false)
        {
            callback(this.master);
            return;
        }

        const apiCall = diosAPI.getDIOS();
        apiCall.then(response => {
            this.master = response.data;
            callback(this.master);
        })
        .catch((reason) => {
            console.log("DIOSManager::getAll::ERROR::" + reason.response.data);
            callback([]);
        })
    }

    createDIOS(settings, callback)
    {
        const apiCall = diosAPI.createDIOS(settings);
        apiCall.then((response) => {

            //Null out the master to force reload
            this.master = null;

            const diosUID = response.data.diosUID;
            appMessaging.showSuccess(settings.display + ' data source created', 5000);

            callback(true, diosUID);
        })
        .catch((error) => {
            appMessaging.showError('Error creating data source: ' + error, 10000);

            callback(false, error);
        });
    }

    getInstance(diosUID)
    {
        if (this.instances.has(diosUID) === true)
        {
            return this.instances.get(diosUID);
        }

        const instance = new DIOSInstance(diosUID);
        this.instances.set(diosUID, instance);

        return instance;
    }

}
