import {Stack, Typography} from "@mui/material";
import * as React from "react";
import {useNavigate} from "react-router-dom";

const ProcessCard = (props) =>
{
    const navigate = useNavigate();

    function onView()
    {
        navigate("../processes/" + props.entry.processUID);
    }

    function onExpand()
    {

    }
    function onScrollDios()
    {

    }
    return (
        <Stack direction="column" padding={1} spacing={1} alignItems="center" backgroundColor={'#141C21'} onClick={onView} borderRadius={3} sx={{width: 200, height: 200, cursor: "pointer"}}>
            <Stack direction="row" width="100%" alignItems="center" spacing={1}>
                <img src="/icons/process.png" width={32} height={32}/>
                <Typography flexWrap="wrap">{props.entry.display} </Typography>
            </Stack>
            <Stack direction="row">
                <Typography variant="caption" padding={1}>{props.entry.processUID}</Typography>
            </Stack>
            <Stack direction="row">
                <Typography variant="caption" padding={1}>{props.entry.display}</Typography>
            </Stack>
        </Stack>

    )
}
export default ProcessCard;
