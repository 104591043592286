import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import App from './App';
import {Box} from "@mui/material";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <div className={"noPrint"} align={"center"}>
        <Box width="98%" maxWidth={1200}>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </Box>
    </div>
);
