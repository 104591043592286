import {sessionAPI} from "./api/BroadtaskAPI";
import {appEvents, appMessaging} from "./Broadtask";

export default class BTASession
{
    constructor()
    {
        this.username = window.localStorage.getItem("broadtask.username");
        this.loggedIn = false;
    }

    activeSession()
    {
        if (this.loggedIn === true)
        {
            return true;
        }
        return this.loadLast();
    }

    loadLast()
    {
        this.bsak = window.localStorage.getItem("broadtask.sak");
        this.bsat = window.localStorage.getItem("broadtask.sat");
        this.bsat_expires = window.localStorage.getItem("broadtask.sat_expires");

        if (this.bsak !== null && this.bsat !== null && this.bsat_expires !== null)
        {
            this.bsat_start = window.localStorage.getItem("broadtask.sat_start");
            //Make sure the BSAT is not expired
            if (Date.now() < this.bsat_expires)
            {
                this.loggedIn = true;
                return this.loggedIn;
            }
        }

        this.loggedIn = false;

        this.bsak = "";
        this.bsat = "";
        this.bsat_start = 0;
        this.bsat_expires = 0;

        //Clear out any previous values
        window.localStorage.removeItem("broadtask.sak");
        window.localStorage.removeItem("broadtask.sat");
        window.localStorage.removeItem("broadtask.sat_start");
        window.localStorage.removeItem("broadtask.sat_expires");

        return this.loggedIn;
    }

    login(username, password, callback)
    {
        if (this.loggedIn === true)
        {
            callback(this.loggedIn);
            return;
        }

        sessionAPI.start(username, password).then((response) => {
            if (response.status === 200)
            {
                this.loggedIn = true;
                this.username = username;
                //BSAK = BROADTASK Session Authorization Key (SAK) // PUBLIC
                this.bsak = response.data.bsak;
                //BSAT = BROADTASK Session Authorization Token (SAT) // SECRET
                this.bsat = response.data.bsat;
                this.bsat_start = response.data.startedAt;
                this.bsat_expires = response.data.expiresAt;

                window.localStorage.setItem("broadtask.username", this.username);
                window.localStorage.setItem("broadtask.sak", this.bsak);
                window.localStorage.setItem("broadtask.sat", this.bsat);
                window.localStorage.setItem("broadtask.sat_start", this.bsat_start);
                window.localStorage.setItem("broadtask.sat_expires", this.bsat_expires);

                appEvents.dispatch("login", this);
            }
            callback(this.loggedIn);
        }).catch((reason) => {
            appMessaging.showError("Error logging into Broadtask", 60000);
            callback(false);
        });
    }

    logout(callback)
    {
        sessionAPI.end().then(response => {
            console.log("Logged out!")

            this.loggedIn = false;
            this.username = "";
            this.bsak = "";
            this.bsat = "";
            this.bsat_start = 0;
            this.bsat_expires = 0;

            window.localStorage.removeItem("broadtask.sak");
            window.localStorage.removeItem("broadtask.sat");
            window.localStorage.removeItem("broadtask.sat_start");
            window.localStorage.removeItem("broadtask.sat_expires");

            appEvents.dispatch("logout", "");

            callback(this.loggedIn);
        });
    }
}
