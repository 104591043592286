import {Stack} from "@mui/material";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";

const ImageEditor = ({
                        defaultImagePath,
                        imageWidth = 128, imageHeight = 128,
                        onImageChange
                     }) =>
{
    const navigate = useNavigate();

    const fileBrowser = useRef();

    const [imageFile, setImageFile] = useState(null);
    const [imageURL, setImageURL] = useState(null);

    const imageMimeType = /image\/(png|jpg|jpeg)/i;

    useEffect(() => {
        let fileReader, isCancel = false;
        if (imageFile)
        {
            fileReader = new FileReader();
            fileReader.onload = (e) => {
                const { result } = e.target;
                if (result && isCancel === false)
                {
                    setImageURL(result);
                }
            };
            fileReader.readAsDataURL(imageFile);
        }
        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1)
            {
                fileReader.abort();
            }
        }
    }, [imageFile]);

    function onBrowse()
    {
        fileBrowser.current.click();
    }

    function onImageFile(event)
    {
        const file = event.target.files[0];
        if (file.type.match(imageMimeType) === false)
        {
            console.log("Invalid image");
            return;
        }
        setImageFile(file);
        onImageChange(file);
    }

    return (
        <Stack direction="column" padding={1} spacing={0} alignItems="center" style={{ borderRadius:10, borderColor: '#FFFFFF', borderStyle:'solid', borderWidth:0.75 }}>
            <img src={imageURL || defaultImagePath } width={imageWidth} height={imageHeight} onClick={onBrowse} style={{ cursor: "pointer"}} />
            <input ref={fileBrowser} type="file" accept=".png;.jpg;.jpeg" style={{ display: 'none' }} onChange={onImageFile} />
        </Stack>
    )
}
export default ImageEditor;
