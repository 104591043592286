import React, {useEffect} from "react";
import {Button, Divider, Stack, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {dataManager} from "../../Broadtask";
import {RefreshSharp} from "@mui/icons-material";
import {buttonStyle, sectionPanel, subSectionPanel} from "../../../AppStyles";

const AccountInvoicesPane = (props) => {

    const navigate = useNavigate();

    useEffect(() => {
        reload(false);
    }, []);

    function onRefresh()
    {
        reload(true);
    }

    function reload(force)
    {
        dataManager.getAccountManager().getBillingInvoices(force, (invoices) => {

        });
    }

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%"
               alignItems="flex-start" justifyContent="flex-start"
               sx={sectionPanel}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/invoice.png" width={32} height={32}/>
                    <Typography variant="h6">Invoices</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Button onClick={onRefresh} sx={buttonStyle} startIcon={<RefreshSharp />} />
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
            <Stack direction="column" spacing={1} width="100%" minHeight={100}
                   sx={subSectionPanel}>

            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
        </Stack>
);
}

export default AccountInvoicesPane;
