import APICaller from "./APICaller";

export default class UserAPI extends APICaller
{
    constructor()
    {
        super();
    }

    getUserSettings(userUID)
    {
        const servicePath = "users/" + userUID;

        return this.get(servicePath);
    }

    saveUserSettings(userUID, settings)
    {
        const servicePath = "users/" + userUID;

        const payload = {
            "settings": settings
        };

        return this.post(servicePath, payload);
    }

    changePassword(userUID, settings)
    {
        const servicePath = "users/" + userUID + "/password";

        return this.post(servicePath, settings);
    }

    resetPassword(userUID, settings)
    {
        const servicePath = "users/" + userUID + "/reset";

        return this.post(servicePath, settings);
    }
}
