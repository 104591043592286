import {
    Breadcrumbs,
    Button, Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Link,
    Stack,
    Switch,
    Typography
} from "@mui/material";
import {Link as RouterLink, Navigate, useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {useRef} from "react";
import {networkAPI} from "../../../api/BroadtaskAPI";
import {appMessaging, appSession} from "../../../Broadtask";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {Check} from "@mui/icons-material";
import {buttonStyle, mainHeader, sectionStack, subSectionPanel} from "../../../../AppStyles";

const UserAuthPermissions = () =>
{
    const {networkUID, userUID} = useParams();

    const navigate = useNavigate();

    const reqViewPerm = useRef();
    const reqCreatePerm = useRef();
    const reqDeletePerm = useRef();

    const taskableViewPerm = useRef();
    const taskableAddPerm = useRef();
    const taskableRemovePerm= useRef();
    const taskableCmdPerm= useRef();
    const taskableConfigPerm= useRef();

    const processViewPerm= useRef();
    const processEditPerm= useRef();
    const processCreatePerm= useRef();
    const processDeletePerm= useRef();

    const taskViewPerm= useRef();
    const taskEditPerm= useRef();
    const taskCreatePerm= useRef();
    const taskDeletePerm= useRef();

    const dsViewPerm= useRef();
    const dsEditPerm= useRef();
    const dsCreatePerm= useRef();
    const dsDeletePerm= useRef();

    const userAuthViewPerm= useRef();
    const userAuthEditPerm= useRef();
    const userAuthCreatePerm= useRef();
    const userAuthDeletePerm= useRef();

    function onCancel()
    {
        navigate("/networks/" + networkUID + "/users/");
    }

    async function onSave()
    {
        const permissions = {
            requestView: reqViewPerm.current.checked,
            requestCreate: reqCreatePerm.current.checked,
            requestDelete: reqDeletePerm.current.checked,
            taskableView: taskableViewPerm.current.checked,
            taskableAdd: taskableAddPerm.current.checked,
            taskableRemove: taskableRemovePerm.current.checked,
            taskableCommand: taskableCmdPerm.current.checked,
            taskableConfig: taskableConfigPerm.current.checked,
            processView: processViewPerm.current.checked,
            processEdit: processEditPerm.current.checked,
            processCreate: processCreatePerm.current.checked,
            processDelete: processDeletePerm.current.checked,
            taskView: taskViewPerm.current.checked,
            taskEdit: taskEditPerm.current.checked,
            taskCreate: taskCreatePerm.current.checked,
            taskDelete: taskDeletePerm.current.checked,
            dataSourceView: dsViewPerm.current.checked,
            dataSourceEdit: dsEditPerm.current.checked,
            dataSourceCreate: dsCreatePerm.current.checked,
            dataSourceDelete: dsDeletePerm.current.checked,
            userAuthView: userAuthViewPerm.current.checked,
            userAuthEdit: userAuthEditPerm.current.checked,
            userAuthCreate: userAuthCreatePerm.current.checked,
            userAuthDelete: userAuthDeletePerm.current.checked,
        };

        const response = await networkAPI.saveUserPermission(networkUID, userUID, permissions);
        if (response.status && response.status === 200)
        {
            //TODO: Read the data field to get user session details
            console.log(response.data);

            appMessaging.showSuccess('User Permissions Assigned', 5000);

            navigate("/networks/" + networkUID + "/users/");
        }
        else
        {
            appMessaging.showError('Unable to assign user permissions\n' + response, 10000);
        }
    }

    return <Stack spacing={1}>
        <Stack direction="row"
               sx={mainHeader}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/networks"} underline={"hover"}>Networks</Link>
                <Link component={RouterLink} to={"/networks/" + networkUID} underline={"hover"}>{networkUID}</Link>
                <Link component={RouterLink} to={"/networks/" + networkUID + "/users"} underline={"hover"}>User Authorizations</Link>
                <Typography>{userUID}</Typography>
            </Breadcrumbs>
        </Stack>
        {
            appSession.activeSession() === false ?
            <Navigate to="/login"/> :
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2}
                   padding={1}>

                {/* Permissions */}
                <Stack direction="column" spacing={1} padding={1} width="100%" alignItems="flex-start" justifyContent="flex-start"
                       sx={sectionStack}>
                    <Stack direction="row" width="100%"
                           justifyContent="space-between"
                           padding={1} spacing={1}>
                        <Typography variant="h6">Permissions</Typography>
                        <Stack direction="row" spacing={1}>
                            <Button onClick={onCancel} sx={buttonStyle} startIcon={<CancelIcon />}>CANCEL</Button>
                            <Button onClick={onSave} sx={buttonStyle} startIcon={<Check />}>SAVE</Button>
                        </Stack>
                    </Stack>
                    <Divider orientation="horizontal" variant="left" flexItem />
                    <Stack direction="column" spacing={1} padding={2}
                           width="100%" minHeight={100} alignItems="flex-start"
                           sx={subSectionPanel}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" >Requests</FormLabel>
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    value="top"
                                    control={<Switch inputRef={reqViewPerm} />}
                                    label="View"
                                    labelPlacement="left"
                                />
                                <FormControlLabel
                                    value="top"
                                    control={<Switch inputRef={reqCreatePerm} />}
                                    label="Create"
                                    labelPlacement="left"
                                />
                                <FormControlLabel
                                    value="top"
                                    control={<Switch inputRef={reqDeletePerm} />}
                                    label="Delete"
                                    labelPlacement="left"
                                />
                            </FormGroup>
                            <FormLabel component="legend">Taskables</FormLabel>
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    value="top"
                                    control={<Switch inputRef={taskableViewPerm} />}
                                    label="View"
                                    labelPlacement="left"
                                />
                                <FormControlLabel
                                    value="top"
                                    control={<Switch inputRef={taskableAddPerm} />}
                                    label="Add"
                                    labelPlacement="left"
                                />
                                <FormControlLabel
                                    value="top"
                                    control={<Switch inputRef={taskableRemovePerm} />}
                                    label="Remove"
                                    labelPlacement="left"
                                />
                                <FormControlLabel
                                    value="top"
                                    control={<Switch inputRef={taskableCmdPerm} />}
                                    label="Command"
                                    labelPlacement="left"
                                />
                                <FormControlLabel
                                    value="top"
                                    control={<Switch inputRef={taskableConfigPerm} />}
                                    label="Configure"
                                    labelPlacement="left"
                                />
                            </FormGroup>
                            <FormLabel component="legend">Processes</FormLabel>
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    value="top"
                                    control={<Switch inputRef={processViewPerm} />}
                                    label="View"
                                    labelPlacement="left"
                                />
                                <FormControlLabel
                                    value="top"
                                    control={<Switch inputRef={processEditPerm} />}
                                    label="Edit"
                                    labelPlacement="left"
                                />
                                <FormControlLabel
                                    value="top"
                                    control={<Switch inputRef={processCreatePerm} />}
                                    label="Create"
                                    labelPlacement="left"
                                />
                                <FormControlLabel
                                    value="top"
                                    control={<Switch inputRef={processDeletePerm} />}
                                    label="Delete"
                                    labelPlacement="left"
                                />
                            </FormGroup>
                            <FormLabel component="legend">Tasks</FormLabel>
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    value="top"
                                    control={<Switch inputRef={taskViewPerm} />}
                                    label="View"
                                    labelPlacement="left"
                                />
                                <FormControlLabel
                                    value="top"
                                    control={<Switch inputRef={taskEditPerm} />}
                                    label="Edit"
                                    labelPlacement="left"
                                />
                                <FormControlLabel
                                    value="top"
                                    control={<Switch inputRef={taskCreatePerm} />}
                                    label="Create"
                                    labelPlacement="left"
                                />
                                <FormControlLabel
                                    value="top"
                                    control={<Switch inputRef={taskDeletePerm} />}
                                    label="Delete"
                                    labelPlacement="left"
                                />
                            </FormGroup>
                            <FormLabel component="legend">Data Sources</FormLabel>
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    value="top"
                                    control={<Switch inputRef={dsViewPerm} />}
                                    label="View"
                                    labelPlacement="left"
                                />
                                <FormControlLabel
                                    value="top"
                                    control={<Switch inputRef={dsEditPerm} />}
                                    label="Edit"
                                    labelPlacement="left"
                                />
                                <FormControlLabel
                                    value="top"
                                    control={<Switch inputRef={dsCreatePerm} />}
                                    label="Create"
                                    labelPlacement="left"
                                />
                                <FormControlLabel
                                    value="top"
                                    control={<Switch inputRef={dsDeletePerm} />}
                                    label="Delete"
                                    labelPlacement="left"
                                />
                            </FormGroup>
                            <FormLabel component="legend">User Authorizations</FormLabel>
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    value="top"
                                    control={<Switch inputRef={userAuthViewPerm} />}
                                    label="View"
                                    labelPlacement="left"
                                />
                                <FormControlLabel
                                    value="top"
                                    control={<Switch inputRef={userAuthEditPerm} />}
                                    label="Edit"
                                    labelPlacement="left"
                                />
                                <FormControlLabel
                                    value="top"
                                    control={<Switch inputRef={userAuthCreatePerm} />}
                                    label="Create"
                                    labelPlacement="left"
                                />
                                <FormControlLabel
                                    value="top"
                                    control={<Switch inputRef={userAuthDeletePerm} />}
                                    label="Delete"
                                    labelPlacement="left"
                                />
                            </FormGroup>
                        </FormControl>

                    </Stack>
                    <Divider orientation="horizontal" variant="left" flexItem />
                </Stack>

            </Stack>
        }

    </Stack>
};

export default UserAuthPermissions;
