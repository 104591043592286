import {taskableAPI, utilizationAPI} from "../api/BroadtaskAPI";

export default class TaskableInstance
{
    constructor(taskableUID)
    {
        this.taskableUID = taskableUID;

        this.utilization = new Map();
    }

    getApplications(force, callback)
    {
        if (this.applications !== undefined && this.applications !== null && force === false)
        {
            callback(this.applications);
            return;
        }

        const apiCall = taskableAPI.getApplications(this.taskableUID);
        apiCall.then(response => {
            if (response.data !== undefined)
            {
                this.applications = response.data;
                callback(this.applications);
            }
            else
            {
                callback([]);
            }
        })
        .catch((reason) => {
            console.log("TaskableInstance::" + this.taskableUID + "::getApplications::ERROR::" + reason.response.data);
            callback([]);
        })
    }

    getDeploymentProfile(force, callback)
    {
        if (this.deploymentProfile !== undefined && this.deploymentProfile !== null && force === false)
        {
            callback(this.deploymentProfile);
            return;
        }

        const apiCall = taskableAPI.getDeploymentProfile(this.taskableUID);
        apiCall.then(response => {
            if (response.data !== undefined && response.data !== "")
            {
                this.deploymentProfile = response.data;
                callback(this.deploymentProfile);
            }
            else
            {
                callback(null);
            }
        })
        .catch((reason) => {
            console.log("TaskableInstance::" + this.taskableUID + "::getDeploymentProfile::ERROR::" + reason.response.data);
            callback(null);
        })
    }

    getDeviceProfile(force, callback)
    {
        if (this.deviceProfile !== undefined && this.deviceProfile !== null && force === false)
        {
            callback(this.deviceProfile);
            return;
        }

        const apiCall = taskableAPI.getDeviceProfile(this.taskableUID);
        apiCall.then(response => {
            if (response.data !== undefined && response.data !== "") {
                this.deviceProfile = response.data;
                callback(this.deviceProfile);
            }
            else
            {
                callback(null);
            }
        })
        .catch((reason) => {
            console.log("TaskableInstance::" + this.taskableUID + "::getDeviceProfile::ERROR::" + reason.response.data);
            callback(null);
        })
    }

    getHistory(force, callback)
    {
        if (this.history !== undefined && this.history !== null && force === false)
        {
            callback(this.history);
            return;
        }

        const apiCall = taskableAPI.getHistory(this.taskableUID);
        apiCall.then(response => {
            if (response.data !== undefined && response.data !== "") {
                this.history = response.data;
                callback(this.history);
            }
            else
            {
                callback(null);
            }
        })
        .catch((reason) => {
            console.log("TaskableInstance::" + this.taskableUID + "::getHistory::ERROR::" + reason.response.data);
            callback(null);
        })
    }

    getNetworks(force, callback)
    {
        if (this.networks !== undefined && this.networks !== null && force === false)
        {
            callback(this.networks);
            return;
        }

        const apiCall = taskableAPI.getNetworks(this.taskableUID);
        apiCall.then(response => {
            if (response.data !== undefined) {
                this.networks = response.data;
                callback(this.networks);
            }
            else
            {
                callback([]);
            }
        })
        .catch((reason) => {
            console.log("TaskableInstance::" + this.taskableUID + "::getNetworks::ERROR::" + reason.response.data);
            callback([]);
        })
    }

    getSettings(force, callback)
    {
        if (this.settings !== undefined && this.settings !== null && force === false)
        {
            callback(this.settings);
            return;
        }

        const apiCall = taskableAPI.getTaskableSettings(this.taskableUID);
        apiCall.then(response => {
            if (response.data !== undefined && response.data !== "") {
                this.settings = response.data;
                callback(this.settings);
            }
            else {
                callback(null);
            }
        })
        .catch((reason) => {
            console.log("TaskableInstance::" + this.taskableUID + "::getSettings::ERROR::" + reason.response.data);
            callback(null);
        })
    }

    getRuntimes(force, callback)
    {
        if (this.runtimes !== undefined && this.runtimes !== null && force === false)
        {
            callback(this.runtimes);
            return;
        }

        const apiCall = taskableAPI.getRuntimes(this.taskableUID);
        apiCall.then(response => {
            if (response.data !== undefined)
            {
                this.runtimes = response.data;
                callback(this.runtimes);
            }
            else
            {
                callback([]);
            }
        })
        .catch((reason) => {
            console.log("TaskableInstance::" + this.taskableUID + "::getRuntimes::ERROR::" + reason.response.data);
            callback([]);
        })
    }

    getUtilization(periodKey, force, callback)
    {
        if (this.utilization.has(periodKey) === true && force === false)
        {
            callback(this.utilization.get(periodKey));
            return;
        }

        const apiCall = utilizationAPI.getTaskableUtilization(this.taskableUID, periodKey);
        apiCall.then(response => {
            if (response.data !== undefined) {
                this.utilization.set(periodKey, response.data);
                callback(this.utilization.get(periodKey));
            }
            else {
                callback([]);
            }
        })
        .catch((reason) => {
            console.log("TaskableInstance::" + this.taskableUID + "::getUtilization::ERROR::" + reason.response.data);
            callback([]);
        })
    }
}
