import {Link, Stack, Typography} from "@mui/material";

const AppFooter = () => {

    return (
        <Stack direction="row" alignItems="inherit"
               sx={{ backgroundColor: "#141C21" }}
               padding={1} paddingTop={2} spacing={1} marginTop={2} marginBottom={2}
               justifyContent="space-between" useFlexGap flexWrap="wrap">

            <Typography paddingBottom={2} variant="subtitle2">Copyright &copy; 2024, All Rights Reserved</Typography>

            <Stack direction="row" paddingTop={0} spacing={2} sx={{flexDirection: { xs: "column", md: "row"}}}>
                <Link href="https://www.broadtask.net/privacy" target="_blank">Privacy Policy</Link>
                <Link href="https://www.broadtask.net/cookies" target="_blank">Cookie Policy</Link>
                <Link href="https://www.broadtask.net/terms" target="_blank">Terms of Use</Link>
            </Stack>
        </Stack>
    )
}

export default AppFooter;
