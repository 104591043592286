import APICaller from "./APICaller";

export default class AccountAPI extends APICaller
{
    constructor()
    {
        super();
    }

    getDetails()
    {
        const servicePath = "account";

        return this.get(servicePath);
    }

    saveDetails(accountUID, details)
    {
        const servicePath = "account";

        const payload = {
            "details": details
        };

        return this.post(servicePath, payload);
    }

    getBilling()
    {
        const servicePath = "account/billing";

        return this.get(servicePath);
    }

    saveBilling(settings)
    {
        const servicePath = "account/billing";

        return this.post(servicePath, settings);
    }

    getBillingInvoices()
    {
        const servicePath = "account/billing/invoices";

        return this.get(servicePath);
    }

    getContacts()
    {
        const servicePath = "account/contacts";

        return this.get(servicePath);
    }

    createContact(contactSettings)
    {
        const servicePath = "account/contacts";

        const payload = {
            "settings": contactSettings
        };

        return this.post(servicePath, payload);
    }

    deleteContact(contactUID)
    {
        const servicePath = "account/contacts/" + contactUID + "/delete";

        const payload = {
            "contactUID": contactUID
        };

        return this.post(servicePath, payload);
    }

    getContact(contactUID)
    {
        const servicePath = "account/contacts/" + contactUID;

        return this.get(servicePath);
    }

    saveContact(contactUID, contactSettings)
    {
        const servicePath = "account/contacts/" + contactUID;

        const payload = {
            "contactUID": contactUID,
            "settings": contactSettings
        };

        return this.post(servicePath, payload);
    }

    getSubscription()
    {
        const servicePath = "account/subscription";

        return this.get(servicePath);
    }

    saveSubscription(subscriptionSettings)
    {
        const servicePath = "account/subscription";

        const payload = {
            "settings": subscriptionSettings
        };

        return this.post(servicePath, payload);
    }

    getUserAuthorizations()
    {
        const servicePath = "account/users";

        return this.get(servicePath);
    }

    createUserAuthorization(userAuthSettings)
    {
        const servicePath = "account/users";

        const payload = {
            "settings": userAuthSettings
        };

        return this.post(servicePath, payload);
    }

    deleteUserAuthorization(userUID)
    {
        const servicePath = "account/users/" + userUID + "/delete";

        const payload = {
            "userUID": userUID
        };

        return this.post(servicePath, payload);
    }

    getUserAuthorization(userUID)
    {
        const servicePath = "account/users/" + userUID;

        return this.get(servicePath);
    }

    saveUserAuthorization(userUID, userAuthSettings)
    {
        const servicePath = "account/users/" + userUID;

        const payload = {
            "userUID": userUID,
            "settings": userAuthSettings
        };

        return this.post(servicePath, payload);
    }
}
