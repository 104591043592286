import React, {useEffect} from "react";
import {Divider, Stack, Typography} from "@mui/material";
import {dataManager} from "../../Broadtask";
import {useNavigate} from "react-router-dom";
import {sectionStack, subSectionPanel} from "../../../AppStyles";

const TaskableNetworksPane = (props) => {

    const navigate = useNavigate();

    useEffect(() => {
        dataManager.getTaskableManager().getInstance(props.taskableUID).getNetworks(false, (networks) => {

        });
    }, [props.taskableUID]);

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%"
               alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/network_taskable_assignment.png" width={32} height={32}/>
                    <Typography variant="h6">Networks</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>

                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
            <Stack direction="column" spacing={1} width="100%" minHeight={100}
                   sx={subSectionPanel}>

            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
        </Stack>
    );
}

export default TaskableNetworksPane;
