import {processAPI} from "../api/BroadtaskAPI";
import {appMessaging} from "../Broadtask";
import StageInstance from "./StageInstance";

export default class ProcessInstance
{
    constructor(processUID)
    {
        this.processUID = processUID;

        this.stageInstances = new Map();
    }

    getSettings(force, callback)
    {
        if (this.settings === true && force === false)
        {
            callback(this.settings);
            return;
        }

        const apiCall = processAPI.getProcessSettings(this.processUID);
        apiCall.then(response => {

            this.settings = response.data;

            callback(this.settings);
        })
        .catch((reason) => {
            console.log("ProcessInstance::getSettings::ERROR::" + reason.response.data);
            callback(null);
        })
    }

    getStages(force, callback)
    {
        if (this.stages === true && force === false)
        {
            callback(this.stages);
            return;
        }

        const apiCall = processAPI.getStages(this.processUID);
        apiCall.then(response => {

            this.stages = response.data;

            callback(this.stages);
        })
            .catch((reason) => {
                console.log("ProcessInstance::" + this.processUID + "::getStages::ERROR::" + reason.response.data);
                callback([]);
            })
    }

    createStage(settings, callback)
    {
        const apiCall = processAPI.createStage(this.processUID, settings);
        apiCall.then((response) => {

            //Null out the stages to force reload
            this.stages = null;

            const stageUID = response.data.stageUID;
            appMessaging.showSuccess(settings.display + ' stage created', 5000);

            callback(true, stageUID);
        })
            .catch((error) => {
                appMessaging.showError('Error creating stage: ' + error, 10000);

                callback(false, error);
            });
    }

    getStageInstance(stageUID)
    {
        if (this.stageInstances.has(stageUID) === true)
        {
            return this.stageInstances.get(stageUID);
        }

        const instance = new StageInstance(this.processUID, stageUID);
        this.stageInstances.set(stageUID, instance);

        return instance;
    }
}
