import APICaller from "./APICaller";

export default class NetworkTaskableAPI extends APICaller
{
    constructor()
    {
        super();
    }

    getTaskables(networkUID)
    {
        const servicePath = "networks/" + networkUID + "/taskables";

        return this.get(servicePath);
    }

    createTaskable(networkUID, settings)
    {
        const servicePath = "networks/" + networkUID + "/taskables";

        return this.post(servicePath, settings);
    }

    deleteTaskable(networkUID, taskableUID)
    {
        const servicePath = "networks/" + networkUID + "/taskables/" + taskableUID + "/delete";

        const payload = {

        };

        return this.post(servicePath, payload);
    }

    getTaskableSettings(networkUID, taskableUID)
    {
        const servicePath = "networks/" + networkUID + "/taskables/" + taskableUID;

        return this.get(servicePath);
    }

    saveTaskableSettings(networkUID, taskableUID, settings) {
        const servicePath = "networks/" + networkUID + "/taskables/" + taskableUID;

        return this.post(servicePath, settings);
    }

    getCommands(networkUID, taskableUID)
    {
        const servicePath = "networks/" + networkUID + "/taskables/" + taskableUID + "/commands";

        return this.get(servicePath);
    }

    executeCommand(networkUID, taskableUID, commandUID, params)
    {
        const servicePath = "networks/" + networkUID + "/taskables/" + taskableUID + "/commands/" + commandUID + "/execute";

        return this.post(servicePath, params);
    }

    getCommandSettings(networkUID, taskableUID, commandUID)
    {
        const servicePath = "networks/" + networkUID + "/taskables/" + taskableUID + "/commands/" + commandUID;

        return this.get(servicePath);
    }

    saveCommandSettings(networkUID, taskableUID, commandUID, settings)
    {
        const servicePath = "networks/" + networkUID + "/taskables/" + taskableUID + "/commands/" + commandUID;

        return this.post(servicePath, settings);
    }

    getExtensions(networkUID, taskableUID)
    {
        const servicePath = "networks/" + networkUID + "/taskables/" + taskableUID + "/extensions";

        return this.get(servicePath);
    }

    getExtensionSettings(networkUID, taskableUID, extensionUID)
    {
        const servicePath = "networks/" + networkUID + "/taskables/" + taskableUID + "/extensions/" + extensionUID;

        return this.get(servicePath);
    }

    installExtension(networkUID, taskableUID, extensionUID, params)
    {
        const servicePath = "networks/" + networkUID + "/taskables/" + taskableUID + "/extensions/" + extensionUID + "/install";

        return this.post(servicePath, params);
    }

    uninstallExtension(networkUID, taskableUID, extensionUID, params)
    {
        const servicePath = "networks/" + networkUID + "/taskables/" + taskableUID + "/extensions/" + extensionUID + "/uninstall";

        return this.post(servicePath, params);
    }

    getProcessors(networkUID, taskableUID)
    {
        const servicePath = "networks/" + networkUID + "/taskables/" + taskableUID + "/processors";

        return this.get(servicePath);
    }

    executeProcessor(networkUID, taskableUID, processorUID, params)
    {
        const servicePath = "networks/" + networkUID + "/taskables/" + taskableUID + "/processors/" + processorUID + "/execute";

        return this.post(servicePath, params);
    }

    getProcessorSettings(networkUID, taskableUID, processorUID)
    {
        const servicePath = "networks/" + networkUID + "/taskables/" + taskableUID + "/processors/" + processorUID;

        return this.get(servicePath);
    }

    saveProcessorSettings(networkUID, taskableUID, processorUID, settings)
    {
        const servicePath = "networks/" + networkUID + "/taskables/" + taskableUID + "/processors/" + processorUID;

        return this.post(servicePath, settings);
    }

    getServices(networkUID, taskableUID)
    {
        const servicePath = "networks/" + networkUID + "/taskables/" + taskableUID + "/services";

        return this.get(servicePath);
    }

    executeService(networkUID, taskableUID, serviceUID, params)
    {
        const servicePath = "networks/" + networkUID + "/taskables/" + taskableUID + "/services/" + serviceUID + "/execute";

        return this.post(servicePath, params);
    }

    getServiceSettings(networkUID, taskableUID, serviceUID)
    {
        const servicePath = "networks/" + networkUID + "/taskables/" + taskableUID + "/services/" + serviceUID;

        return this.get(servicePath);
    }

    saveServiceSettings(networkUID, taskableUID, serviceUID, settings)
    {
        const servicePath = "networks/" + networkUID + "/taskables/" + taskableUID + "/services/" + serviceUID;

        return this.post(servicePath, settings);
    }
}
