import {sha256} from "js-sha256";

export function createRandomAlphaNum(totalLen)
{
    const charSet = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    let output = "";

    for (let p = 0; p < totalLen; p++)
    {
        output = output + charSet.charAt(Math.floor(Math.random() * charSet.length));
    }

    return output;
};

export function hash256Hex(content)
{
    const hasher = sha256.create();
    hasher.update(content);
    return hasher.hex();
}
