import {channelAPI} from "../api/BroadtaskAPI";

export default class ChannelInstance
{
    constructor(networkUID, channelUID)
    {
        this.networkUID = networkUID;
        this.channelUID = channelUID;
    }

    getSettings(force, callback)
    {
        if (this.settings === true && force === false)
        {
            callback(this.settings);
            return;
        }

        const apiCall = channelAPI.getChannelSettings(this.networkUID, this.channelUID);
        apiCall.then(response => {

            this.settings = response.data;

            callback(this.settings);
        })
        .catch((reason) => {
            console.log("ChannelInstance::getSettings::ERROR::" + reason.response.data);
            callback(null);
        })
    }
}
