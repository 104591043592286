import {
    Alert,
    Avatar,
    Badge,
    Button, Divider,
    IconButton,
    Link, ListItemIcon,
    Menu, MenuItem, menuItemClasses,
    Snackbar,
    Stack, styled,
    Tooltip,
    Typography
} from "@mui/material";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import * as React from "react";
import {useEffect, useState} from "react";
import CloseIcon from '@mui/icons-material/Close';
import {appEvents, appMessaging, appSession} from "../Broadtask";
import SettingsIcon from '@mui/icons-material/Settings';
import {Logout, ManageAccounts, NotificationImportant} from "@mui/icons-material";
import {blue, blueGrey} from "@mui/material/colors";
import { Dropdown } from '@mui/base/Dropdown';
import { MenuButton } from '@mui/base/MenuButton';

const AppHeader = (props) =>
{
    const [messageShowing, setMessageShowing] = useState(false);
    const [message, setMessage] = useState("");
    const [messageSeverity, setMessageSeverity] = useState("info");
    const [messageDuration, setMessageDuration] = useState(5000);

    const navigate = useNavigate();

    useEffect(() => {
        appMessaging.setMessageRenderer(showMessage);
        appEvents.register("login", onLogin);
    }, []);

    function onLogin(topic, content)
    {

    }

    function onViewAccount()
    {
        navigate("/account");
    }
    function onViewUser()
    {
        navigate("/user");
    }

    function onLogout()
    {
        appSession.logout(response => {
            navigate("/login");
        })
    }


    function showMessage(severity, message, duration)
    {
        setMessage(message);
        setMessageSeverity(severity);
        setMessageDuration(duration);
        setMessageShowing(true);
    }

    const onMessageClose = (event, reason) =>
    {
        setMessageShowing(false);
    }

    const actionFragment = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={onMessageClose}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={onMessageClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );


    return (
    <Stack direction="row"
           sx={{ backgroundColor: "#141C21" }}
           padding={1}
           justifyContent="space-between" justifyItems="center">
        <Stack direction="row" spacing={1} justifyContent="space-between" width="100%">
            <Stack direction="row" spacing={1} alignItems="center">
                <Link component={RouterLink} to="/" underline={"hover"}><img className={"header-image"} height={32}/></Link>
                <Typography variant={"h4"} sx={{ fontFamily:"FiraSans-Regular", fontWeight: 300 }}>BROADTASK</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2} padding={1}>
                <Badge badgeContent={2} aria-label="Notifications" sx={{
                    "& .MuiBadge-badge": {
                        color: "white",
                        backgroundColor: "#ef4f24"
                    },
                    cursor: "pointer"
                }}>
                    <NotificationImportant ></NotificationImportant>
                </Badge>
                <IconButton onClick={onViewAccount} aria-label="Account Settings" title="Account Settings" sx={{ cursor: "pointer"}}>
                    <SettingsIcon />
                </IconButton>
                <IconButton onClick={onViewUser}  aria-label="User Settings" title="User Settings" sx={{ cursor: "pointer"}}>
                    <ManageAccounts />
                </IconButton>
                <IconButton onClick={onLogout}  aria-label="Logout" title="Logout" sx={{ cursor: "pointer"}}>
                    <Logout />
                </IconButton>
            </Stack>
        </Stack>
        <Snackbar
            anchorOrigin={{horizontal: "center", vertical: "top"}}
            open={messageShowing}
            autoHideDuration={messageDuration}
            onClose={onMessageClose}
            message="Hello!"
            action={actionFragment}>
            <Alert
                sx={{ whiteSpace: 'pre-line' }}
                onClose={onMessageClose}
                severity={messageSeverity}>{message}</Alert>
        </Snackbar>
    </Stack>
    )
};

export default AppHeader;
