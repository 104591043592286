import NetworkAPI from "./NetworkAPI";
import UserAPI from "./UserAPI";
import SessionAPI from "./SessionAPI";
import AccountAPI from "./AccountAPI";
import DIOSAPI from "./DIOSAPI";
import ExtensionsAPI from "./ExtensionsAPI";
import ProcessAPI from "./ProcessAPI";
import TaskableAPI from "./TaskableAPI";
import ChannelAPI from "./ChannelAPI";
import RequestAPI from "./RequestAPI";
import StreamAPI from "./StreamAPI";
import NetworkAuthAPI from "./NetworkAuthAPI";
import NetworkTaskableAPI from "./NetworkTaskableAPI";
import UtilizationAPI from "./UtilizationAPI";
import NetworkExtensionAPI from "./NetworkExtensionAPI";

export const accountAPI = new AccountAPI();
export const channelAPI = new ChannelAPI();
export const diosAPI = new DIOSAPI();
export const extensionsAPI = new ExtensionsAPI();
export const networkAPI = new NetworkAPI();
export const networkAuthAPI = new NetworkAuthAPI();
export const networkExtensionAPI = new NetworkExtensionAPI();
export const networkTaskableAPI = new NetworkTaskableAPI();
export const processAPI = new ProcessAPI();
export const requestAPI = new RequestAPI();
export const sessionAPI = new SessionAPI();
export const streamAPI = new StreamAPI();
export const taskableAPI = new TaskableAPI();
export const userAPI = new UserAPI();
export const utilizationAPI = new UtilizationAPI();
