import React, {useEffect} from "react";
import {Button, Divider, Stack, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {dataManager} from "../../Broadtask";
import GridViewIcon from "@mui/icons-material/GridView";
import {ViewList} from "@mui/icons-material";
import {buttonStyle, sectionStack} from "../../../AppStyles";

const NetworkDeploymentPane = (props) => {

    const navigate = useNavigate();

    useEffect(() => {
        reload(false);
    }, [props.networkUID]);

    function onRefresh()
    {
        reload(true);
    }

    function reload(force)
    {
        dataManager.getNetworkManager().getInstance(props.networkUID).getTaskables(force, (taskables) => {

        });
    }

    function onView()
    {
        navigate("/networks/" + props.networkUID + "/deployments");
    }

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%"
               alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Typography variant="h6">Deployments</Typography>
                <Stack direction="row" spacing={1}>
                    <Button onClick={onView}
                            sx={buttonStyle}
                            startIcon={<GridViewIcon />}>VIEW ALL</Button>
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
            <Stack direction="column" spacing={1}
                   sx={{ backgroundColor: '#141c21'}}>
                <img src="/images/basemap_preview.png" width={360} height={180}/>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
        </Stack>
);
}

export default NetworkDeploymentPane;
