import APICaller from "./APICaller";

export default class NetworkAuthAPI extends APICaller
{
    constructor()
    {
        super();
    }

    getAuthorizations(networkUID)
    {
        const servicePath = "networks/" + networkUID + "/auth";

        return this.get(servicePath);
    }

    createAuthorizations(networkUID, authSettings)
    {
        const servicePath = "networks/" + networkUID + "/auth";

        return this.post(servicePath, authSettings);
    }

    getAuthorization(networkUID, authUID)
    {
        const servicePath = "networks/" + networkUID + "/auth/" + authUID;

        return this.get(servicePath);
    }

    saveAuthorization(networkUID, authUID, authSettings)
    {
        const servicePath = "networks/" + networkUID + "/auth/" + authUID;

        return this.post(servicePath, authSettings);
    }

    deleteAuthorization(networkUID, authUID)
    {
        const servicePath = "networks/" + networkUID + "/auth/" + authUID + "/delete";

        const payload = {

        };

        return this.post(servicePath, payload);
    }
}
