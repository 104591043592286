import APICaller from "./APICaller";

export default class NetworkExtensionAPI extends APICaller
{
    constructor()
    {
        super();
    }

    getExtensions(networkUID)
    {
        const servicePath = "networks/" + networkUID + "/extensions";

        return this.get(servicePath);
    }

    getExtension(networkUID, extensionUID)
    {
        const servicePath = "networks/" + networkUID + "/extensions/" + extensionUID;

        return this.get(servicePath);
    }
}
