import APICaller from "./APICaller";

export default class TaskableAPI extends APICaller
{
    constructor()
    {
        super();
    }

    getTaskables()
    {
        const servicePath = "taskables";

        return this.get(servicePath);
    }

    createTaskable(settings)
    {
        const servicePath = "taskables";

        return this.post(servicePath, settings);
    }

    deleteTaskable(taskableUID)
    {
        const servicePath = "taskables/" + taskableUID;

        const payload = {

        };

        return this.post(servicePath, payload);
    }

    getTaskableSettings(taskableUID)
    {
        const servicePath = "taskables/" + taskableUID;

        return this.get(servicePath);
    }

    getApplications(taskableUID)
    {
        const servicePath = "taskables/" + taskableUID + "/applications";

        return this.get(servicePath);
    }

    getDeploymentProfile(taskableUID)
    {
        const servicePath = "taskables/" + taskableUID + "/deployment";

        return this.get(servicePath);
    }

    getDeviceProfile(taskableUID)
    {
        const servicePath = "taskables/" + taskableUID + "/device";

        return this.get(servicePath);
    }

    getHistory(taskableUID)
    {
        const servicePath = "taskables/" + taskableUID + "/history";

        return this.get(servicePath);
    }

    getNetworks(taskableUID)
    {
        const servicePath = "taskables/" + taskableUID + "/networks";

        return this.get(servicePath);
    }

    getRuntimes(taskableUID)
    {
        const servicePath = "taskables/" + taskableUID + "/runtimes";

        return this.get(servicePath);
    }
}
