import React, {useEffect} from "react";
import {Button, Divider, Stack, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import GridViewIcon from "@mui/icons-material/GridView";
import {buttonStyle, sectionStack, subSectionPanel} from "../../../../../AppStyles";

const TaskableProcessorsPane = (props) => {

    const navigate = useNavigate();

    useEffect(() => {

    }, []);

    function onView()
    {
        navigate("../networks/" + props.networkUID + "/taskables/" + props.taskableUID + "/processors");
    }

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%"
               alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/task_processor.png" width={32} height={32}/>
                    <Typography variant="h6">Task Processors</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Button onClick={onView} title="View All"
                            sx={buttonStyle}
                            startIcon={<GridViewIcon />}>View All</Button>
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
            <Stack direction="column" spacing={1} padding={1} width="100%" minHeight={100}
                   sx={subSectionPanel}>

            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
        </Stack>
    );
}

export default TaskableProcessorsPane;
