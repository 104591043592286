import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useParams} from "react-router-dom";
import * as React from "react";
import {appSession} from "../../../../Broadtask";
import {mainHeader} from "../../../../../AppStyles";

const TaskableProcessorManager = () =>
{
    const {networkUID, taskableUID} = useParams();

    return <Stack spacing={2}>
        <Stack direction="row"
               sx={mainHeader}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/networks"} underline={"hover"}>Networks</Link>
                <Link component={RouterLink} to={"/networks/" + networkUID} underline={"hover"}>{networkUID}</Link>
                <Link component={RouterLink} to={"/networks/" + networkUID + "/taskables"} underline={"hover"}>Taskables</Link>
                <Link component={RouterLink} to={"/networks/" + networkUID + "/taskables/" + taskableUID} underline={"hover"}>{taskableUID}</Link>
                <Typography>Task Processors</Typography>
            </Breadcrumbs>
        </Stack>

        {
            appSession.activeSession() === false ?
            <Navigate to="/login"/> :
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2} padding={0}>


            </Stack>
        }

    </Stack>
};

export default TaskableProcessorManager;
