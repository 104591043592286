import {diosAPI} from "../api/BroadtaskAPI";

export default class DIOSInstance
{
    constructor(diosUID)
    {
        this.diosUID = diosUID;
    }

    getSettings(force, callback)
    {
        if (this.settings === true && force === false)
        {
            callback(this.settings);
            return;
        }

        const apiCall = diosAPI.getDIOSSettings(this.diosUID);
        apiCall.then(response => {

            this.settings = response.data;

            callback(this.settings);
        })
        .catch((reason) => {
            console.log("DIOSInstance::getSettings::ERROR::" + reason.response.data);
            callback(null);
        })
    }
}
