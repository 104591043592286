import {Breadcrumbs, Button, Divider, Link, Stack, TextField, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useNavigate} from "react-router-dom";
import * as React from "react";
import {useState} from "react";
import {appSession, dataManager} from "../../Broadtask";
import ImageEditor from "../../components/ImageEditor";
import {networkAPI} from "../../api/BroadtaskAPI";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import {Check} from "@mui/icons-material";
import {buttonStyle, mainHeader, sectionStack} from "../../../AppStyles";

const NetworkCreate = () =>
{
    const navigate = useNavigate();

    const [key, setKey] = useState("");
    const [display, setDisplay] = useState("");
    const [fileIcon, setFileIcon] = useState(null);

    function onCancel()
    {
        navigate("/networks");
    }

    async function onCreate()
    {
        const settings = {
            networkKey: key,
            display: display
        };

        dataManager.getNetworkManager().createNetwork(settings, (success, response) => {
            if (success === true)
            {
                const networkUID = response;

                if (fileIcon)
                {
                    const iconUpdated = networkAPI.updateIcon(networkUID, fileIcon);
                    console.log(iconUpdated);
                }

                navigate("/networks/" + networkUID);
            }
        });
    }

    async function onChangeIcon(fileImage)
    {
        // console.log("NetworkCreate::onChangeIcon");
        // console.log(fileImage);
        setFileIcon(fileImage);
    }

    return <Stack spacing={1}>
        <Stack direction="row"
               sx={mainHeader}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/networks"} underline={"hover"}>Networks</Link>
                <Typography>Create Network</Typography>
            </Breadcrumbs>
        </Stack>
        {
            appSession.activeSession() === false ?
            <Navigate to="/login" />:
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2} padding={1}>

                {/* Create Editor */}
                <Stack direction="column" spacing={1} padding={1} width="100%" alignItems="flex-start" justifyContent="flex-start"
                       sx={sectionStack}>
                    <Stack direction="row" width="100%"
                           justifyContent="space-between"
                           padding={1} spacing={1}>
                        <Typography variant="h6">Create a Network</Typography>
                        <Stack direction="row" spacing={1}>
                            <Button onClick={onCancel} sx={buttonStyle} startIcon={<CancelIcon />}>CANCEL</Button>
                            <Button onClick={onCreate} sx={buttonStyle} startIcon={<Check />}>CREATE</Button>
                        </Stack>
                    </Stack>
                    <Divider orientation="horizontal" variant="left" flexItem />
                    <Stack direction="row" spacing={1} padding={1} width="100%" minHeight={100}
                           sx={{backgroundColor: '#141c21', borderRadius: 2}}>

                        <ImageEditor defaultImagePath="/icons/network.png" onImageChange={onChangeIcon} />
                        <Stack direction="column" spacing={1} width="100%">
                            <TextField label="Key" fullWidth={true} size="small" value={key} type="text" onChange={(event) => { setKey(event.target.value); }} />
                            <TextField label="Display" fullWidth={true} size="small" value={display} type="text" onChange={(event) => { setDisplay(event.target.value); }} />
                        </Stack>

                    </Stack>
                </Stack>

            </Stack>
        }

    </Stack>
};

export default NetworkCreate;
