import {processAPI} from "../api/BroadtaskAPI";
import ProcessInstance from "./ProcessInstance";
import {appMessaging} from "../Broadtask";

export default class ProcessManager
{
    constructor()
    {
        this.instances = new Map();
    }

    getProcesses(force, callback)
    {
        if (this.master !== undefined && this.master !== null && force === false)
        {
            callback(this.master);
            return;
        }

        const apiCall = processAPI.getProcesses();
        apiCall.then(response => {
            this.master = response.data;
            callback(this.master);
        })
        .catch((reason) => {
            console.log("ProcessManager::getProcesses::ERROR::" + reason.response.data);
            callback([]);
        })
    }

    createProcess(settings, callback)
    {
        const apiCall = processAPI.createProcess(settings);
        apiCall.then((response) => {

            //Null out the master to force reload
            this.master = null;

            const processUID = response.data.processUID;
            appMessaging.showSuccess(settings.display + ' process created', 5000);

            callback(true, processUID);
        })
        .catch((error) => {
            appMessaging.showError('Error creating process: ' + error, 10000);

            callback(false, error);
        });
    }

    getInstance(processUID)
    {
        if (this.instances.has(processUID) === true)
        {
            return this.instances.get(processUID);
        }

        const instance = new ProcessInstance(processUID);
        this.instances.set(processUID, instance);

        return instance;
    }
}
