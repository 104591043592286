import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useParams} from "react-router-dom";
import * as React from "react";
import TaskableRegistrationPane from "../../components/taskable/TaskableRegistrationPane";
import TaskableUtilizationPane from "../../components/taskable/TaskableUtilizationPane";
import TaskableDeviceProfilePane from "../../components/taskable/TaskableDeviceProfilePane";
import TaskableDeploymentProfilePane from "../../components/taskable/TaskableDeploymentProfilePane";
import TaskableApplicationsPane from "../../components/taskable/TaskableApplicationsPane";
import TaskableRuntimesPane from "../../components/taskable/TaskableRuntimesPane";
import TaskableNetworksPane from "../../components/taskable/TaskableNetworksPane";
import TaskableHistoryPane from "../../components/taskable/TaskableHistoryPane";
import {appSession} from "../../Broadtask";

const TaskableViewer = () =>
{
    const {taskableUID} = useParams();

    return <Stack spacing={2} padding={1}>
        <Stack direction="row"
               sx={{ backgroundColor: '#182229'}}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/taskables"} underline={"hover"}>Taskables</Link>
                <Typography>{taskableUID}</Typography>
            </Breadcrumbs>
        </Stack>
        {
            appSession.activeSession() === false ?
            <Navigate to="/login" />:
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2}
                   padding={1}>

                <Stack direction="row" spacing={2} justifyContent="stretch" alignItems="flex-start">

                    <TaskableRegistrationPane taskableUID={taskableUID} />

                    <TaskableUtilizationPane taskableUID={taskableUID} />

                </Stack>

                <Stack direction="row" spacing={2} justifyContent="stretch" alignItems="flex-start">

                    <TaskableDeviceProfilePane taskableUID={taskableUID} />

                    <TaskableDeploymentProfilePane taskableUID={taskableUID} />

                </Stack>

                <Stack direction="row" spacing={2} justifyContent="stretch" alignItems="flex-start">

                    <TaskableApplicationsPane taskableUID={taskableUID} />

                    <TaskableRuntimesPane taskableUID={taskableUID} />

                </Stack>


                <TaskableNetworksPane taskableUID={taskableUID} />

                <TaskableHistoryPane taskableUID={taskableUID} />

                {/*<Stack direction="row" spacing={2} justifyContent="stretch" alignItems="flex-start">*/}

                {/*    <TaskableExtensionsPane taskableUID={taskableUID} />*/}

                {/*    <TaskableCommandsPane taskableUID={taskableUID} />*/}

                {/*    <TaskableServicesPane taskableUID={taskableUID} />*/}

                {/*    <TaskableTaskProcessorsPane taskableUID={taskableUID} />*/}

                {/*</Stack>*/}
            </Stack>
        }
        </Stack>
};

export default TaskableViewer;
