import APICaller from "./APICaller";

export default class ExtensionsAPI extends APICaller
{
    constructor()
    {
        super();
    }

    getExtensions()
    {
        const servicePath = "extensions";

        return this.get(servicePath);
    }

    createExtension(fileExt)
    {
        const servicePath = "extensions";

        const payload = {

        };

        return this.post(servicePath, payload);
    }

    getSettings(extensionUID)
    {
        const servicePath = "extensions/" + extensionUID;

        return this.get(servicePath);
    }

    deploy(extensionUID)
    {
        const servicePath = "extensions/" + extensionUID;

        const payload = {

        };

        return this.post(servicePath, payload);
    }

    getTaskables(extensionUID)
    {
        const servicePath = "extensions/" + extensionUID + "/taskables";

        return this.get(servicePath);
    }

    uninstall(extensionUID)
    {
        const servicePath = "extensions/" + extensionUID + "/uninstall";

        const payload = {

        };

        return this.post(servicePath, payload);
    }

    upload(extensionUID, fileExt)
    {
        const servicePath = "extensions/" + extensionUID + "/upload";

        const payload = {

        };

        return this.post(servicePath, payload);
    }
}
