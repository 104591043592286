import axios from "axios";
import {appSession} from "../Broadtask";
import {createRandomAlphaNum, hash256Hex} from "../BTUtils";

export default class APICaller
{
    constructor()
    {

    }

    async get(servicePath)
    {
        const serviceURL = process.env.REACT_APP_API_ROOT + servicePath;

        const config = this.createConfig();

        console.log("APICaller::get::" + serviceURL);

        return axios.get(serviceURL, config);
    }

    async post(servicePath, payload)
    {
        const serviceURL = process.env.REACT_APP_API_ROOT + servicePath;

        const config = this.createConfig();

        console.log("APICaller::post::" + serviceURL);

        return axios.post(serviceURL, payload, config);
    }

    async getResponse(servicePath)
    {
        const serviceURL = process.env.REACT_APP_API_ROOT + servicePath;

        const config = this.createConfig();

        console.log("APICaller::getResponse::" + serviceURL);

        const response = await axios.get(serviceURL, config)
            .then((response) => {
                return response;
            })
            .catch((reason) => {
                return reason;
            });

        return response;
    }

    async postResponse(servicePath, payload)
    {
        const serviceURL = process.env.REACT_APP_API_ROOT + servicePath;

        const config = this.createConfig();

        console.log("APICaller::postResponse::" + serviceURL);

        const response = await axios.post(serviceURL, payload, config)
            .then((response) => {
                return response;
            })
            .catch((reason) => {
                console.log(reason);
                return reason;
            });

        return response;
    }

    createConfig()
    {
        //Create a random seed value
        const seed = createRandomAlphaNum(12);

        //Add the random seed to the bsat
        const content = appSession.bsat + seed;
        //Calculate the hash token
        const hashToken = hash256Hex(content);

        //Build the SAT code
        const satCode = appSession.bsak + "-" + seed + "-" + hashToken;

        const config = {
            headers: {
                "X-Api-Key": process.env.REACT_APP_API_KEY,
                "X-Broadtask-Sat": satCode
            }
        }

        return config;
    }

}
