import {Stack, Typography} from "@mui/material";
import * as React from "react";
import {useNavigate} from "react-router-dom";

const NetworkTaskableCard = (props) =>
{
    const navigate = useNavigate();

    let iconPath = "/icons/taskable.png";

    switch (props.entry.taskableType)
    {
        case "COMPUTER":
            iconPath = "/icons/taskable_computer.png";
            break;
        case "MOBILE_ANDROID":
            iconPath = "/icons/taskable_android.png";
            break;
        case "MOBILE_IOS":
            iconPath = "/icons/taskable_ios.png";
            break;
        case "PI":
            iconPath = "/icons/taskable_pi.png";
            break;
    }

    function onView()
    {
        navigate("../networks/" + props.networkUID + "/taskables/" + props.entry.taskableUID);
    }

    return (
        <Stack direction="column" padding={1} spacing={1} alignItems="center" backgroundColor={'#141C21'} onClick={onView} borderRadius={3} sx={{width: 200, height: 200, cursor: "pointer"}}>
            <img src={iconPath} width={64} height={64}/>
            <Stack direction="column" spacing={0} padding={1} alignItems="flex-start">
                <Typography>{props.entry.display}</Typography>
                <Typography variant="caption">{props.entry.taskableUID}</Typography>
                <Typography variant="caption">Type: {props.entry.taskableType}</Typography>
            </Stack>
        </Stack>
    )
}
export default NetworkTaskableCard;
