import APICaller from "./APICaller";

export default class ProcessAPI extends APICaller
{
    constructor()
    {
        super();
    }

    getProcesses()
    {
        const servicePath = "processes";

        return this.get(servicePath);
    }

    createProcess(settings)
    {
        const servicePath = "processes";

        return this.post(servicePath, settings);
    }

    deleteProcess(processUID)
    {
        const servicePath = "processes/" + processUID + "/delete";

        const payload = {

        };

        return this.post(servicePath, payload);
    }

    getProcessSettings(processUID)
    {
        const servicePath = "processes/" + processUID;

        return this.get(servicePath);
    }

    saveProcessSettings(processUID, settings)
    {
        const servicePath = "processes/" + processUID;

        return this.post(servicePath, settings);
    }

    getStages(processUID)
    {
        const servicePath = "processes/" + processUID + "/stages";

        return this.get(servicePath);
    }

    createStage(processUID, settings)
    {
        const servicePath = "processes/" + processUID + "/stages";

        return this.post(servicePath, settings);
    }

    deleteStage(processUID, stageUID)
    {
        const servicePath = "processes/" + processUID + "/stages/" + stageUID + "/delete";

        const payload = {

        };

        return this.post(servicePath, payload);
    }

    getStageSettings(processUID, stageUID)
    {
        const servicePath = "processes/" + processUID + "/stages/" + stageUID;

        return this.get(servicePath);
    }

    saveStageSettings(processUID, stageUID, settings)
    {
        const servicePath = "processes/" + processUID + "/stages/" + stageUID;

        return this.post(servicePath, settings);
    }

    getTasks(processUID, stageUID)
    {
        const servicePath = "processes/" + processUID + "/stages/" + stageUID + "/tasks";

        return this.get(servicePath);
    }

    createTask(processUID, stageUID, settings)
    {
        const servicePath = "processes/" + processUID + "/stages/" + stageUID + "/tasks";

        return this.post(servicePath, settings);
    }

    deleteTask(processUID, stageUID, taskUID)
    {
        const servicePath = "processes/" + processUID + "/stages/" + stageUID + "/tasks/" + taskUID + "/delete";

        const payload = {

        };

        return this.post(servicePath, payload);
    }

    getTaskSettings(processUID, stageUID, taskUID)
    {
        const servicePath = "processes/" + processUID + "/stages/" + stageUID + "/tasks/" + taskUID;

        return this.get(servicePath);
    }

    saveTaskSettings(processUID, stageUID, taskUID, settings)
    {
        const servicePath = "processes/" + processUID + "/stages/" + stageUID + "/tasks/" + taskUID;

        return this.post(servicePath, settings);
    }
}
