import {Breadcrumbs, Button, Stack, Typography} from "@mui/material";
import {Navigate, useNavigate} from "react-router-dom";
import * as React from "react";
import {useEffect} from "react";
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import NetworkSelectPane from "../components/network/NetworkSelectPane";
import TaskableSelectPane from "../components/taskable/TaskableSelectPane";
import DIOSSelectPane from "../components/dios/DIOSSelectPane";
import ProcessSelectPane from "../components/process/ProcessSelectPane";
import ExtensionSelectPane from "../components/extension/ExtensionSelectPane";
import AccountUtilizationPane from "../components/account/AccountUtilizationPane";
import TaskableDeploymentPane from "../components/taskable/TaskableDeploymentPane";
import {appSession} from "../Broadtask";

const AppHome = () =>
{
    const navigate = useNavigate();

    useEffect(() => {

    }, []);

    return <Stack spacing={1}>
        <Stack direction="row"
               sx={{ backgroundColor: '#182229'}}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Typography>Home</Typography>
            </Breadcrumbs>
        </Stack>
        {
            appSession.activeSession() === false ?
            <Navigate to="/login" />:
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                spacing={2}
                padding={1}>

                <NetworkSelectPane />

                <AccountUtilizationPane />

                <TaskableDeploymentPane />

                <TaskableSelectPane />
                <ExtensionSelectPane />
                <ProcessSelectPane />
                <DIOSSelectPane />

                <Stack direction="row" spacing={1} justifyContent="stretch" alignItems="flex-start">
                </Stack>

                <Stack direction="row" spacing={1} justifyContent="stretch" alignItems="flex-start">
                </Stack>

            </Stack>
        }

    </Stack>
};

export default AppHome;
