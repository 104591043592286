import APICaller from "./APICaller";

export default class ChannelAPI extends APICaller
{
    constructor()
    {
        super();
    }

    getChannels(networkUID)
    {
        const servicePath = "networks/" + networkUID + "/channels";

        return this.get(servicePath);
    }

    createChannel(networkUID, settings)
    {
        const servicePath = "networks/" + networkUID + "/channels";

        return this.post(servicePath, settings);
    }

    deleteChannel(networkUID, channelUID)
    {
        const servicePath = "networks/" + networkUID + "/channels/" + channelUID + "/delete";

        const payload = {

        };

        return this.post(servicePath, payload);
    }

    getChannelSettings(networkUID, channelUID)
    {
        const servicePath = "networks/" + networkUID + "/channels/" + channelUID;

        return this.get(servicePath);
    }

    saveChannelSettings(networkUID, channelUID, settings)
    {
        const servicePath = "networks/" + networkUID + "/channels/" + channelUID;

        return this.post(servicePath, settings);
    }

    getChannelListeners(networkUID, channelUID)
    {
        const servicePath = "networks/" + networkUID + "/channels/" + channelUID + "/listeners";

        return this.get(servicePath);
    }

    createChannelListener(networkUID, channelUID, settings)
    {
        const servicePath = "networks/" + networkUID + "/channels/" + channelUID + "/listeners";

        return this.post(servicePath, settings);
    }

    deleteChannelListener(networkUID, channelUID, listenerUID)
    {
        const servicePath = "networks/" + networkUID + "/channels/" + channelUID + "/listeners/" + listenerUID + "/delete";

        const payload = {

        };

        return this.post(servicePath, payload);
    }

    getChannelListenerSettings(networkUID, channelUID, listenerUID)
    {
        const servicePath = "networks/" + networkUID + "/channels/" + channelUID + "/listeners/" + listenerUID;

        return this.get(servicePath);
    }

    saveChannelListenerSettings(networkUID, channelUID, listenerUID, settings)
    {
        const servicePath = "networks/" + networkUID + "/channels/" + channelUID + "/listeners/" + listenerUID;

        return this.post(servicePath, settings);
    }

}
