import React, {useEffect} from "react";
import {Divider, Stack, Typography} from "@mui/material";
import {sectionPanel, subSectionPanel} from "../../../AppStyles";

const ExtensionDetailsPane = (props) => {

    useEffect(() => {

    }, []);

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%"
               alignItems="flex-start" justifyContent="flex-start"
               sx={sectionPanel}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/channel.png" width={32} height={32}/>
                    <Typography variant="h6">Extension Details</Typography>
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
            <Stack direction="row" spacing={1} padding={1} width="100%" minHeight={100}
                   sx={sectionPanel}>
                <Stack direction="row" width="50%" alignItems="flex-start" spacing={1}>
                    <Stack direction="column" justifyContent="center">
                        <Stack direction="column" alignItems="center" justifyContent="center" width={150} height={150} sx={{ backgroundColor: '#000000', borderRadius:2, borderColor: '#FFFFFF', borderStyle:'solid' }}>
                            <img src="/icons/extension.png" height={128} width={128} />
                        </Stack>
                    </Stack>
                    <Stack direction="column" spacing={1} padding={1} width="100%" alignItems="flex-start">
                        <Typography>TYPE</Typography>
                        <Typography>NAMESPACE</Typography>
                        <Typography>KEY + VERSION + RELEASE DATE</Typography>
                        <Typography>NAME</Typography>
                        <Typography>URL</Typography>
                    </Stack>
                </Stack>
                <Stack direction="row" width="50%" alignItems="flex-start" spacing={1}>
                    <Stack direction="column" justifyContent="center">
                        <Stack direction="column" alignItems="center" justifyContent="center" width={150} height={150} sx={{ backgroundColor: '#000000', borderRadius:2, borderColor: '#FFFFFF', borderStyle:'solid' }}>
                            <img src="/icons/company.png" height={128} width={128} />
                        </Stack>
                    </Stack>
                    <Stack direction="column" spacing={1} padding={1} width="100%" alignItems="flex-start">
                        <Typography>PUBLISHER</Typography>
                        <Typography>SUMMARY</Typography>
                        <Typography>URL</Typography>
                        <Typography>CONTACT EMAIL</Typography>
                        <Typography>CONTACT PHONE</Typography>
                    </Stack>
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
        </Stack>
    );
}

export default ExtensionDetailsPane;
