import React, {useEffect, useState} from "react";
import {Button, Divider, Slider, Stack, Switch, Typography} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {appMessaging, dataManager} from "../../Broadtask";
import {useNavigate} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from '@mui/icons-material/Cancel';
import {buttonStyle, sectionStack, subSectionPanel} from "../../../AppStyles";

const AccountSubscriptionPane = (props) => {

    const navigate = useNavigate();

    const [editMode, setEditMode] = useState(false);
    const [postBlocks, setPostBlocks] = useState(1);
    const [storageBlocks, setStorageBlocks] = useState(1);
    const [taskBlocks, setTaskBlocks] = useState(1);
    const [taskableBlocks, setTaskableBlocks] = useState(1);
    const [enableTaskableVM, setEnableTaskableVM] = useState(false);

    useEffect(() => {
        dataManager.getAccountManager().getSubscription(false, (subscription) => {
            setPostBlocks(subscription.postBlocks);
            setStorageBlocks(subscription.storageBlocks);
            setTaskBlocks(subscription.taskBlocks);
            setTaskableBlocks(subscription.taskableBlocks);
            setEnableTaskableVM(subscription.enableTaskableVM);
        });
    }, []);

    function onEdit()
    {
        setEditMode(true);
    }

    function onCancel()
    {
        setEditMode(false);
    }

    function onSave()
    {
        const subscription = {
            "type": "standard"
        };

        dataManager.getAccountManager().saveSubscription(subscription, (status) => {

            if (status === 200)
            {
                appMessaging.showSuccess("Account subscription updated", 5000);
                setEditMode(false);
            }
            else
            {
                appMessaging.showError("Error occurred updating account subscription", 5000);
            }
        });
    }

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%"
               alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/subscription.png" width={32} height={32}/>
                    <Typography variant="h6">Subscription</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    {
                        editMode === false ?
                            <Button onClick={onEdit} sx={buttonStyle} startIcon={<EditIcon />}>Edit</Button> :
                            <Stack direction="row">
                                <Button onClick={onCancel} sx={buttonStyle} startIcon={<CancelIcon />}>Cancel</Button>
                                <Button onClick={onSave} sx={buttonStyle} startIcon={<SaveIcon />}>Save</Button>
                            </Stack>
                    }
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
            <Stack direction="column" spacing={1} padding={1} width="100%" minHeight={100}
                   sx={subSectionPanel}>
                <Stack direction="row" spacing={2}>
                    <Typography minWidth={120}>Posts</Typography>
                    {
                        editMode === false ?
                            <Typography minWidth={200}>{(postBlocks * 1000000).toLocaleString(undefined, { maximumFractionDigits: 0})}</Typography> :
                            <Slider size="small" aria-label="Posts" value={postBlocks} onChange={(event) => setPostBlocks(event.target.value)} step={1} min={1} max={999} valueLabelDisplay="auto" />
                    }
                    <Typography minWidth={150}>Per Month</Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <Typography minWidth={120}>Storage</Typography>
                    {
                        editMode === false ?
                            <Typography minWidth={200}>{storageBlocks}</Typography> :
                            <Slider size="small" aria-label="Storage" value={storageBlocks} onChange={(event) => setStorageBlocks(event.target.value)} step={1} min={1} max={9999} valueLabelDisplay="auto" />
                    }
                    <Typography minWidth={150}>GB Per Month</Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <Typography minWidth={120}>Tasks</Typography>
                    {
                        editMode === false ?
                            <Typography minWidth={200}>{(taskBlocks * 100000).toLocaleString(undefined, { maximumFractionDigits: 0})}</Typography> :
                            <Slider size="small" aria-label="Tasks" value={taskBlocks} onChange={(event) => setTaskBlocks(event.target.value)} step={1} min={1} max={9999} valueLabelDisplay="auto" />
                    }
                    <Typography minWidth={150}>GB Per Month</Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <Typography minWidth={120}>Taskables</Typography>
                    {
                        editMode === false ?
                            <Typography minWidth={200}>{(taskableBlocks * 10)}</Typography> :
                            <Slider size="small" aria-label="Taskables" value={taskableBlocks} onChange={(event) => setTaskableBlocks(event.target.value)} step={1} min={1} max={99} valueLabelDisplay="auto" />
                    }
                    <Typography minWidth={150}>Connections</Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <Typography minWidth={120}>Taskables VM</Typography>
                    {
                        editMode === false ?
                            <Typography minWidth={200}>{enableTaskableVM ? "ENABLED": "DISABLED"}</Typography> :
                            <Switch value={enableTaskableVM} />
                    }
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
        </Stack>
);
}

export default AccountSubscriptionPane;
