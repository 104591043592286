import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate} from "react-router-dom";
import * as React from "react";
import TaskableManagerPane from "../../components/taskable/TaskableManagerPane";
import {appSession} from "../../Broadtask";
import {mainHeader} from "../../../AppStyles";

const TaskableManager = () =>
{
    return <Stack spacing={2}>
        <Stack direction="row"
               sx={mainHeader}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Typography>Taskables</Typography>
            </Breadcrumbs>
        </Stack>
        {
            appSession.activeSession() === false ?
            <Navigate to="/login" />:
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2}
                   padding={1}>

                <TaskableManagerPane />

            </Stack>
        }
    </Stack>
};

export default TaskableManager;
