import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    Divider,
    IconButton,
    Stack, styled, ToggleButtonGroup,
    Typography
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {dataManager} from "../../Broadtask";
import MuiToggleButton from "@mui/material/ToggleButton";
import ViewStatistics from '@mui/icons-material/MultilineChart';
import NetworkCard from "../network/NetworkCard";
import HideShowButton from "../HideShowButton";
import {buttonStyle, sectionStack, toggleButtonStyle} from "../../../AppStyles";

const AccountUtilizationPane = (props) => {

    const navigate = useNavigate();

    let [lastStatus, setLastStatus] = useState("current");

    const [showPanel, setShowPanel] = useState(true);

    const ToggleButton = styled(MuiToggleButton)({
        "&.Mui-selected, &.Mui-selected:hover": {
            color: '#495e67',
            backgroundColor: '#f9a555'
        },
        color: '#495e67'
    });

    useEffect(() => {
        reload(false);
    }, []);

    function onRefresh()
    {
        reload(true);
    }

    function reload(force)
    {
        dataManager.getAccountManager().getUtilization("last24", force, (utilization) => {

        });
    }

    function onView()
    {
        navigate("/utilization");
    }

    function onTogglePanel()
    {
        setShowPanel(!showPanel);
    }

    function handleLastStatus(selectedLastStatus)
    {
        lastStatus = selectedLastStatus;
        setLastStatus(lastStatus);
    }

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%" alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%" justifyContent="space-between" alignItems="left">
                <Stack direction="row" alignItems="left">
                    <img src="/icons/utilization.png" width={58} height={58}/>
                    <Stack direction="column" width="100%" alignItems="flex-start" justifyContent="flex-start">
                        <Typography color="#84898D" variant="subtitle1">UTILIZATION</Typography>
                        <Typography color="#495D67" variant="subtitle2" alignItems="left">STATISTICS FOR ACCOUNT LEVEL DATA POINTS</Typography>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={1} padding={1} alignItems="center">
                    <Card variant="outlined"
                          sx={{
                              display: 'flex',
                              color: '#f9a555',
                              backgroundColor: '#141c21',
                              '& svg': {
                                  m: 1,
                              },
                              '& hr': {
                                  mx: 0.5,
                              },
                          }}
                    >
                        <Button onClick={onView}
                                sx={buttonStyle}
                                startIcon={<ViewStatistics/>} >ANALYZE</Button>
                    </Card>
                    <HideShowButton onChange={onTogglePanel} />
                </Stack>
            </Stack>
            {
                showPanel === false ?
                    <></> :

                <Stack direction="column" width="100%">
                    <Divider orientation="horizontal" variant="CENTER" width="100%" flexItem>
                        <Typography fontSize="small" sx={{ color: '#808183'}}>STATISTICS</Typography>
                    </Divider>
                    <Stack direction="row" width="100%" alignItems="center">
                        <Stack direction="row" alignItems="center" spacing={1} padding={1} width="100%" minHeight={100}
                               useFlexGap sx={{ flexWrap: "wrap", backgroundColor: '#2A333A'}}>
                            {
                                <Stack direction="row" padding={1} spacing={1} backgroundColor={'#2a333a'} borderRadius={3} sx={{alignItems: 'center', width: '100%'}}>
                                    <Stack direction="column" padding={1} spacing={1} alignItems="center" backgroundColor={'#141C21'} borderRadius={3} style={{ height: 195, width: 215 }}>
                                        <Typography fontSize="medium" sx={{ color: '#808183', fontSize: '14px'}}>REQUESTS</Typography>
                                        <Divider orientation="horizontal" variant="CENTER" sx={{ color: '#808183', borderStyle: 'dashed', padding:'1'}} width="100%" flexItem/>
                                        <Stack direction="row" padding={0} spacing={1} alignItems="center" backgroundColor={'#141C21'} sx={{ borderRadius: '3'}}>
                                            <Typography sx={{ color: '#ffffff', fontSize: '10px', fontStretch: 'extra-expanded', textAlign:'right', width: 60}}>CREATED:</Typography>
                                            <Stack direction="row" padding={1} spacing={1} alignItems="center" backgroundColor={'#182229'} sx={{ borderRadius: '3', width: 100}}>
                                                <Typography alignItems="right" sx={{ color: '#495d67', fontSize: '11px', fontWeight: 'bold', textAlign:'left'}}>Total: </Typography>
                                                <Typography alignItems="right" sx={{ color: '#09a3c3', fontSize: '11px', fontWeight: 'bold', textAlign:'right', width: '100%'}}>0 </Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack direction="row" padding={0} spacing={1} alignItems="center" backgroundColor={'#141C21'} sx={{ borderRadius: '3'}}>
                                            <Typography style={{ color: '#ffffff', fontSize: '10px', fontStretch: 'extra-expanded', textAlign:'right', width: 60}}>COMPLETED:</Typography>
                                            <Stack direction="row" padding={1} spacing={1} alignItems="center" backgroundColor={'#182229'} sx={{ borderRadius: '3', width: 100}}>
                                                <Typography alignItems="right" sx={{ color: '#495d67', fontSize: '11px', fontWeight: 'bold', textAlign:'left'}}>Total: </Typography>
                                                <Typography alignItems="right" sx={{ color: '#09a3c3', fontSize: '11px', fontWeight: 'bold', textAlign:'right', width: '100%'}}>0 </Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack direction="row" padding={0} spacing={1} alignItems="center" backgroundColor={'#141C21'} sx={{ borderRadius: '3'}}>
                                            <Typography sx={{ color: '#ffffff', fontSize: '10px', fontStretch: 'extra-expanded', textAlign:'right', width: 60}}>ERROR:</Typography>
                                            <Stack direction="row" padding={1} spacing={1} alignItems="center" backgroundColor={'#182229'} sx={{ borderRadius: '3', width: 100}}>
                                                <Typography alignItems="right" sx={{ color: '#495d67', fontSize: '11px', fontWeight: 'bold', textAlign:'left'}}>Total: </Typography>
                                                <Typography alignItems="right" sx={{ color: '#ba3f61', fontSize: '11px', fontWeight: 'bold', textAlign:'right', width: '100%'}}>0 </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    <Stack direction="column" padding={1} spacing={1} alignItems="center" backgroundColor={'#141C21'} borderRadius={3} style={{ height: 195, width: 215 }}>
                                        <Typography fontSize="medium" sx={{ color: '#808183', fontSize: '14px'}}>TASKS</Typography>
                                        <Divider orientation="horizontal" variant="CENTER" sx={{ color: '#808183', borderStyle: 'dashed', padding:'1'}} width="100%" flexItem/>
                                        <Stack direction="row" padding={0} spacing={1} alignItems="center" backgroundColor={'#141C21'} sx={{ borderRadius: '3'}}>
                                            <Typography sx={{ color: '#ffffff', fontSize: '10px', fontStretch: 'extra-expanded', textAlign:'right', width: 60}}>CREATED:</Typography>
                                            <Stack direction="row" padding={1} spacing={1} alignItems="center" backgroundColor={'#182229'} sx={{ borderRadius: '3', width: 100}}>
                                                <Typography alignItems="right" sx={{ color: '#495d67', fontSize: '11px', fontWeight: 'bold', textAlign:'left'}}>Total: </Typography>
                                                <Typography alignItems="right" sx={{ color: '#09a3c3', fontSize: '11px', fontWeight: 'bold', textAlign:'right', width: '100%'}}>0 </Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack direction="row" padding={0} spacing={1} alignItems="center" backgroundColor={'#141C21'} sx={{ borderRadius: '3'}}>
                                            <Typography style={{ color: '#ffffff', fontSize: '10px', fontStretch: 'extra-expanded', textAlign:'right', width: 60}}>COMPLETED:</Typography>
                                            <Stack direction="row" padding={1} spacing={1} alignItems="center" backgroundColor={'#182229'} sx={{ borderRadius: '3', width: 100}}>
                                                <Typography alignItems="right" sx={{ color: '#495d67', fontSize: '11px', fontWeight: 'bold', textAlign:'left'}}>Total: </Typography>
                                                <Typography alignItems="right" sx={{ color: '#09a3c3', fontSize: '11px', fontWeight: 'bold', textAlign:'right', width: '100%'}}>0 </Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack direction="row" padding={0} spacing={1} alignItems="center" backgroundColor={'#141C21'} sx={{ borderRadius: '3'}}>
                                            <Typography sx={{ color: '#ffffff', fontSize: '10px', fontStretch: 'extra-expanded', textAlign:'right', width: 60}}>ERROR:</Typography>
                                            <Stack direction="row" padding={1} spacing={1} alignItems="center" backgroundColor={'#182229'} sx={{ borderRadius: '3', width: 100}}>
                                                <Typography alignItems="right" sx={{ color: '#495d67', fontSize: '11px', fontWeight: 'bold', textAlign:'left'}}>Total: </Typography>
                                                <Typography alignItems="right" sx={{ color: '#ba3f61', fontSize: '11px', fontWeight: 'bold', textAlign:'right', width: '100%'}}>0 </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    <Stack direction="column" padding={1} spacing={1} alignItems="center" backgroundColor={'#141C21'} borderRadius={3} style={{ height: 200, width: 215 }}>
                                        <Typography fontSize="medium" sx={{ color: '#808183',fontSize: '14px'}}>STREAMS</Typography>
                                        <Divider orientation="horizontal" variant="CENTER" sx={{ color: '#808183', borderStyle: 'dashed', padding:'1'}} width="100%" flexItem/>
                                        <Stack direction="row" padding={0} spacing={1} alignItems="center" backgroundColor={'#141C21'} sx={{ borderRadius: '3'}}>
                                            <Typography sx={{ color: '#ffffff', fontSize: '10px', fontStretch: 'extra-expanded', textAlign:'right', width: 60}}>POSTS:</Typography>
                                            <Stack direction="row" padding={1} spacing={1} alignItems="center" backgroundColor={'#182229'} sx={{ borderRadius: '3', width: 100}}>
                                                <Typography alignItems="right" sx={{ color: '#495d67', fontSize: '11px', fontWeight: 'bold', textAlign:'left'}}>Total: </Typography>
                                                <Typography alignItems="right" sx={{ color: '#09a3c3', fontSize: '11px', fontWeight: 'bold', textAlign:'right', width: '100%'}}>0 </Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack direction="row" padding={0} spacing={1} alignItems="center" backgroundColor={'#141C21'} sx={{ borderRadius: '3'}}>
                                            <Typography style={{ color: '#ffffff', fontSize: '10px', fontStretch: 'extra-expanded', textAlign:'right', width: 60}}>TRIGGERS:</Typography>
                                            <Stack direction="row" padding={1} spacing={1} alignItems="center" backgroundColor={'#182229'} sx={{ borderRadius: '3', width: 100}}>
                                                <Typography alignItems="right" sx={{ color: '#495d67', fontSize: '11px', fontWeight: 'bold', textAlign:'left'}}>Total: </Typography>
                                                <Typography alignItems="right" sx={{ color: '#09a3c3', fontSize: '11px', fontWeight: 'bold', textAlign:'right', width: '100%'}}>0 </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    <Stack direction="column" padding={1} spacing={1} alignItems="center" backgroundColor={'#141C21'} borderRadius={3} style={{ height: 200, width: 215 }}>
                                        <Typography sx={{ color: '#808183', fontSize: '14px'}}>CHANNELS</Typography>
                                        <Divider orientation="horizontal" variant="CENTER" sx={{ color: '#808183', borderStyle: 'dashed', padding:'1'}} width="100%" flexItem/>
                                        <Stack direction="row" padding={0} spacing={1} alignItems="center" backgroundColor={'#141C21'} sx={{ borderRadius: '3'}}>
                                            <Typography sx={{ color: '#ffffff', fontSize: '10px', fontStretch: 'extra-expanded', textAlign:'right', width: 60}}>POSTS:</Typography>
                                            <Stack direction="row" padding={1} spacing={1} alignItems="center" backgroundColor={'#182229'} sx={{ borderRadius: '3', width: 100}}>
                                                <Typography alignItems="right" sx={{ color: '#495d67', fontSize: '11px', fontWeight: 'bold', textAlign:'left'}}>Total: </Typography>
                                                <Typography alignItems="right" sx={{ color: '#09a3c3', fontSize: '11px', fontWeight: 'bold', textAlign:'right', width: '100%'}}>0 </Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack direction="row" padding={0} spacing={1} alignItems="center" backgroundColor={'#141C21'} sx={{ borderRadius: '3'}}>
                                            <Typography style={{ color: '#ffffff', fontSize: '10px', fontStretch: 'extra-expanded', textAlign:'right', width: 60}}>TRIGGERS:</Typography>
                                            <Stack direction="row" padding={1} spacing={1} alignItems="center" backgroundColor={'#182229'} sx={{ borderRadius: '3', width: 100}}>
                                                <Typography alignItems="right" sx={{ color: '#495d67', fontSize: '11px', fontWeight: 'bold', textAlign:'left'}}>Total: </Typography>
                                                <Typography alignItems="right" sx={{ color: '#09a3c3', fontSize: '11px', fontWeight: 'bold', textAlign:'right', width: '100%'}}>0 </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    <Stack direction="column" padding={1} spacing={1} alignItems="center" backgroundColor={'#141C21'} borderRadius={3} style={{ height: 200, width: 215 }}>
                                        <Typography sx={{ color: '#808183', fontSize: '14px'}}>TASKABLES</Typography>
                                        <Divider orientation="horizontal" variant="CENTER" sx={{ color: '#808183', borderStyle: 'dashed', padding:'1'}} width="100%" flexItem/>
                                        <Stack direction="row" padding={0} spacing={1} alignItems="center" backgroundColor={'#141C21'} sx={{ borderRadius: '3'}}>
                                            <Typography sx={{ color: '#ffffff', fontSize: '10px', fontStretch: 'extra-expanded', textAlign:'right', width: 60}}>MIN:</Typography>
                                            <Stack direction="row" padding={1} spacing={1} alignItems="center" backgroundColor={'#182229'} sx={{ borderRadius: '3', width: 100}}>
                                                <Typography alignItems="right" sx={{ color: '#495d67', fontSize: '11px', fontWeight: 'bold', textAlign:'left'}}>Total: </Typography>
                                                <Typography alignItems="right" sx={{ color: '#09a3c3', fontSize: '11px', fontWeight: 'bold', textAlign:'right', width: '100%'}}>0 </Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack direction="row" padding={0} spacing={1} alignItems="center" backgroundColor={'#141C21'} sx={{ borderRadius: '3'}}>
                                            <Typography style={{ color: '#ffffff', fontSize: '10px', fontStretch: 'extra-expanded', textAlign:'right', width: 60}}>MAX:</Typography>
                                            <Stack direction="row" padding={1} spacing={1} alignItems="center" backgroundColor={'#182229'} sx={{ borderRadius: '3', width: 100}}>
                                                <Typography alignItems="right" sx={{ color: '#495d67', fontSize: '11px', fontWeight: 'bold', textAlign:'left'}}>Total: </Typography>
                                                <Typography alignItems="right" sx={{ color: '#09a3c3', fontSize: '11px', fontWeight: 'bold', textAlign:'right', width: '100%'}}>0 </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            }
                        </Stack>
                    </Stack>
                <Stack direction="column" padding={0} spacing={1} alignItems="center" width="100%">
                    <ToggleButtonGroup value={lastStatus} exclusive aria-label="text alignment"
                                       onChange={event => handleLastStatus(event.target.value)}
                                       sx={toggleButtonStyle}>
                        <ToggleButton value="current" aria-label="current" >CURRENT DAY</ToggleButton>
                        <ToggleButton value="hour" aria-label="last hour">LAST HOUR</ToggleButton>
                        <ToggleButton value="day" aria-label="last day">LAST DAY</ToggleButton>
                    </ToggleButtonGroup>
                </Stack>
                <Divider orientation="horizontal" variant="CENTER" width="100%" flexItem>
                    <Typography fontSize="small" sx={{ color: '#808183'}}>UPDATED EVERY 5 MINUTES</Typography>
                </Divider>
            </Stack>
            }
    </Stack>
);
}

export default AccountUtilizationPane;
