import {Breadcrumbs, Button, Divider, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {appSession} from "../../../Broadtask";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {Check} from "@mui/icons-material";
import {buttonStyle, mainHeader, sectionStack, subSectionPanel} from "../../../../AppStyles";

const NetworkTaskableAdd = () =>
{
    const {networkUID} = useParams();

    const navigate = useNavigate();

    return <Stack spacing={1}>
        <Stack direction="row"
               sx={mainHeader}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/networks"} underline={"hover"}>Networks</Link>
                <Link component={RouterLink} to={"/networks/" + networkUID} underline={"hover"}>{networkUID}</Link>
                <Link component={RouterLink} to={"/networks/" + networkUID + "/taskables"} underline={"hover"}>Taskables</Link>
                <Typography>Add Taskable to Network</Typography>
            </Breadcrumbs>
        </Stack>

        {
            appSession.activeSession() === false ?
            <Navigate to="/login"/> :
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2} padding={1}>

                {/* Provision Taskable */}
                <Stack direction="column" spacing={1} padding={1} width="100%"
                       alignItems="flex-start" justifyContent="flex-start"
                       sx={sectionStack}>
                    <Stack direction="row" width="100%"
                           justifyContent="space-between"
                           padding={0} spacing={0}>
                        <Typography variant="h6">Add Taskable</Typography>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" paddingTop={1}>
                            <Button sx={buttonStyle} startIcon={<Check />}>ADD</Button>
                        </Stack>
                    </Stack>
                    <Divider orientation="horizontal" variant="left" flexItem />
                    <Stack direction="column" spacing={1} width="100%" minHeight={100}
                           sx={subSectionPanel}>

                    </Stack>
                    <Divider orientation="horizontal" variant="left" flexItem />
                </Stack>

            </Stack>
        }

    </Stack>
};

export default NetworkTaskableAdd;
