export const buttonStyle = { backgroundColor: '#141c21',color:'#f9a555'};
export const buttonBarCard = {
    display: 'flex',
    color: '#f9a555',
    backgroundColor: '#141c21',
    '& svg': {
        m: 1,
    },
    '& hr': {
        mx: 0.5,
    },
};
export const sectionTitle = { color: '#84898D'};
export const sectionSubtitle = { color: '#495D67'};
export const sectionStack = { backgroundColor: '#2A333A', borderRadius: 2}

export const sectionPanel = { backgroundColor: '#2A333A'}
export const subSectionPanel = { backgroundColor: '#141c21', borderRadius: 2}

export const mainHeader = { backgroundColor: '#182229'}
export const cardWrapper = { backgroundColor: '#141c21', flexWrap: 'wrap'}
export const toggleButtonStyle = { backgroundColor: '#182229', borderRadius: 2}
