import {Breadcrumbs, Divider, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {appSession} from "../../Broadtask";
import {sectionStack} from "../../../AppStyles";

const TaskableHistory = () =>
{
    const navigate = useNavigate();

    const {taskableUID} = useParams();

    return <Stack spacing={1}>
        <Stack direction="row"
               sx={{ backgroundColor: '#182229'}}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/taskables"} underline={"hover"}>Taskables</Link>
                <Link component={RouterLink} to={"/taskables/" + taskableUID} underline={"hover"}>{taskableUID}</Link>
                <Typography>History</Typography>
            </Breadcrumbs>
        </Stack>

        {
            appSession.activeSession() === false ?
            <Navigate to="/login"/> :
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2}
                   padding={1}>

                {/* History */}
                <Stack direction="column" spacing={1} padding={1} width="100%" alignItems="flex-start" justifyContent="flex-start"
                       sx={sectionStack}>
                    <Stack direction="row" width="100%"
                           justifyContent="space-between"
                           padding={1} spacing={1}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <img src="/icons/taskable.png" width={32} height={32}/>
                            <Typography variant="h6">History</Typography>
                        </Stack>
                    </Stack>
                    <Divider orientation="horizontal" variant="left" flexItem />
                    <Stack direction="column" spacing={1} width="100%" minHeight={100}
                           sx={{ backgroundColor: '#141c21'}}>

                    </Stack>
                    <Divider orientation="horizontal" variant="left" flexItem />
                </Stack>

            </Stack>
        }

    </Stack>
};

export default TaskableHistory;
