import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {appSession} from "../../../Broadtask";
import StreamDetailsPane from "../../../components/network/stream/StreamDetailsPane";
import StreamPersistencePane from "../../../components/network/stream/StreamPersistencePane";
import StreamListenersPane from "../../../components/network/stream/StreamListenersPane";
import StreamUtilizationPane from "../../../components/network/stream/StreamUtilizationPane";
import {sectionTitle} from "../../../../AppStyles";

const StreamViewer = () =>
{
    const navigate = useNavigate();

    const {networkUID, streamUID} = useParams();

    return <Stack spacing={1}>
        <Stack direction="row"
               sx={sectionTitle}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/networks"} underline={"hover"}>Networks</Link>
                <Link component={RouterLink} to={"/networks/" + networkUID} underline={"hover"}>{networkUID}</Link>
                <Link component={RouterLink} to={"/networks/" + networkUID + "/streams"} underline={"hover"}>Data Streams</Link>
                <Typography>{streamUID}</Typography>
            </Breadcrumbs>
        </Stack>

        {
            appSession.activeSession() === false ?
            <Navigate to="/login"/> :
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2} padding={1}>

                {/*Top Row*/}
                <Stack direction="row" spacing={1} justifyContent="stretch" alignItems="flex-start">

                    <StreamDetailsPane networkUID={networkUID} streamUID={streamUID} />

                    <StreamPersistencePane networkUID={networkUID} streamUID={streamUID} />

                </Stack>

                <StreamListenersPane networkUID={networkUID} streamUID={streamUID} />

                <StreamUtilizationPane networkUID={networkUID} streamUID={streamUID} />

            </Stack>
        }

    </Stack>
};

export default StreamViewer;
