import {networkAPI} from "../api/BroadtaskAPI";
import NetworkInstance from "./NetworkInstance";
import {appMessaging} from "../Broadtask";

export default class NetworkManager
{
    constructor()
    {
        this.instances = new Map();
    }

    getNetworks(force, callback)
    {
        if (this.master !== undefined && this.master !== null && force === false)
        {
            callback(this.master);
            return;
        }

        const apiCall = networkAPI.getNetworks();
        apiCall.then(response => {

            this.master = response.data;
            callback(this.master);
        })
        .catch((reason) => {
            console.log("NetworkManager::getNetworks::ERROR::" + reason.response.data);
            callback([]);
        })
    }

    createNetwork(settings, callback)
    {
        const apiCall = networkAPI.createNetwork(settings);
        apiCall.then((response) => {

            //Null out the master to force reload
            this.master = null;

            const networkUID = response.data.networkUID;
            appMessaging.showSuccess(settings.display + ' network created', 5000);

            callback(true, networkUID);
        })
        .catch((error) => {
            appMessaging.showError('Error creating network: ' + error, 10000);

            callback(false, error);
        });
    }

    getInstance(networkUID)
    {
        if (this.instances.has(networkUID) === true)
        {
            return this.instances.get(networkUID);
        }

        const netInstance = new NetworkInstance(networkUID);
        this.instances.set(networkUID, netInstance);

        return netInstance;
    }

}
