import {Breadcrumbs, Divider, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {appSession} from "../../Broadtask";
import {sectionPanel, sectionTitle, subSectionPanel} from "../../../AppStyles";

const ProcessStageTaskAdd = () =>
{
    const navigate = useNavigate();
    const {processUID, stageUID} = useParams();

    return <Stack spacing={1} padding={1}>
        <Stack direction="row"
               sx={sectionTitle}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/processes"} underline={"hover"}>Processes</Link>
                <Link component={RouterLink} to={"/processes/" + processUID} underline={"hover"}>{processUID}</Link>
                <Link component={RouterLink} to={"/processes/" + processUID} underline={"hover"}>Stages</Link>
                <Link component={RouterLink} to={"/processes/" + processUID + "/stages/" + stageUID} underline={"hover"}>{stageUID}</Link>
                <Typography>Add Task</Typography>
            </Breadcrumbs>
        </Stack>
        {
            appSession.activeSession() === false ?
            <Navigate to="/login"/> :
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2}
                   padding={1}>

                <Stack direction="column" spacing={1} padding={1} width="100%"
                       alignItems="flex-start" justifyContent="flex-start"
                       sx={sectionPanel}>
                    <Stack direction="row" width="100%"
                           justifyContent="space-between"
                           padding={1} spacing={1}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <img src="/icons/task.png" width={32} height={32}/>
                            <Typography variant="h6">Task Settings</Typography>
                        </Stack>
                    </Stack>
                    <Divider orientation="horizontal" variant="left" flexItem />
                    <Stack direction="column" spacing={1} width="100%" minHeight={100}
                           sx={subSectionPanel}>

                    </Stack>
                    <Divider orientation="horizontal" variant="left" flexItem />
                </Stack>

            </Stack>
        }
    </Stack>
};

export default ProcessStageTaskAdd;
