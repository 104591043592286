import {processAPI} from "../api/BroadtaskAPI";
import {appMessaging} from "../Broadtask";

export default class StageInstance
{
    constructor(processUID, stageUID)
    {
        this.processUID = processUID;
        this.stageUID = stageUID;
    }

    getSettings(force, callback)
    {
        if (this.settings === true && force === false)
        {
            callback(this.settings);
            return;
        }

        const apiCall = processAPI.getStageSettings(this.processUID, this.stageUID);
        apiCall.then(response => {

            this.settings = response.data;

            callback(this.settings);
        })
        .catch((reason) => {
            console.log("StageInstance::getSettings::ERROR::" + reason.response.data);
            callback(null);
        })
    }

    getTasks(force, callback)
    {
        if (this.tasks === true && force === false)
        {
            callback(this.tasks);
            return;
        }

        const apiCall = processAPI.getTasks(this.processUID, this.stageUID);
        apiCall.then(response => {

            this.tasks = response.data;

            callback(this.tasks);
        })
            .catch((reason) => {
                console.log("StageInstance::" + this.processUID + "::" + this.stageUID + "::getTasks::ERROR::" + reason.response.data);
                callback([]);
            })
    }

    createTask(settings, callback)
    {
        const apiCall = processAPI.createTask(this.processUID, this.stageUID, settings);
        apiCall.then((response) => {

            //Null out the tasks to force reload
            this.tasks = null;

            const taskUID = response.data.taskUID;
            appMessaging.showSuccess(settings.display + ' task add to stage', 5000);

            callback(true, taskUID);
        })
            .catch((error) => {
                appMessaging.showError('Error adding task to stage: ' + error, 10000);

                callback(false, error);
            });
    }

}
