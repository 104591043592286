import React, {useEffect, useState} from "react";
import {Button, Divider, Stack, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {dataManager} from "../../Broadtask";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ExtensionCard from "./ExtensionCard";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {buttonStyle, sectionStack} from "../../../AppStyles";

const ExtensionManagerPane = (props) => {

    const navigate = useNavigate();

    const [extensions, setExtensions] = useState(null);

    useEffect(() => {
        reload(false);
    }, []);

    function onRefresh()
    {
        reload(true);
    }

    function reload(force)
    {
        dataManager.getExtensionManager().getExtensions(force, (ext) => {
            setExtensions(ext);
        });
    }

    function onInstall()
    {
        navigate("/extensions/install");
    }

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%" alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/extension.png" width={32} height={32}/>
                    <Typography variant="h6">Extensions</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Button onClick={onInstall}
                            sx={buttonStyle}
                            startIcon={<CloudUploadIcon />}>INSTALL</Button>
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
            <Stack direction="row" alignItems="center" spacing={1} padding={1} width="100%" minHeight={100}
                   useFlexGap sx={{ flexWrap: "wrap", backgroundColor: '#2A333A'}}>
                {
                    extensions === null ?
                        <Typography variant="h6">Loading...</Typography>:
                        extensions.map(nextExt => {
                            return (<ExtensionCard key={"ext_" + nextExt.extensionUID} entry={nextExt} />)
                        })
                }
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
        </Stack>
);
}

export default ExtensionManagerPane;
