import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useNavigate, useParams} from "react-router-dom";
import {appSession} from "../../Broadtask";
import * as React from "react";

const AccountContactEditor = () =>
{
    const navigate = useNavigate();
    const {contactUID} = useParams();

    return <Stack>
        <Stack direction="row"
               sx={{ backgroundColor: '#1e1e1e'}}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/account"} underline={"hover"}>Account</Link>
                <Typography>Contact: {contactUID}</Typography>
            </Breadcrumbs>
        </Stack>

        {
            appSession.activeSession() === false ?
            <Navigate to="/login" />:
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2} padding={0}>

            </Stack>
        }
    </Stack>
};

export default AccountContactEditor;
