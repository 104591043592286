import React, {useEffect} from "react";
import {Button, Divider, Stack, Typography} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {useNavigate} from "react-router-dom";
import {buttonStyle, sectionStack, subSectionPanel} from "../../../../AppStyles";
import CancelIcon from "@mui/icons-material/Cancel";
import GridViewIcon from "@mui/icons-material/GridView";

const ChannelListenersPane = (props) => {

    const navigate = useNavigate();

    useEffect(() => {

    }, []);

    function onCreate()
    {
        navigate("../networks/" + props.networkUID + "/channels/" + props.channelUID + "/listeners/create");
    }

    function onView()
    {
        navigate("../networks/" + props.networkUID + "/channels/" + props.channelUID + "/listeners/CLD3M029");
    }

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%"
               alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/channel_listener.png" width={32} height={32}/>
                    <Typography variant="h6">Channel Listeners</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Button onClick={onView} title="View"
                            sx={buttonStyle}
                            startIcon={<GridViewIcon />}>View</Button>
                    <Button onClick={onCreate} title="Create"
                            sx={buttonStyle}
                            startIcon={<AddCircleOutlineIcon />}>Create</Button>
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
            <Stack direction="column" spacing={1} padding={1} width="100%" minHeight={100}
                   sx={subSectionPanel}>

            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
        </Stack>
    );
}

export default ChannelListenersPane;
