import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {appSession} from "../../../Broadtask";
import StreamManagerPane from "../../../components/network/stream/StreamManagerPane";
import {mainHeader} from "../../../../AppStyles";

const StreamManager = () =>
{
    const navigate = useNavigate();

    const {networkUID} = useParams();

    return <Stack spacing={2}>
        <Stack direction="row"
               sx={mainHeader}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/networks"} underline={"hover"}>Networks</Link>
                <Link component={RouterLink} to={"/networks/" + networkUID} underline={"hover"}>{networkUID}</Link>
                <Typography>Data Streams</Typography>
            </Breadcrumbs>
        </Stack>

        {
            appSession.activeSession() === false ?
            <Navigate to="/login"/> :
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2} padding={0}>

                <StreamManagerPane networkUID={networkUID} />

            </Stack>
        }
    </Stack>
};

export default StreamManager;
