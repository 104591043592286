import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, Navigate, useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {appSession} from "../../Broadtask";
import ProcessSettingsPane from "../../components/process/ProcessSettingsPane";
import ProcessOptionsPane from "../../components/process/ProcessOptionsPane";
import ProcessStagesPane from "../../components/process/ProcessStagesPane";
import {sectionTitle} from "../../../AppStyles";

const ProcessEditor = () =>
{
    const navigate = useNavigate();
    const {processUID} = useParams();

    return <Stack spacing={1}>
        <Stack direction="row"
               sx={sectionTitle}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/processes"} underline={"hover"}>Processes</Link>
                <Typography>{processUID}</Typography>
            </Breadcrumbs>
        </Stack>
        {
            appSession.activeSession() === false ?
            <Navigate to="/login"/> :
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2}
                   padding={1}>

                {/*HBox*/}
                <Stack direction="row" spacing={1} justifyContent="stretch" alignItems="flex-start">

                    <ProcessSettingsPane processUID={processUID} />

                    <ProcessOptionsPane processUID={processUID} />

                </Stack>

                <ProcessStagesPane processUID={processUID} />

            </Stack>
        }
    </Stack>
};

export default ProcessEditor;
