import APICaller from "./APICaller";

export default class StreamAPI extends APICaller
{
    constructor()
    {
        super();
    }

    getStreams(networkUID)
    {
        const servicePath = "networks/" + networkUID + "/streams";

        return this.get(servicePath);
    }

    createStream(networkUID, settings)
    {
        const servicePath = "networks/" + networkUID + "/streams";

        return this.post(servicePath, settings);
    }

    deleteStream(networkUID, streamUID)
    {
        const servicePath = "networks/" + networkUID + "/streams/" + streamUID + "/delete";

        const payload = {

        };

        return this.post(servicePath, payload);
    }

    getStreamSettings(networkUID, streamUID)
    {
        const servicePath = "networks/" + networkUID + "/streams/" + streamUID;

        return this.get(servicePath);
    }

    saveStreamSettings(networkUID, streamUID, settings)
    {
        const servicePath = "networks/" + networkUID + "/streams/" + streamUID;

        return this.post(servicePath, settings);
    }

    getStreamListeners(networkUID, streamUID)
    {
        const servicePath = "networks/" + networkUID + "/streams/" + streamUID + "/listeners";

        return this.get(servicePath);
    }

    createStreamListener(networkUID, streamUID, settings)
    {
        const servicePath = "networks/" + networkUID + "/streams/" + streamUID + "/listeners";

        return this.post(servicePath, settings);
    }

    deleteStreamListener(networkUID, streamUID, listenerUID)
    {
        const servicePath = "networks/" + networkUID + "/streams/" + streamUID + "/listeners/" + listenerUID + "/delete";

        const payload = {

        };

        return this.post(servicePath, payload);
    }

    getStreamListenerSettings(networkUID, streamUID, listenerUID)
    {
        const servicePath = "networks/" + networkUID + "/streams/" + streamUID + "/listeners/" + listenerUID;

        return this.get(servicePath);
    }

    saveStreamListenerSettings(networkUID, streamUID, listenerUID, settings)
    {
        const servicePath = "networks/" + networkUID + "/streams/" + streamUID + "/listeners/" + listenerUID;

        return this.post(servicePath, settings);
    }
}
