import {
    Breadcrumbs,
    Button, Divider,
    Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText,
    MenuItem, Paper,
    Select,
    Slider,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import {Link as RouterLink, Navigate, useNavigate} from "react-router-dom";
import {appMessaging, appSession} from "../../Broadtask";
import * as React from "react";
import {useState} from "react";
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import DomainIcon from '@mui/icons-material/Domain';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import {sectionPanel, sectionStack, sectionTitle, subSectionPanel} from "../../../AppStyles";

const TaskableCreate = () => {
    const navigate = useNavigate();

    const countries = [
        {"iso3": "AUS", "display": "Australia"},
        {"iso3": "BHR", "display": "Bahrain"},
        {"iso3": "BRA", "display": "Brazil"},
        {"iso3": "CAN", "display": "Canada"},
        {"iso3": "CHN", "display": "China"},
        {"iso3": "FRA", "display": "France"},
        {"iso3": "DEU", "display": "Germany"},
        {"iso3": "IND", "display": "India"},
        {"iso3": "IDN", "display": "Indonesia"},
        {"iso3": "IRL", "display": "Ireland"},
        {"iso3": "ISR", "display": "Israel"},
        {"iso3": "ITA", "display": "Italy"},
        {"iso3": "JPN", "display": "Japan"},
        {"iso3": "SGP", "display": "Singapore"},
        {"iso3": "ZAF", "display": "South Africa"},
        {"iso3": "KOR", "display": "South Korea"},
        {"iso3": "ESP", "display": "Spain"},
        {"iso3": "SWE", "display": "Sweden"},
        {"iso3": "CHE", "display": "Switzerland"},
        {"iso3": "ARE", "display": "United Arab Emirates (UAE)"},
        {"iso3": "GBR", "display": "United Kingdom"},
        {"iso3": "USA", "display": "United States"},
    ];

    const cities = [
        {"countryCode": "AUS", "cityKey": "AUS-MEL", "display": "Melbourne"},
        {"countryCode": "AUS", "cityKey": "AUS-SYD", "display": "Sydney"},
        {"countryCode": "USA", "cityKey": "USA-OH", "display": "Ohio"},
        {"countryCode": "USA", "cityKey": "USA-VA", "display": "Virginia"},
        {"countryCode": "USA", "cityKey": "USA-CA", "display": "California"},
        {"countryCode": "USA", "cityKey": "USA-OR", "display": "Oregon"},
        {"countryCode": "ZAF", "cityKey": "ZAF-CT", "display": "Cape Town"},
        {"countryCode": "CHN", "cityKey": "CHN-HK", "display": "Hong Kong"},
        {"countryCode": "IND", "cityKey": "IND-HYD", "display": "Hyderabad"},
        {"countryCode": "IND", "cityKey": "IND-MUM", "display": "Mumbai"},
        {"countryCode": "IND", "cityKey": "IDN-JAK", "display": "Jakarta"},
        {"countryCode": "JAP", "cityKey": "JAP-OSK", "display": "Osaka"},
        {"countryCode": "JAP", "cityKey": "JAP-TOK", "display": "Tokyo"},
        {"countryCode": "KOR", "cityKey": "KOR-SEO", "display": "Seoul"},
        {"countryCode": "SGP", "cityKey": "SGP-SIN", "display": "Singapore"},
        {"countryCode": "CAN", "cityKey": "CAN-CAL", "display": "Calgary"},
        {"countryCode": "CAN", "cityKey": "CAN-MON", "display": "Montreal"},
        {"countryCode": "DEU", "cityKey": "DEU-FRA", "display": "Frankfurt"},
        {"countryCode": "IRL", "cityKey": "IRL-DUB", "display": "Dublin"},
        {"countryCode": "GBR", "cityKey": "GBR-LON", "display": "London"},
        {"countryCode": "ITA", "cityKey": "ITA-MIL", "display": "Milan"},
        {"countryCode": "FRA", "cityKey": "FRA-PAR", "display": "Paris"},
        {"countryCode": "ESP", "cityKey": "ESP-ARA", "display": "Aragon"},
        {"countryCode": "SWE", "cityKey": "SWE-STO", "display": "Stockholm"},
        {"countryCode": "CHE", "cityKey": "CHE-ZUR", "display": "Zurich"},
        {"countryCode": "ISR", "cityKey": "ISR-TEL", "display": "Tel Aviv"},
        {"countryCode": "BHR", "cityKey": "BHR-MAN", "display": "Manama"},
        {"countryCode": "ARE", "cityKey": "ARE-DUB", "display": "Dubai"},
        {"countryCode": "BRA", "cityKey": "BRA-SAN", "display": "San Paulo"},
    ];

    const hosts = [
        {"countryCode": "USA", "cityKey": "USA-OH", "hostKey":"AWS", "hostValue":"us-east-2", "display": "AWS: Ohio"},
        {"countryCode": "USA", "cityKey": "USA-VA", "hostKey":"AWS", "hostValue":"us-east-1", "display": "AWS: Virginia"},
        {"countryCode": "USA", "cityKey": "USA-CA", "hostKey":"AWS", "hostValue":"us-west-1", "display": "AWS: California"},
        {"countryCode": "USA", "cityKey": "USA-OR", "hostKey":"AWS", "hostValue":"us-west-2", "display": "AWS: Oregon"},
        {"countryCode": "AUS", "cityKey": "AUS-MEL", "hostKey":"AWS", "hostValue":"ap-southeast-4", "display": "AWS: Melbourne"},
        {"countryCode": "AUS", "cityKey": "AUS-SYD", "hostKey":"AWS", "hostValue":"ap-southeast-2", "display": "AWS: Sydney"},
        {"countryCode": "ZAF", "cityKey": "ZAF-CT", "hostKey":"AWS", "hostValue":"af-south-1", "display": "AWS: Cape Town"},
        {"countryCode": "CHN", "cityKey": "CHN-HK", "hostKey":"AWS", "hostValue":"ap-east-1", "display": "AWS: Hong Kong"},
        {"countryCode": "IND", "cityKey": "IND-HYD", "hostKey":"AWS", "hostValue":"ap-south-2", "display": "AWS: Hyderabad"},
        {"countryCode": "IND", "cityKey": "IND-MUM", "hostKey":"AWS", "hostValue":"ap-south-1", "display": "AWS: Mumbai"},
        {"countryCode": "IND", "cityKey": "IDN-JAK", "hostKey":"AWS", "hostValue":"ap-southeast-3", "display": "AWS: Jakarta"},
        {"countryCode": "JAP", "cityKey": "JAP-OSK", "hostKey":"AWS", "hostValue":"ap-northeast-3", "display": "AWS: Osaka"},
        {"countryCode": "JAP", "cityKey": "JAP-TOK", "hostKey":"AWS", "hostValue":"ap-northeast-1", "display": "AWS: Tokyo"},
        {"countryCode": "KOR", "cityKey": "KOR-SEO", "hostKey":"AWS", "hostValue":"ap-northeast-2", "display": "AWS: Seoul"},
        {"countryCode": "SGP", "cityKey": "SGP-SIN", "hostKey":"AWS", "hostValue":"ap-southeast-1", "display": "AWS: Singapore"},
        {"countryCode": "CAN", "cityKey": "CAN-CAL", "hostKey":"AWS", "hostValue":"ca-west-1", "display": "AWS: Calgary"},
        {"countryCode": "CAN", "cityKey": "CAN-MON", "hostKey":"AWS", "hostValue":"ca-central-1", "display": "AWS: Montreal"},
        {"countryCode": "DEU", "cityKey": "DEU-FRA", "hostKey":"AWS", "hostValue":"eu-central-1", "display": "AWS: Frankfurt"},
        {"countryCode": "IRL", "cityKey": "IRL-DUB", "hostKey":"AWS", "hostValue":"eu-west-1", "display": "AWS: Dublin"},
        {"countryCode": "GBR", "cityKey": "GBR-LON", "hostKey":"AWS", "hostValue":"eu-west-2", "display": "AWS: London"},
        {"countryCode": "ITA", "cityKey": "ITA-MIL", "hostKey":"AWS", "hostValue":"eu-south-1", "display": "AWS: Milan"},
        {"countryCode": "FRA", "cityKey": "FRA-PAR", "hostKey":"AWS", "hostValue":"eu-west-3", "display": "AWS: Paris"},
        {"countryCode": "ESP", "cityKey": "ESP-ARA", "hostKey":"AWS", "hostValue":"eu-south-2", "display": "AWS: Aragon"},
        {"countryCode": "SWE", "cityKey": "SWE-STO", "hostKey":"AWS", "hostValue":"eu-north-1", "display": "AWS: Stockholm"},
        {"countryCode": "CHE", "cityKey": "CHE-ZUR", "hostKey":"AWS", "hostValue":"eu-central-2", "display": "AWS: Zurich"},
        {"countryCode": "ISR", "cityKey": "ISR-TEL", "hostKey":"AWS", "hostValue":"il-central-1", "display": "AWS: Tel Aviv"},
        {"countryCode": "BHR", "cityKey": "BHR-MAN", "hostKey":"AWS", "hostValue":"me-south-1", "display": "AWS: Manama"},
        {"countryCode": "ARE", "cityKey": "ARE-DUB", "hostKey":"AWS", "hostValue":"me-central-1", "display": "AWS: Dubai"},
        {"countryCode": "BRA", "cityKey": "BRA-SAN", "hostKey":"AWS", "hostValue":"sa-east-1", "display": "AWS: San Paulo"},
    ];

    const [display, setDisplay] = useState("");
    const [region, setRegion] = useState("");
    const [operatingSystem, setOperatingSystem] = useState(null);
    const [cpuCount, setCPUCount] = useState(4);
    const [memoryAmount, setMemoryAmount] = useState(8);
    const [storageSize, setStorageSize] = useState(100);
    const [netSpeed, setNetSpeed] = useState(5);

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedCountryIndex, setSelectedCountryIndex] = useState(-1);
    const [showCities, setShowCities] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedCityIndex, setSelectedCityIndex] = useState(-1);
    const [showHosts, setShowHosts] = useState(null);
    const [selectedHost, setSelectedHost] = useState(null);
    const [selectedHostIndex, setSelectedHostIndex] = useState(-1);

    function onCreate() {
        appMessaging.showError("Taskable Virtual Machines not enabled on this account", 10000);
    }

    function onCountrySelect(event, country, index)
    {
        setSelectedCountry(country);
        setSelectedCountryIndex(index);
        setShowCities([]);
        setSelectedCity(null);
        setSelectedCountryIndex(-1);
        setShowHosts([]);
        setSelectedHost(null);
        setSelectedHostIndex(-1);

        const filterCities = [];
        cities.map((nextCity) => {
            if (nextCity.countryCode === country.iso3)
            {
                filterCities.push(nextCity);
            }
        });
        setShowCities(filterCities);
    }

    function onCitySelect(event, city, index)
    {
        setSelectedCity(city);
        setSelectedCityIndex(index);
        setShowHosts([]);
        setSelectedHost(null);
        setSelectedHostIndex(-1);

        const filterHosts = [];
        hosts.map((nextHost) => {
            if (nextHost.cityKey === city.cityKey)
            {
                filterHosts.push(nextHost);
            }
        });
        setShowHosts(filterHosts);
    }

    function onHostSelect(event, host, index)
    {
        setSelectedHost(host);
        setSelectedHostIndex(index);
    }

    return <Stack spacing={1}>
        <Stack direction="row"
               sx={sectionTitle}
               alignItems="center" justifyContent="space-between" padding={1}>
            <Breadcrumbs>
                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                <Link component={RouterLink} to={"/taskables"} underline={"hover"}>Taskables</Link>
                <Typography>Create a Taskable</Typography>
            </Breadcrumbs>
        </Stack>

        {
            appSession.activeSession() === false ?
            <Navigate to="/login"/> :
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch"
                   spacing={2} padding={1}>

                <Stack direction="column" spacing={1} padding={1} width="100%" alignItems="flex-start" justifyContent="flex-start"
                       sx={sectionStack}>
                    <Stack direction="row" width="100%"
                           justifyContent="space-between"
                           padding={1} spacing={1}>
                        <Typography variant="h6">Taskable Computer Configuration</Typography>
                        <Stack direction="row" spacing={1}>
                        </Stack>
                    </Stack>
                    <Divider orientation="horizontal" variant="left" flexItem />
                    <Stack direction="row" spacing={1} padding={1} width="100%" minHeight={100} alignItems="flex-start"
                           sx={subSectionPanel}>

                        <Stack direction="column" justifyContent="center">
                            <Stack direction="column" alignItems="center" justifyContent="center" width={150} height={150} sx={{ backgroundColor: '#000000', borderRadius:2, borderColor: '#FFFFFF', borderStyle:'solid' }}>
                                <img src="/icons/taskable.png" height={128} width={128} />
                            </Stack>
                        </Stack>
                        <Stack direction="column" spacing={1} width="100%">
                            <TextField label="Display" size="small" value={display} onChange={(event) => setDisplay(event.target.value)}/>
                        </Stack>
                    </Stack>
                    <Divider orientation="horizontal" variant="left" flexItem />
                </Stack>

                <Stack direction="column" spacing={1} padding={1} width="100%" alignItems="flex-start" justifyContent="flex-start"
                       sx={sectionStack}>
                    <Stack direction="row" width="100%"
                           justifyContent="space-between"
                           padding={1} spacing={1}>
                        <Typography variant="h6">Location and Hosting Service</Typography>
                        <Stack direction="row" spacing={1}>

                        </Stack>
                    </Stack>
                    <Divider orientation="horizontal" variant="left" flexItem />
                    <Stack direction="row" spacing={1} padding={1} width="100%" alignItems="flex-start"
                           sx={subSectionPanel}>

                        <Stack direction="column" spacing={1} padding={1} width="25%">
                            <Typography>Select a Country</Typography>
                            <Paper sx={{ maxHeight: 300, overflow: 'auto' }}>
                                <List dense={true}>
                                {
                                    countries.map((nextCountry, nextIndex) => {
                                        return <ListItemButton
                                                key={"country-" + nextCountry.iso3}
                                                selected={selectedCountryIndex === nextIndex}
                                                onClick={(event) => onCountrySelect(event, nextCountry, nextIndex)}>
                                            <ListItemIcon>
                                                <FlagCircleIcon />
                                                <ListItemText primary={nextCountry.display} secondary={nextCountry.iso3}/>
                                            </ListItemIcon>
                                        </ListItemButton>
                                    })
                                }
                                </List>
                            </Paper>
                        </Stack>

                        <Stack direction="column" spacing={1} padding={1} width="25%">
                            <Typography>Select a City</Typography>
                            <Paper sx={{ maxHeight: 300, overflow: 'auto' }}>
                                <List dense={true}>
                                    {
                                        showCities === null ?
                                        <span></span> :
                                        showCities.map((nextCity, nextIndex) => {
                                            return <ListItemButton
                                                    key={"city-" + nextCity.cityKey}
                                                    selected={selectedCityIndex === nextIndex}
                                                    onClick={(event) => onCitySelect(event, nextCity, nextIndex)}>
                                                <ListItemIcon>
                                                    <LocationCityIcon />
                                                    <ListItemText primary={nextCity.display} secondary={nextCity.cityKey}/>
                                                </ListItemIcon>
                                            </ListItemButton>
                                        })
                                    }
                                </List>
                            </Paper>
                        </Stack>

                        <Stack direction="column" spacing={1} padding={1} width="50%">
                            <Typography>Select a Hosting Service</Typography>
                            <Paper sx={{ maxHeight: 300, overflow: 'auto' }}>
                                <List dense={true}>
                                    {
                                        showHosts === null ?
                                        <span></span> :
                                        showHosts.map((nextHost, nextIndex) => {
                                            return <ListItemButton
                                                    key={"host-" + nextHost.hostKey}
                                                    selected={selectedHostIndex === nextIndex}
                                                    onClick={(event) => onHostSelect(event, nextHost, nextIndex)}>
                                                <ListItemIcon>
                                                    <DomainIcon />
                                                    <ListItemText primary={nextHost.display} secondary={nextHost.hostValue}/>
                                                </ListItemIcon>
                                            </ListItemButton>
                                        })
                                    }
                                </List>
                            </Paper>
                        </Stack>

                    </Stack>
                    <Divider orientation="horizontal" variant="left" flexItem />
                </Stack>

                {
                    selectedHost === null ?
                    <span></span>:
                    <Stack direction="column" spacing={1} padding={1} width="100%"
                           alignItems="flex-start" justifyContent="flex-start"
                           sx={sectionPanel}>
                        <Stack direction="row" width="100%"
                               justifyContent="space-between"
                               padding={1} spacing={1}>
                            <Typography variant="h6">{"VM Options: " + selectedHost.hostKey + " (" + selectedHost.hostValue + ") " + selectedCity.display + ", " + selectedCountry.display}</Typography>
                            <Stack direction="row" spacing={1}>
                            </Stack>
                        </Stack>
                        <Divider orientation="horizontal" variant="left" flexItem />
                        <Stack direction="column" spacing={1} padding={1} width="100%" minHeight={100} alignItems="flex-start"
                               sx={subSectionPanel}>

                            <Stack direction="row" alignItems="center" padding={1} spacing={1}>
                                <Typography>Operating System:</Typography>
                                <ToggleButtonGroup value={operatingSystem}
                                                   onChange={(event) => setOperatingSystem(event.target.value)}>
                                    <ToggleButton value="AWS_LINUX">AWS Linux</ToggleButton>
                                    <ToggleButton value="MAC_OS">macOS</ToggleButton>
                                    <ToggleButton value="UBUNTI">Ubuntu</ToggleButton>
                                    <ToggleButton value="WINDOWS">Windows</ToggleButton>
                                    <ToggleButton value="RED_HAT">Red Hat</ToggleButton>
                                    <ToggleButton value="SUSE_LINUX">SUSE Linux</ToggleButton>
                                    <ToggleButton value="DEBIAN">DEBIAN</ToggleButton>
                                </ToggleButtonGroup>
                            </Stack>
                            <Stack direction="row" padding={1} spacing={2} width="100%">
                                <Typography width={100}>vCPUs:</Typography>
                                <Typography width={120}>{cpuCount} Cores</Typography>
                                <Slider aria-label="vCPUs" value={cpuCount} onChange={(event) => setCPUCount(event.target.value)} step={2} min={4} max={64} valueLabelDisplay="auto"></Slider>
                            </Stack>
                            <Stack direction="row" padding={1} spacing={2} width="100%">
                                <Typography width={100}>Memory:</Typography>
                                <Typography width={120}>{memoryAmount} GB</Typography>
                                <Slider aria-label="MEMORY" value={memoryAmount} onChange={(event) => setMemoryAmount(event.target.value)} step={8} min={8} max={256} valueLabelDisplay="auto"></Slider>
                            </Stack>
                            <Stack direction="row" padding={1} spacing={2} width="100%">
                                <Typography width={100}>Storage:</Typography>
                                <Typography width={120}>{storageSize} GB</Typography>
                                <Slider aria-label="STORAGE" value={storageSize} onChange={(event) => setStorageSize(event.target.value)} step={25} min={100} max={4000} valueLabelDisplay="auto"></Slider>
                            </Stack>
                            <Stack direction="row" padding={1} spacing={2} width="100%">
                                <Typography width={100}>Network:</Typography>
                                <Typography width={120}>{netSpeed} Gigabit</Typography>
                                <Slider aria-label="NETWORK" value={netSpeed} onChange={(event) => setNetSpeed(event.target.value)} step={5} min={5} max={25} valueLabelDisplay="auto"></Slider>
                            </Stack>

                        </Stack>
                        <Divider orientation="horizontal" variant="left" flexItem />
                    </Stack>
                }

            </Stack>
        }
    </Stack>
};

export default TaskableCreate;
