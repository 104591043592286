import APICaller from "./APICaller";

export default class NetworkAPI extends APICaller
{
    constructor()
    {
        super();
    }

    getNetworks()
    {
        const servicePath = "networks";

        return this.get(servicePath);
    }

    createNetwork(settings)
    {
        const servicePath = "networks";

        return this.post(servicePath, settings);
    }

    deleteNetwork(networkUID)
    {
        const servicePath = "networks/" + networkUID + "/delete";

        const payload = {

        };

        return this.post(servicePath, payload);
    }

    getNetworkSettings(networkUID)
    {
        const servicePath = "networks/" + networkUID;

        return this.get(servicePath);
    }

    saveNetworkSettings(networkUID, settings)
    {
        const servicePath = "networks/" + networkUID;

        return this.post(servicePath, settings);
    }

    updateIcon(networkUID, fileIcon)
    {
        const servicePath = "networks/" + networkUID + "/icon";

        return this.post(servicePath, fileIcon);
    }
}
