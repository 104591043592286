import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    Divider,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Stack,
    styled, ToggleButtonGroup,
    Typography
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {dataManager} from "../../Broadtask";
import MuiToggleButton from "@mui/material/ToggleButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
    MapOutlined,
    MapsUgcOutlined,
    MapTwoTone,
    MoreVert,
    ViewListOutlined,
    ZoomOutMapOutlined
} from "@mui/icons-material";
import {Global} from "@emotion/react";
import TaskableCard from "./TaskableCard";
import HideShowButton from "../HideShowButton";
import {buttonStyle, sectionStack, toggleButtonStyle} from "../../../AppStyles";

const TaskableDeploymentPane = (props) => {

    const navigate = useNavigate();

    const [taskables, setTaskables] = useState(null);

    let [mobilityType, setMobilityType] = useState("all");
    let [taskableType, setTaskableType] = useState("all");
    let [mapToggle, setMapToggle] = useState("physical");

    const [showPanel, setShowPanel] = useState(true);

    const ToggleButton = styled(MuiToggleButton)({
        "&.Mui-selected, &.Mui-selected:hover": {
            color: '#495e67',
            backgroundColor: '#f9a555'
        },
        color: '#495e67'
    });

    useEffect(() => {
        reload(false);
    }, [props.taskableUID]);

    function onRefresh()
    {
        reload(true);
    }

    function reload(force)
    {
        dataManager.getTaskableManager().getTaskables(force, (taskables) => {
            setTaskables(taskables);
        });
    }

    function onView()
    {
        navigate("/deployments");
    }

    function onExpand()
    {

    }
    function onTogglePanel()
    {
        setShowPanel(!showPanel);
    }
    function onScrollDeployments()
    {

    }

    function handleMobilityType(selectedMobilityType)
    {
        mobilityType = selectedMobilityType;
        setMobilityType(mobilityType);
    }

    function handleTaskableType(selectedTaskableType)
    {
        taskableType = selectedTaskableType;
        setTaskableType(taskableType);
    }

    function handleMapToggle(selectedMapToggle)
    {
        mapToggle = selectedMapToggle;
        setMapToggle(mapToggle);
    }

    return (
        <Stack direction="column" spacing={1} padding={1} width="100%" alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%" justifyContent="space-between" alignItems="left">
                <Stack direction="row" alignItems="left">
                    <img src="/icons/deployments.png" width={58} height={58}/>
                    <Stack direction="column" width="100%" alignItems="flex-start" justifyContent="flex-start">
                        <Typography color="#84898D" variant="subtitle1">DEPLOYMENTS</Typography>
                        <Typography color="#495D67" variant="subtitle2" alignItems="left">TASKABLE DEPLOYMENTS (PHYSICAL/ATTRIBUTABLE)</Typography>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={1} padding={1} alignItems="center">
                    <Card variant="outlined"
                          sx={{
                              display: 'flex',
                              color: '#f9a555',
                              backgroundColor: '#141c21',
                              '& svg': {
                                  m: 1,
                              },
                              '& hr': {
                                  mx: 0.5,
                              },
                          }}
                    >
                        <Button onClick={onView}
                                sx={buttonStyle}
                                startIcon={<MapTwoTone />}>MANAGE DEPLOYMENTS</Button>
                    </Card>
                    <HideShowButton onChange={onTogglePanel} />
                </Stack>
            </Stack>
            {
                showPanel === false ?
                <></> :
                <Stack direction="column" width="100%">
                    <Divider orientation="horizontal" variant="CENTER" width="100%" flexItem>
                        <Typography fontSize="small" sx={{ color: '#808183'}}>DEPLOYMENTS</Typography>
                    </Divider>
                    <Stack direction="row">
                        <Stack direction="column" width="100%" padding={1}
                               sx={{ borderRadius: 2, borderStyle: 'solid', borderColor: '#141c21'}} >
                            <Typography color="#84898D" variant="subtitle2" textAlign="center" >Updated every 5 minutes</Typography>
                            <img src="/images/map_deployments.png" width={580} height={380}/>
                            <Stack direction="column" spacing={0} alignItems="center" width="100%">
                                <ToggleButtonGroup value={mapToggle} exclusive aria-label="text alignment"
                                                   onChange={event => handleMapToggle(event.target.value)}
                                                   sx={{backgroundColor: '#141c21', borderRadius: 2, textAlign: 'center'}}>
                                    <ToggleButton value="physical" aria-label="physical">PHYSICAL</ToggleButton>
                                    <ToggleButton value="attributable" aria-label="attributable">ATTRIBUTABLE</ToggleButton>
                                </ToggleButtonGroup>
                            </Stack>
                        </Stack>

                        <Stack direction="row" width="100%" alignItems="center" padding={1}>
                            <IconButton onClick={onScrollDeployments} title="Scroll Left"
                                        sx={{ backgroundColor: '#2A333A',color:'#f9a555', width: 36, height: 36,transform: 'rotate(270deg)'}} >
                                <img src="/icons/arrow.png" width={16} height={16} />
                            </IconButton>
                            <Stack direction="row" alignItems="center" spacing={1} padding={1} width="100%" minHeight={100}
                                   useFlexGap sx={{ flexWrap: "wrap", backgroundColor: '#2A333A'}}>
                                {
                                    taskables === null ?
                                        <Typography variant="h6">Loading...</Typography>:
                                        taskables.map(nextTaskable => {
                                            return (<TaskableCard key={"taskable_" + nextTaskable.taskableUID} entry={nextTaskable} />)
                                        })
                                }
                            </Stack>
                            <IconButton onClick={onScrollDeployments} title="Scroll Right"
                                        sx={{ backgroundColor: '#2A333A',color:'#f9a555', width: 36, height: 36,transform: 'rotate(90deg)'}} >
                                <img src="/icons/arrow.png" width={16} height={16} />
                            </IconButton>
                        </Stack>
                    </Stack>
                    <Divider orientation="horizontal" variant="left" width="100%" flexItem>
                        <div>
                            <FormControl sx={{ m: 1, minWidth: 80}} size="small">
                                <Select sx={{
                                    color: "#495d67",
                                    '& .MuiSvgIcon-root': {
                                        color: "#f9a555",
                                    },
                                    backgroundColor: "#2a333a"}}
                                        value={5}>
                                    <MenuItem value={5}>Display 5</MenuItem>
                                    <MenuItem value={10}>Display 10</MenuItem>
                                    <MenuItem value={15}>Display 15</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Divider>
                    <Stack direction="column" alignItems="left" spacing={1} padding={1} width="100%" minHeight={100}
                           sx={{ backgroundColor: '#495d67', borderRadius: 2}}>
                        <Stack direction="row" alignItems="left" spacing={0} padding={0} width="100%" minHeight={10}>
                            <Typography variant="subtitle1" width="100%" spacing={1} padding={1} sx={{ color: '#f9a555', }}>FILTERS</Typography>
                            <IconButton size="large" spacing={0} padding={0} aria-label="Filter Settings" edge="end" color="inherit">
                                <MoreVert sx={{ color:'#f9a555'}}/>
                            </IconButton>
                        </Stack>
                        <Divider orientation="horizontal" variant="left" width="100%" flexItem />
                        <Stack direction="row" alignItems="flex-start" spacing={5} padding={1} width="100%">
                            <Stack direction="column" line-height="1.0" alignItems="flex-start" justifyContent="flex-start" >
                                <Stack direction="row" alignItems="left" padding={0} width="100%" >
                                    <Typography fontSize="small" sx={{ color: '#f9a555'}}>MOBILITY TYPE</Typography>
                                </Stack>
                                <ToggleButtonGroup value={mobilityType} exclusive aria-label="text alignment"
                                                   onChange={event => handleMobilityType(event.target.value)}
                                                   sx={toggleButtonStyle}>
                                    <ToggleButton value="all" aria-label="left aligned" >ALL</ToggleButton>
                                    <ToggleButton value="mobile" aria-label="center">MOBILE</ToggleButton>
                                    <ToggleButton value="fixed" aria-label="right aligned">FIXED</ToggleButton>
                                </ToggleButtonGroup>
                            </Stack>
                            <Stack direction="column" alignItems="flex-start" justifyContent="flex-start">
                                <Stack direction="row" alignItems="left" spacing={1} padding={0} width="100%" >
                                    <Typography fontSize="small" sx={{ color: '#f9a555'}}>TASKABLE TYPE</Typography>
                                </Stack>
                                <ToggleButtonGroup value={taskableType} aria-label="text alignment"
                                                   onChange={event => handleTaskableType(event.target.value)}
                                                   sx={toggleButtonStyle}>
                                    <ToggleButton value="all" aria-label="left aligned">ALL</ToggleButton>
                                    <ToggleButton value="android" aria-label="center">ANDROID</ToggleButton>
                                    <ToggleButton value="ios" aria-label="right aligned">IOS</ToggleButton>
                                    <ToggleButton value="pi" aria-label="right aligned">PI</ToggleButton>
                                    <ToggleButton value="computer" aria-label="right aligned">COMPUTER</ToggleButton>
                                </ToggleButtonGroup>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            }
        </Stack>
    );
}

export default TaskableDeploymentPane;
