import React, {useEffect, useState} from "react";
import {Divider, Stack, Typography} from "@mui/material";
import {dataManager} from "../../Broadtask";
import {sectionStack, subSectionPanel} from "../../../AppStyles";

const TaskableRegistrationPane = (props) => {

    const [registration, setRegistration] = useState(null);

    useEffect(() => {
        dataManager.getTaskableManager().getInstance(props.taskableUID).getSettings(false, (registration) => {
            setRegistration(registration);
        });
    }, [props.taskableUID]);

    return (
        <Stack direction="column" spacing={1} padding={1} width="30%"
               alignItems="flex-start" justifyContent="flex-start"
               sx={sectionStack}>
            <Stack direction="row" width="100%"
                   justifyContent="space-between"
                   padding={1} spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src="/icons/taskable.png" width={32} height={32} alt="Taskable Icon"/>
                    <Typography variant="h6">Registration</Typography>
                </Stack>
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
            <Stack direction="column" spacing={1} width="100%" minHeight={100}
                   sx={subSectionPanel}>
            {
                registration === null ?
                <Typography>Loading...</Typography>:
                <Stack padding={1}>
                    <Typography variant="h5">{registration.taskableUID}</Typography>
                    <Typography variant="h6">{registration.display}</Typography>
                    <Typography>Type: {registration.taskableType}, v{registration.taskableTypeVersion}</Typography>
                </Stack>
            }
            </Stack>
            <Divider orientation="horizontal" variant="left" flexItem />
        </Stack>
);
}

export default TaskableRegistrationPane;
